import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';

export class QuestionBase<T> {
	value: T;
	key: string;
	label: string;
	required: boolean;
	disabled: boolean;
	visible: boolean;
	order: number;
	controlType: string;
	validators: ValidatorFn[];
	onChange: any;
	isGroupEditInconsistent: any;
	type?: string;
	showOnCloning?: boolean;
	isHidden?: boolean;
	customControlInit?: () => AbstractControl;
	dataExchangeSub?: Subject<any>;

	constructor(
		options: {
			value?: T;
			key?: string;
			label?: string;
			required?: boolean;
			disabled?: boolean;
			visible?: boolean;
			order?: number;
			controlType?: string;
			validators?: ValidatorFn[];
			onChange?: any;
			isGroupEditInconsistent?: boolean;
			showOnCloning?: boolean;
			isHidden?: boolean;
			customControlInit?: () => AbstractControl;
			dataExchangeSub?: Subject<any>;
		} = {}
	) {
		this.value = options.value;
		this.key = options.key || '';
		this.label = options.label || '';
		this.required = !!options.required;
		this.disabled = !!options.disabled;
		this.visible = options.visible === undefined ? true : options.visible;
		this.order = options.order === undefined ? 1 : options.order;
		this.controlType = options.controlType || '';
		this.validators = options.validators || [];
		this.onChange = options.onChange;
		this.isGroupEditInconsistent = !!options.isGroupEditInconsistent;
		this.showOnCloning = options.showOnCloning || false;
		this.isHidden = options.isHidden || false;
		this.customControlInit = options.customControlInit;
		this.dataExchangeSub = options.dataExchangeSub;
	}
}
