<div [formGroup]="form">
	<h2>{{ arrayLabel }}</h2>
	<div class="form-array" [formArrayName]="formArrayName">
		<div *ngFor="let formArr of formArray.controls; let i = index" [formGroupName]="i" class="dynamic-input-form">
			<div class="form-group">
				<mat-form-field appearance="fill" class="dynamic-input">
					<mat-label>Key</mat-label>
					<input matInput formControlName="key" />
					<mat-error
						*ngIf="(formArr.get('key')?.hasError('isOnlyWhiteSpace') || formArr.get('key')?.hasError('required')) && formArr.get('key')?.touched">
						Field cannot be empty.
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="fill" class="dynamic-input">
					<mat-label>Value</mat-label>
					<input matInput formControlName="value" />
					<mat-error
						*ngIf="
							(formArr.get('value')?.hasError('isOnlyWhiteSpace') || formArr.get('value')?.hasError('required')) && formArr.get('value')?.touched
						">
						Field cannot be empty.
					</mat-error>
				</mat-form-field>

				<button mat-icon-button type="button" matTooltip="Remove item" (click)="removeParameter(i)">
					<mat-icon>close_small</mat-icon>
				</button>
			</div>
		</div>
	</div>
	<button mat-stroked-button type="button" class="add-button" (click)="addParameter()">
		<mat-icon>add</mat-icon>
		{{ addButtonText }}
	</button>
</div>
