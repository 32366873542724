import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GridBaseComponent } from './components/grid-base/grid-base.component';
import { QuickFilterComponent } from './components/quick-filter/quick-filter.component';

@NgModule({
	declarations: [QuickFilterComponent, GridBaseComponent],
	imports: [CommonModule, TranslateModule, AgGridModule, FormsModule],
	exports: [GridBaseComponent, QuickFilterComponent],
})
export class SharedUiGridsCoreModule {}
