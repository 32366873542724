import { Inject, Injectable, InjectionToken } from '@angular/core';
export const ENV_CONFIG = new InjectionToken<any>('ENV_CONFIG');

declare let H;

@Injectable({
	providedIn: 'root',
})
export class HereService {
	platform: any;
	geocoder: any;
	appId: string;
	appCode: string;

	constructor(@Inject(ENV_CONFIG) environmentConfig: any) {
		if (environmentConfig && environmentConfig.here) {
			this.appCode = environmentConfig.here.appCode;
			this.appId = environmentConfig.here.appId;
		}
		this.platform = new H.service.Platform({
			app_id: this.appId,
			app_code: this.appCode,
			useHTTPS: true,
		});
		this.geocoder = this.platform.getGeocodingService();
	}

	getAddress(query: string) {
		return new Promise((resolve, reject) => {
			this.geocoder.geocode(
				{ searchText: query },
				(result) => {
					if (result.Response.View.length > 0) {
						if (result.Response.View[0].Result.length > 0) {
							resolve(result.Response.View[0].Result);
						} else {
							reject({ message: 'no results found' });
						}
					} else {
						reject({ message: 'no results found' });
					}
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	getAddressFromLatLng(query: string) {
		return new Promise((resolve, reject) => {
			this.geocoder.reverseGeocode(
				{ prox: query, mode: 'retrieveAddress' },
				(result) => {
					if (result.Response.View.length > 0) {
						if (result.Response.View[0].Result.length > 0) {
							resolve(result.Response.View[0].Result);
						} else {
							reject({ message: 'no results found' });
						}
					} else {
						reject({ message: 'no results found' });
					}
				},
				(error) => {
					reject(error);
				}
			);
		});
	}
}
