import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IChartConfig, IChartSeriesConfig, IMarkAreasConfig, LineChartComponent } from '@fitech-workspace/shared/ui/chart-lib';

@Component({
	selector: 'fitech-workspace-chart-dialog',
	templateUrl: './chart-dialog.component.html',
	styleUrls: ['./chart-dialog.component.scss'],
})
export class ChartDialogComponent {
	@ViewChild('lineChart', { static: true }) private _chart: LineChartComponent;

	title: string;
	subtitle: string;

	constructor(public dialogRef: MatDialogRef<ChartDialogComponent>) {}

	initialize(title: string, subtitle: string, chartConfig: IChartConfig, seriesConfigs: IChartSeriesConfig[], markAreasConfig?: IMarkAreasConfig): void {
		this.title = title;
		this.subtitle = subtitle;
		this._chart.initialize(chartConfig);

		if (seriesConfigs) {
			seriesConfigs.forEach((series: IChartSeriesConfig) => {
				this._chart.addSeries(series);
			});
		}

		if (markAreasConfig) {
			this._chart.addMarkAreas(markAreasConfig);
		}
	}

	onCancel(): void {
		this.dialogRef.close();
	}
}
