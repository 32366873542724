import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
	selector: 'fitech-workspace-labelled-chips-renderer',
	templateUrl: './labelled-chips-renderer.component.html',
	styleUrls: ['./labelled-chips-renderer.component.scss'],
})
export class LabelledChipsRendererComponent implements ICellRendererAngularComp {
	public params: any;
	chips: ILabelledChipsData[] = [];

	refresh(params: any): boolean {
		return true;
	}

	agInit(params: any): void {
		this.params = params;
		this.chips = params.data.getLabelledChips(params.colDef.field);
	}

	afterGuiAttached?(params?: any): void {
		this.params = params;
	}
}

export interface ILabelledChipsData {
	value: string;
	id: number;
	label: string;
	cssclass: string;
}

export interface ILabelledChipsDataProvider {
	getLabelledChips(field: string): ILabelledChipsData[];
}
