import { IDateRange } from '@fitech-workspace/shared/ui/controls-lib';
import { SelectedItem } from './shared/selectors-components/list-selector/models/selected-item';

export class Globals {
	selectedItems: { name: string; id?: number | string }[] = [];

	public static reportColorPalette = [
		'#2ec7c9',
		'#b6a2de',
		'#5ab1ef',
		'#ffb980',
		'#d87a80',
		'#8d98b3',
		'#e5cf0d',
		'#97b552',
		'#95706d',
		'#dc69aa',
		'#07a2a4',
		'#9a7fd1',
		'#588dd5',
		'#f5994e',
		'#c05050',
		'#59678c',
		'#c9ab00',
		'#7eb00a',
		'#6f5553',
		'#c14089',
	];

	public static PASS_STATUSES = ['Pass'];
	public static INFORMATION_STATUSES = ['PositionOfPCB', 'EmptyFeeder', 'TrashContainerFull', 'AdditionalControlAfterNOK'];
	public static EXCLUDED_STATUSES = ['SpiralSearchStep'];
	public static MACHINE_WORK_STATUSES = ['MachineStateAssembly'];
	public static MACHINE_STOP_STATUSES = ['MachineStateStop', 'MachineStateAssemblyPause', 'MachineStateHoming'];
	public static MACHINE_ALARM_STATUSES = ['MachineStateAlarm'];
	public static MACHINE_DISABLED_STATUSES = ['MachineStatePassThrough'];
	public static MACHINE_TIME_RANGE_CHOICE: IDateRange = null;
	public static MACHINE_SELECTED_ITEMS: SelectedItem[] = [];
	public static MACHINE_LINE_SELECTED: SelectedItem[] = [];

	public static PRODUCT_SELECTED_ITEMS: SelectedItem[] = [];

	public static DATEPICKER_FREQUENCY: string;
	public static DATEPICKER_PERIOD: string;
	public static DATEPICKER_QUARTER: string;
	public static DATEPICKER_SHIFT: string;

	public static SHOW_ALARMS_DECK: boolean;

	public static internetConnectionError: boolean;
}
