<fitech-workspace-common-header *ngIf="showHeader" [isMainHeader]="true" [navLinks]="navLinks$ | async">
	<fitech-workspace-user-info
		custom-right-elem
		[customOptions]="userMenuOptions"
		(selectedOption)="selectUserMenuOption($event)">
	</fitech-workspace-user-info>
</fitech-workspace-common-header>

<main id="main">
	<router-outlet></router-outlet>
</main>

<fitech-workspace-size-detector></fitech-workspace-size-detector>
