import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
	LoginComponent,
	LoginRedirectComponent,
	LoginRequestForRolesComponent,
	PasswordForgotComponent,
	PasswordResetComponent,
	PasswordSetComponent,
} from '@fitech-workspace/auth-lib';
import { getClientsResolver } from './shared/resolvers/clients.resolver';
import { environment } from '../environments/environment';

const routes: Routes = [
	{ path: 'passwordset', component: PasswordSetComponent },
	{
		path: 'login',
		component: LoginComponent,
		data: {
			sigInAdEnabled: window.location.href.includes('fideltronik') || window.location.href.includes('dev') || window.location.href.includes('localhost'),
			sigInAccountEnabled: !window.location.href.includes('fideltronik'),
			logoSrc: environment.logoSrc,
			logoAdSrc: environment.logoAdSrc,
		},
	},
	{ path: 'loginRequestForRoles', component: LoginRequestForRolesComponent },
	{ path: 'loginRequestForRoles/:data', component: LoginRequestForRolesComponent },
	{ path: 'loginAdCallback/:data', component: LoginRedirectComponent },
	{ path: 'passwordreset', component: PasswordResetComponent },
	{ path: 'passwordforgot', component: PasswordForgotComponent },
	{
		path: '',
		redirectTo: '/reports/overall-dashboard',
		pathMatch: 'full',
	},
	{
		path: 'monitoring',
		loadChildren: () => import('./monitoring/monitoring.module').then((m) => m.MonitoringModule),
		resolve: { clientsLoaded: getClientsResolver },
	},
	{ path: 'reports', loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule), resolve: { clientsLoaded: getClientsResolver } },
	{ path: 'alarms', loadChildren: () => import('./alarms/alarms.module').then((m) => m.AlarmsModule), resolve: { clientsLoaded: getClientsResolver } },
	{
		path: 'management',
		loadChildren: () => import('./management/management.module').then((m) => m.ManagementModule),
		resolve: { clientsLoaded: getClientsResolver },
	},
	{
		path: 'dashboard',
		loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
		resolve: { clientsLoaded: getClientsResolver },
	},
	{ path: 'recipes', loadChildren: () => import('./recipes/recipes.module').then((m) => m.RecipesModule), resolve: { clientsLoaded: getClientsResolver } },
	{
		path: 'software-packages',
		loadChildren: () => import('./software-packages/software-packages.module').then((m) => m.SoftwarePackagesModule),
		resolve: { clientsLoaded: getClientsResolver },
	},
	{
		path: 'components',
		loadChildren: () => import('./components-management/components.module').then((m) => m.ComponentsModule),
		resolve: { clientsLoaded: getClientsResolver },
	},
	{ path: '**', redirectTo: 'login' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { enableTracing: false })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
