import { QuestionBase } from './question-base';

export class SwitchQuestion extends QuestionBase<string> {
	controlType = 'switch';
	togglingVisibilityKeys: string[];

	constructor(options: {} = {}) {
		super(options);
		this.togglingVisibilityKeys = options['togglingVisibilityKeys'];
	}
}
