import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SCREEN_ORIENTATION } from './screen-orientation.enum';
import { SCREEN_SIZE } from './screen-size.enum';

@Injectable()
export class ScreenSizeService {
	size: SCREEN_SIZE;
	innerWidth: number;
	innerHeight: number;
	orientation: SCREEN_ORIENTATION;

	private _resizeSubject: Subject<{ size: SCREEN_SIZE; orientation: SCREEN_ORIENTATION }>;

	get onResize$(): Observable<{ size: SCREEN_SIZE; orientation: SCREEN_ORIENTATION }> {
		return this._resizeSubject.asObservable().pipe(distinctUntilChanged());
	}

	constructor() {
		this._resizeSubject = new Subject();
	}

	onResize(size: SCREEN_SIZE): void {
		this.size = size;
		this._resizeSubject.next({ size: size, orientation: this.orientation });
	}
}
