import { UserSettingParameterValueTypeEnum } from '../enums';
import { UserSettingBaseDto, UserSettingDto, UserSettingParameterDto, UserSettingParameterValue } from '../models/user-settings';

export class UserSettingsHelper {
	static areEqual(firstUserSetting: UserSettingDto, secondUserSetting: UserSettingDto): boolean {
		return (
			(firstUserSetting.id === secondUserSetting.id && firstUserSetting.id > 0) ||
			(firstUserSetting.name === secondUserSetting.name &&
				firstUserSetting.userSettingsLocation?.view === secondUserSetting.userSettingsLocation?.view &&
				firstUserSetting.userSettingsLocation?.section === secondUserSetting.userSettingsLocation?.section &&
				firstUserSetting.userSettingsLocation?.element === secondUserSetting.userSettingsLocation?.element)
		);
	}

	static getGlobal(settings: UserSettingDto[]): UserSettingDto[] {
		return settings.filter((userSetting: UserSettingDto) => userSetting.isGlobal);
	}

	static getLocal(settings: UserSettingDto[]): UserSettingDto[] {
		return settings.filter((userSetting: UserSettingDto) => !userSetting.isGlobal);
	}

	static getIds(settings: UserSettingDto[]): number[] {
		return settings.map((userSetting: UserSettingDto) => userSetting.id);
	}

	static appendMany(newUserSettings: UserSettingDto[], previousUserSettings: UserSettingBaseDto[]): UserSettingDto[] {
		const newSubcollection: UserSettingDto[] = UserSettingsHelper.extractNew(newUserSettings, previousUserSettings);
		return [
			...previousUserSettings.map(
				(previousUserSetting: UserSettingDto) =>
					newUserSettings.find((newUserSetting: UserSettingDto) => newUserSetting.id === previousUserSetting.id) ?? previousUserSetting
			),
			...newSubcollection,
		];
	}

	static append<TUserSetting extends UserSettingBaseDto, TInput extends TUserSetting>(
		newOrUpdatedUserSetting: TInput,
		previousUserSettings: TUserSetting[]
	): TUserSetting[] {
		return previousUserSettings.some(
			(previousUserSetting: TUserSetting) => previousUserSetting.id === newOrUpdatedUserSetting.id && newOrUpdatedUserSetting.id > 0
		)
			? previousUserSettings.map((previousUserSetting: TUserSetting) =>
					previousUserSetting.id === newOrUpdatedUserSetting.id ? newOrUpdatedUserSetting : previousUserSetting
			  )
			: [...previousUserSettings, newOrUpdatedUserSetting];
	}

	static removeMany<TUserSetting extends UserSettingBaseDto>(userSettings: TUserSetting[], idsToRemove: number[]): TUserSetting[] {
		return userSettings.filter((userSetting: TUserSetting) => !idsToRemove.includes(userSetting.id));
	}

	static getParameterByKey(userSetting: UserSettingDto, parameterKey: string): UserSettingParameterDto {
		return userSetting?.parameters.find((parameter: UserSettingParameterDto) => parameter.key === parameterKey);
	}

	static getParameterValue(userSetting: UserSettingDto, parameterKey: string): UserSettingParameterValue {
		const parameter: UserSettingParameterDto | undefined = userSetting?.parameters.find(
			(parameter: UserSettingParameterDto) => parameter.key === parameterKey
		);
		return this.parseParameterValue(parameter);
	}

	private static extractNew(newUserSettings: UserSettingDto[], previousUserSettings: UserSettingBaseDto[]): UserSettingDto[] {
		return newUserSettings.filter(
			(newUserSetting: UserSettingDto) =>
				!previousUserSettings.some((previousUserSetting: UserSettingDto) => previousUserSetting.id === newUserSetting.id)
		);
	}

	private static parseParameterValue(parameter: UserSettingParameterDto | undefined): UserSettingParameterValue {
		if (typeof parameter === 'undefined' || typeof parameter.value === 'undefined') {
			return undefined;
		}

		switch (parameter.type) {
			case UserSettingParameterValueTypeEnum.Boolean:
				return this.parseBooleanValue(parameter.value);
			case UserSettingParameterValueTypeEnum.Integer:
				return this.parseIntegerValue(parameter.value);
			case UserSettingParameterValueTypeEnum.Double:
				return this.parseDoubleValue(parameter.value);
			default:
				return parameter.value;
		}
	}

	private static parseBooleanValue(value: string): boolean | undefined {
		switch (value) {
			case 'true':
				return true;
			case 'false':
				return false;
			default:
				return undefined;
		}
	}

	private static parseIntegerValue(value: string): number | undefined {
		const result = parseInt(value, 10);
		return this.isNumber(result) ? result : undefined;
	}

	private static parseDoubleValue(value: string): number | undefined {
		const result = parseFloat(value);
		return this.isNumber(result) ? result : undefined;
	}

	private static isNumber(value: number): boolean {
		return !isNaN(value) && isFinite(value);
	}
}
