import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ScreenSizeService } from '@fitech-workspace/core-lib';
import { AlarmsApiService, MeasurementsApiService } from '@fitech-workspace/fisense-common-lib';
import { ChartSeriesTypeEnum, IChartSeriesConfig } from '@fitech-workspace/shared/ui/chart-lib';
import { IDateRange } from '@fitech-workspace/shared/ui/controls-lib';
import { LineSeriesOption } from 'echarts';
import { AsEnumerable } from 'linq-es2015';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { MachineSensorsChartsComponent } from './machine-sensors-charts/machine-sensors-charts.component';
import { MachineSensorsListComponent } from './machine-sensors-list/machine-sensors-list.component';
import { IMachineDialogOptions, ISensorAddedWithAlarm } from './models/machine-components.model';
import { PlanRepositoryService } from '../../api/plan-repository.service';
import { Alarm } from '../../models/alarm.model';
import { SensorDisplayTypeEnum } from '../../models/sensor-display-type.enum';
import { Sensor } from '../../models/sensor.model';
import { State } from '../../models/state.model';
import { TrendSensorForViewer } from '../../models/trend-sensor-for-viewer.model';
import { TrendSensor } from '../../models/trend-sensor.model';

@Component({
	selector: 'fitech-workspace-machine',
	templateUrl: './machine.component.html',
	styleUrls: ['./machine.component.scss'],
})
export class MachineComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('sensorsCharts', { static: true }) private _sensorsCharts: MachineSensorsChartsComponent;
	@ViewChild('machineSensorsList', { static: true }) private _machineSensorsList: MachineSensorsListComponent;

	title: string;
	subTitle: string;
	locationId: number;
	machineAlarmsGridData: Alarm[];
	currentMachineSensorStates: State[];
	trendSensors: TrendSensorForViewer[] = [];
	trendSensorIds: number[] = [];
	isCurrentStateActive = true;
	dateRange: IDateRange;
	loadingAlarms = false;
	onDataChangedSubs: Subscription;
	onResizeSub: Subscription;
	machineId: number;

	private readonly _colors: string[] = [
		'#2c73d2',
		'#3FFF00',
		'#E52B50',
		'#00FFFF',
		'#964B00',
		'#FD6C9E',
		'#8A2BE2',
		'#007BA7',
		'#7FFFD4',
		'#FF00FF',
		'#D1E231',
		'#FF007F',
		'#FFFF00',
		'#E0B0FF',
	];

	private _colorIndex = 0;
	private readonly _emphasisColor = '#FFCA5E';
	private _reportAreaResized = false;
	private _isLineVisibleDefault = false;

	constructor(
		private _dialogRef: MatDialogRef<MachineComponent>,
		private _alarmsApi: AlarmsApiService,
		private _measurementsApi: MeasurementsApiService,
		private _screenSizeService: ScreenSizeService,
		private _planRepositoryService: PlanRepositoryService
	) {}

	ngOnInit(): void {
		this.dateRange = {
			type: '1d',
			from: moment().startOf('day').toDate(),
			to: moment().startOf('day').add(1, 'days').toDate(),
		};
		this.isCurrentStateActive = this._machineSensorsList.isCurrentStateActive;
		this.onResizeSub = this._screenSizeService.onResize$.pipe(delay(500)).subscribe((x) => {
			this.chartsResize(this.isCurrentStateActive);
		});

		this.onDataChangedSubs = this._planRepositoryService.dataChanged.subscribe((event) => this.onDataChanged(event));
	}

	ngAfterViewInit(): void {
		if (this._reportAreaResized) {
			this._sensorsCharts.sensorsChart.lineChart.resize();
			this._reportAreaResized = false;
		}
	}

	ngOnDestroy(): void {
		if (this.onResizeSub) {
			this.onResizeSub.unsubscribe();
		}
		if (this.onDataChangedSubs) {
			this.onDataChangedSubs.unsubscribe();
		}
	}

	initialize(options: IMachineDialogOptions): void {
		this.title = options.title;
		this.subTitle = options.subTitle;
		this.locationId = options.locationId;
		this.machineId = options.machineId;
		this.currentMachineSensorStates = this.sortStates(options.currentMachineSensorStatesGridData);
	}

	getAlarms(): void {
		this.loadingAlarms = true;
		this._alarmsApi.getAlarms(this.dateRange.from, this.dateRange.to, null, this.locationId).subscribe(
			(res) => {
				this.machineAlarmsGridData = this.computedAlarms(res);
				this.loadingAlarms = false;
			},
			() => {
				this.loadingAlarms = false;
			}
		);
	}

	removeSensor(sensor: TrendSensor): void {
		if (!this.trendSensorIds.includes(sensor.id)) {
			return;
		}

		this._sensorsCharts.sensorsChart.lineChart.removeSeries(sensor.name, sensor.id);
		sensor.addedToReport = false;
		this.trendSensorIds = this.trendSensorIds.filter((x) => x !== sensor.id);
		this._machineSensorsList.refresh();
		this.trendSensors = this.trendSensors.filter((trendSensor: TrendSensorForViewer): boolean => trendSensor.id !== sensor.id);
		this._reportAreaResized = true;
	}

	toggleSensorChartType(sensor: TrendSensorForViewer): void {
		sensor.isLineVisible = !sensor.isLineVisible;
		this._sensorsCharts.sensorsChart.lineChart.updateSeriesConfig(sensor.name, this.getSeriesCustomConfig(sensor.isLineVisible, sensor.color), sensor.id);
	}

	toggleSensorVisibility(sensor: TrendSensor): void {
		sensor.visible = !sensor.visible;
		this._sensorsCharts.sensorsChart.lineChart.changeSeriesVisibility(sensor.name, sensor.visible, sensor.id);
	}

	addSensor(addedSensor: TrendSensor): void {
		if (this.trendSensorIds.includes(addedSensor.id)) {
			return;
		}

		addedSensor.color = this._colors[this._colorIndex % 14];
		this._colorIndex++;

		const isStep = addedSensor.displayType === SensorDisplayTypeEnum.analogStep || addedSensor.displayType === SensorDisplayTypeEnum.discrete;

		const chartSeries: IChartSeriesConfig = {
			id: addedSensor.id,
			name: addedSensor.name,
			type: isStep ? ChartSeriesTypeEnum.dataStep : ChartSeriesTypeEnum.data,
			units: addedSensor.units,
			color: addedSensor.color,
			ignoreMinMax: true,
			yAxisMinMax: { min: addedSensor.minValue, max: addedSensor.maxValue },
			getDataFunc: (from: Date, to: Date, frequency?: number, data?: any) => {
				frequency = addedSensor.displayType === SensorDisplayTypeEnum.discrete ? 0 : frequency;
				return this._measurementsApi.getStateHistory(from, to, addedSensor.id, frequency);
			},
			customConfig: this.getSeriesCustomConfig(this._isLineVisibleDefault, addedSensor.color),
		};
		this._sensorsCharts.sensorsChart.lineChart.addSeries(chartSeries);
		addedSensor.addedToReport = true;
		this.trendSensorIds = [...this.trendSensorIds, addedSensor.id];
		this._machineSensorsList.refresh();
		const sensorForViewer: TrendSensorForViewer = addedSensor as TrendSensorForViewer;
		sensorForViewer.isLineVisible = this._isLineVisibleDefault;
		this.trendSensors.push(sensorForViewer);
		this._reportAreaResized = true;
	}

	onSensorAddedWithAlarm(options: ISensorAddedWithAlarm): void {
		this.machineAlarmsGridData.map((alarm) => {
			if (alarm.addedToReport) {
				alarm.addedToReport = false;
			}
		});
		options.alarm.addedToReport = true;
		this._machineSensorsList.refresh();
		this._sensorsCharts.addSeriesWithAlarm(options);
	}

	trackBySensorId(index: number, sensor: TrendSensorForViewer): any {
		return sensor.id;
	}

	onSidePanelToggled(): void {
		setTimeout(() => this.chartsResize(this.isCurrentStateActive), 100);
	}

	onCurrentStateChange(state: boolean): void {
		this.isCurrentStateActive = state;
		setTimeout(() => this.chartsResize(state), 10);
	}

	onDataChanged(event: any): void {
		const machineStates = this._planRepositoryService.getSensorStates();
		const states = (AsEnumerable(machineStates).FirstOrDefault((x) => x.sourceId == this.machineId) as any).sensorsStates as State[];

		// preserve addedToReport
		this.currentMachineSensorStates.forEach((state) => {
			const newState = AsEnumerable(states).FirstOrDefault((x) => x.sourceId === state.sourceId);
			if (newState) {
				newState.source.addedToReport = state.source.addedToReport;
			}
		});

		this.currentMachineSensorStates = this.sortStates(states);
		this._machineSensorsList.refresh();
	}

	onDateRangeChanged(event: IDateRange): void {
		this.dateRange = event;
		this.getAlarms();
		this._machineSensorsList.refresh();
	}

	chartsResize(currentState: boolean): void {
		currentState ? this._sensorsCharts.chartWithoutAlarmsResize() : this._sensorsCharts.chartWithAlarmsResize();
	}

	//TODO
	computedAlarms(alarms: Alarm[]): Alarm[] {
		return alarms;
		// return alarms.map(alarm => {
		//   alarm.attachSensor(this.currentMachineSensorStates.map(state => state.source as Sensor));
		//   return alarm
		// }).filter(alarm => alarm.source);
	}

	sortStates(statesData: State[]): State[] {
		if (statesData.length) {
			return AsEnumerable(statesData)
				.Select((x) => ({ state: x, alarm: x.alarmGuid ? 1 : 0 }))
				.OrderByDescending((x) => x.alarm)
				.ThenByDescending((x) => {
					if (x.state) {
						if (x.state.source instanceof Sensor) {
							return x.state.source.isMachineDefaultSensor;
						}
					}
					return;
				})
				.ThenByDescending((x) => x.state.timestamp)
				.Select((y) => y.state)
				.ToArray();
		} else {
			return [];
		}
	}

	onCancel(): void {
		this._dialogRef.close();
	}

	private getSeriesCustomConfig(isLineVisible: boolean, color: string): LineSeriesOption {
		if (isLineVisible) {
			return {
				symbol: 'none',
				lineStyle: {
					width: 2,
					color: color,
				},
				emphasis: {
					itemStyle: {},
				},
				symbolSize: 0,
			};
		}

		return {
			symbolSize: 5,
			symbol: 'circle',
			itemStyle: {
				color: color,
			},
			lineStyle: {
				width: 0,
			},
			emphasis: {
				itemStyle: {
					color: this._emphasisColor,
					borderColor: this._emphasisColor,
					borderWidth: 3,
				},
			},
		};
	}
}
