import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
	selector: 'fitech-workspace-autocomplete-renderer',
	templateUrl: './autocomplete-renderer.component.html',
	styleUrls: ['./autocomplete-renderer.component.scss'],
})
export class AutocompleteRendererComponent implements ICellRendererAngularComp, OnInit {
	items: any[];
	itemLabelProp: string;
	itemUniqueProp: string;
	itemInitialParamsDataProp: string;
	filteredItems: Observable<any[]>;
	currentItem: any;
	isMatched = false;
	searchControl: FormControl<string> = new FormControl();
	rowNode: IRowNode<any>;

	emitDataChange: (targetNode: IRowNode<any>, updatedItem: any) => void;

	ngOnInit(): void {
		this.filteredItems = this.searchControl.valueChanges.pipe(
			startWith(this.searchControl.value),
			map((value: any) => {
				const itemName = typeof value === 'string' ? value : value?.[this.itemLabelProp];
				return itemName ? this.autocompleteFilter(itemName) : this.items.slice();
			})
		);
	}

	agInit(params: ICellRendererParams): void {
		this.rowNode = params.node;
		({
			items: this.items,
			itemInitialParamsDataProp: this.itemInitialParamsDataProp,
			itemUniqueProp: this.itemUniqueProp,
			itemLabelProp: this.itemLabelProp,
			selectedItemChangeCallback: this.emitDataChange,
		} = params.data.autocomplete);

		let initialItem = this.items.find((item: any) => item[this.itemUniqueProp] === params.data?.[this.itemInitialParamsDataProp]?.[this.itemUniqueProp]);
		if (!initialItem) {
			initialItem = params.data[this.itemInitialParamsDataProp];
		}

		this.searchControl.setValue(initialItem);
		this.currentItem = initialItem;
	}

	refresh(): boolean {
		return true;
	}

	handleSelectOption(event: MatAutocompleteSelectedEvent): void {
		const selectedItem = event.option.value;

		const hasChanged = this.currentItem?.[this.itemUniqueProp] !== selectedItem[this.itemUniqueProp];
		this.currentItem = selectedItem;

		hasChanged && this.emitDataChange(this.rowNode, selectedItem);
	}

	handleSelectClosed(): void {
		if (typeof this.searchControl.value !== 'string') {
			return;
		}
		if (this.searchControl.value === this.currentItem[this.itemLabelProp]) {
			return;
		}

		this.searchControl.setValue(this.currentItem);
	}

	handleBlur(): void {
		if (!this.isMatched) {
			this.searchControl.setValue(this.currentItem);
		}
	}

	displayFn = (item: any): any => {
		return item?.[this.itemLabelProp] ? item[this.itemLabelProp] : '';
	};

	trackByItemUniqueProp(item: any): any {
		return item[this.itemUniqueProp];
	}

	private autocompleteFilter(value: string): any[] {
		const filteredItems = this.items.filter((item: any) => item[this.itemLabelProp].toLowerCase().includes(value.toLowerCase()));
		this.isMatched = filteredItems.length > 0;
		return filteredItems;
	}
}
