<div class="filter-container">
	<span *ngIf="quickFilter === '' || !quickFilter" class="fa-stack">
		<i class="fas fa-filter fa-stack-1x"></i>
	</span>
	<span *ngIf="quickFilter !== '' && quickFilter" class="fa-stack clickable" (click)="clearQuickFilter()">
		<i class="fas fa-filter fa-stack-1x"></i>
		<i class="fas fa-slash fa-stack-1x" style="color: black"></i>
	</span>
	<input
		id="quick-search-input"
		type="text"
		class="form-control"
		[ngModel]="quickFilter"
		(ngModelChange)="applyQuickFilterOnTextChange($event)"
		(keydown)="onKeyDown($event)" />
	<button
		*ngIf="options.showSearchButton"
		id="search-button"
		class="btn btn--secondary btn--secondary-small btn--ml-5 btn--h-32"
		(click)="applyQuickFilterOnClick()">
		{{ 'Search' | translate }}
	</button>
</div>
