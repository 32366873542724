export class ObjectUtils {
  /**
   * Spread properties from one object into another. If property doesn't exist,
   * then it is created. If it exists, then it is modified.
   * If property is array then arrays are combined.
   * @param source any object
   * @param spreadedObject any object that properties will be copied to source object
   * @returns source object with spreaded properties
   */
  static deepSpread(source: object, spreadedObject: object): object {
    if (typeof source === 'undefined' || source === null) {
      return {
        ...(spreadedObject || {})
      }
    }

    Object.keys(spreadedObject).forEach(
      (key: string): void => {
        if (typeof source[key] === 'object') {
          const isArray = Array.isArray(source[key]);
          source[key] = isArray
            ? [...source[key], ...spreadedObject[key]]
            : this.deepSpread(source[key], spreadedObject[key]);
          return;
        }

        source[key] = spreadedObject[key];
      }
    );
    return source;
  }
}
