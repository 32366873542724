import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserMenuOption } from '../../models';
import { User } from '../../models/user.model';
import { UserMenuService } from '../../services/user-menu.service';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'fitech-workspace-user-info',
	templateUrl: './user-info.component.html',
	styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent {
	@Input() customOptions: UserMenuOption[] = [];

	@Output() selectedOption: EventEmitter<UserMenuOption> = new EventEmitter<UserMenuOption>();

	user: BehaviorSubject<User>;

	constructor(private _userService: UserService, private _userMenuService: UserMenuService) {
		this.user = this._userService.currentUserChanged;
	}

	trackByKey(index: number, option: UserMenuOption): string {
		return option.key;
	}

	selectOption(option: UserMenuOption): void {
		this.selectedOption.emit(option);
	}

	logout(): void {
		this._userService.logout();
	}

	menuOpened(): void {
		this._userMenuService.setOpenState();
	}
}
