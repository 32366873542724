export class Result<T> {
	isSuccess: boolean;
	data?: T;
	errorMessage?: string;

	static success<T>(data: T): Result<T> {
		return {
			isSuccess: true,
			data: data,
		};
	}

	static fail<T>(errorMessage: string): Result<T> {
		return {
			isSuccess: false,
			errorMessage: errorMessage,
		};
	}
}
