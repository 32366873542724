import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { CellRendererParamsExt } from './../../models/cell-renderer-params-ext.model';
import { GridBtnRendererOutput } from './../../models/grid-btn-renderer-output.model';

@Component({
	selector: 'fitech-workspace-button-renderer',
	templateUrl: './button-renderer.component.html',
	styleUrls: ['./button-renderer.component.scss'],
})
export class ButtonRendererComponent implements ICellRendererAngularComp {
	params: CellRendererParamsExt;
	data: GridBtnRendererOutput;
	isDisabled = false;

	refresh(): boolean {
		return true;
	}

	agInit(params: CellRendererParamsExt): void {
		this.params = params;
		this.data = {
			tooltip: params.tooltip,
			buttonIcon: params.buttonIcon,
			isDisabled: params.isDisabled,
		};
	}

	// Not sure how to type this one below - looks to me that this method does not fire at all ever & what is more
	// params type on this method (derived method from ICellRendererAngularComp) are totally different type than params in this component.
	afterGuiAttached?(params?: any): void {
		this.params = params;
	}
}
