import { Component, OnInit, ViewChild } from '@angular/core';
import { Alarm, AlarmsApiService, MeasurementsApiService } from '@fitech-workspace/fisense-common-lib';
import {
	AlarmThresholdData,
	ChartSeriesTypeEnum,
	EnableStatesEnum,
	IChartConfig,
	IChartData,
	IChartSeriesConfig,
	IMarkAreaData,
	IMarkAreasConfig,
	LineChartComponent,
	LineChartConfigBuilder,
} from '@fitech-workspace/shared/ui/chart-lib';
import { IDateRange } from '@fitech-workspace/shared/ui/controls-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISensorAddedWithAlarm } from '../../models/machine-components.model';

@Component({
	selector: 'fitech-workspace-sensors-chart-with-alarms',
	templateUrl: './sensors-chart-with-alarms.component.html',
	styleUrls: ['./sensors-chart-with-alarms.component.scss'],
})
export class SensorsChartWithAlarmsComponent implements OnInit {
	@ViewChild('chart', { static: true }) lineChart: LineChartComponent;

	lineChartConfig: IChartConfig;
	dateRange: IDateRange;

	constructor(private _alarmsApi: AlarmsApiService, private _measurementsApi: MeasurementsApiService) {}

	ngOnInit(): void {
		this.initializeLineChart();
	}

	alarmFunc(from: Date, to: Date, sensorId: number): Observable<AlarmThresholdData> {
		return this._alarmsApi.getAlarmsThresholds(from, to, sensorId);
	}

	addSeriesWithAlarm(options: ISensorAddedWithAlarm): void {
		const seriesConfigs = [];
		const alarm = options.alarm;
		this.dateRange = options.dateRange;

		this.lineChart.clearData();

		const dataSeries: IChartSeriesConfig = {
			name: alarm.sensorName,
			type: ChartSeriesTypeEnum.data,
			units: alarm.sensorUnits,
			yAxisMinMax: { min: alarm.sensorMinValue, max: alarm.sensorMaxValue },
			getDataFunc: (from: Date, to: Date, frequency?: number, data?: any) => {
				return this._measurementsApi.getStateHistory(from, to, alarm.sensorId, frequency);
			},
		};
		seriesConfigs.push(dataSeries);

		const alarmThresholdHigh: IChartSeriesConfig = {
			name: 'Alarm high',
			type: ChartSeriesTypeEnum.alarmThresholdSolid,
			units: alarm.sensorUnits,
			getDataFunc: (from: Date, to: Date, frequency?: number, data?: any) =>
				this.alarmFunc(from, to, alarm.sensorId).pipe(map((x: AlarmThresholdData) => x.high?.filter((value: IChartData) => !!value.value) ?? [])),
		};
		seriesConfigs.push(alarmThresholdHigh);
		const alarmThresholdHighHist: IChartSeriesConfig = {
			name: 'Alarm high hysteresis',
			type: ChartSeriesTypeEnum.alarmThresholdDotted,
			units: alarm.sensorUnits,
			getDataFunc: (from: Date, to: Date, frequency?: number, data?: any) =>
				this.alarmFunc(from, to, alarm.sensorId).pipe(
					map((x: AlarmThresholdData) => x.highHysteresis?.filter((value: IChartData) => !!value.value) ?? [])
				),
		};
		seriesConfigs.push(alarmThresholdHighHist);

		// alarm threshold series LOW
		const alarmThresholdLow: IChartSeriesConfig = {
			name: 'Alarm low',
			type: ChartSeriesTypeEnum.alarmThresholdSolid,
			units: alarm.sensorUnits,
			getDataFunc: (from: Date, to: Date, frequency?: number, data?: any) =>
				this.alarmFunc(from, to, alarm.sensorId).pipe(map((x: AlarmThresholdData) => x.low?.filter((value: IChartData) => !!value.value) ?? [])),
		};
		seriesConfigs.push(alarmThresholdLow);
		const alarmThresholdLowHist: IChartSeriesConfig = {
			name: 'Alarm low hysteresis',
			type: ChartSeriesTypeEnum.alarmThresholdDotted,
			units: alarm.sensorUnits,
			getDataFunc: (from: Date, to: Date, frequency?: number, data?: any) =>
				this.alarmFunc(from, to, alarm.sensorId).pipe(
					map((x: AlarmThresholdData) => x.lowHysteresis?.filter((value: IChartData) => !!value.value) ?? [])
				),
		};
		seriesConfigs.push(alarmThresholdLowHist);

		//mark areas
		const markAreasConfig: IMarkAreasConfig = {
			getChartMarkAreasFunc: (from: Date, to: Date, data?: any) =>
				this._alarmsApi
					.getAlarms(from, to, null, alarm.sensorId)
					.pipe(map((x: Alarm[]) => x.map((y: Alarm) => <IMarkAreaData>{ name: y.type, from: y.from, to: y.to, color: y.getAlarmColor() }))),
		};

		this.lineChart.onDateRangeChanged(this.dateRange);
		seriesConfigs.map((series: any) => this.lineChart.addSeries(series));

		this.lineChart.addMarkAreas(markAreasConfig);
		this.lineChart.readMarkAreas();
	}

	private initializeLineChart(): void {
		this.lineChartConfig = {
			autoRefreshChartData: true,
			showFrequency: true,
			showStartStopRefreshData: true,
			minMaxSeries: EnableStatesEnum.EnabledAndSetOff,
			chartOptions: new LineChartConfigBuilder().setDefault().build(),
		};

		this.lineChart.initialize(this.lineChartConfig);
	}
}
