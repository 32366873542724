<div *ngIf="!isMessageBox" class="box">
	<h1>{{ 'Hello' | translate }} {{ loginData?.fullName }},</h1>
	<p>{{ 'Please select roles that you would like to have assigned to your account.' | translate }}</p>
	<p>{{ 'Your request will be checked by our admins and after that process the account will be enabled.' | translate }}</p>

	<form [formGroup]="form" (ngSubmit)="sendMessage()">
		<div class="roles-section">
			<ul>
				<li *ngFor="let role of loginData?.roles; trackBy: trackByName">
					<mat-checkbox color="primary" [formControlName]="role.name">
						<span class="role-name">{{ role.name }}</span> - <span class="role-description">{{ role.description }}</span>
					</mat-checkbox>
				</li>
			</ul>
		</div>
		<div class="button-container">
			<button mat-stroked-button type="submit" color="primary" class="submit-btn btn-color" [disabled]="isInProgress || form.invalid">
				{{ 'Send request' | translate }}
			</button>
		</div>
	</form>
</div>
<div class="box box--pt-64" *ngIf="isMessageBox">
	<p>{{ message | translate }}</p>
</div>
