import { MachineState } from './machine-state.model';
import { SensorDisplayTypeEnum } from './sensor-display-type.enum';
import { SensorTypes } from './sensor-types.model';

export class Machine {
	id: number;
	machineId: number;
	name: string;
	clientId: number;
	zoneId: number;
	zoneName: string;
	locationId: number;
	locationName: string;
	performanceSetpointPerHour: number;
	minimalDowntimeStateDurationInSeconds: number;
	positionX: number;
	positionY: number;
	addedToReport = false;
	units: string;
	badgeClass?: string;
	type = 'machine';
	displayType?: SensorDisplayTypeEnum = SensorDisplayTypeEnum.analog;
	sourceProperty?: string;
	sensorsGroupType?: string;
	alias?: string;

	constructor(data?: any) {
		if (data) {
			this.id = data['id'];
			this.machineId = data['machineId'];
			this.name = data['name'];
			this.alias = data['alias'];
			this.clientId = data['clientId'];
			this.zoneName = data['zoneName'];
			this.zoneId = data['zoneId'];
			this.locationName = data['locationName'];
			this.locationId = data['locationId'];
			this.performanceSetpointPerHour = data['performanceSetpointPerHour'];
			this.minimalDowntimeStateDurationInSeconds = data['minimalDowntimeStateDurationInSeconds'];
			this.positionX = data['positionX'];
			this.positionY = data['positionY'];
			this.units = data['units'];
			this.sensorsGroupType = data['sensorsGroupType'];
		}
	}

	static getIconClass(machineState?: MachineState): string {
		if (machineState && machineState.defaultSensorState && machineState.defaultSensorState.source.type === 'gas_flamable_digi') {
			const type = machineState.defaultSensorState.source.type;
			return SensorTypes.getBySourceProperty(type) ? SensorTypes.getBySourceProperty(type).icon : 'fas fa-cogs';
		}
		return 'fas fa-cogs';
	}
}
