export const iconsConfig: any[] = [
	{
		key: 'cleaningmachine',
		type: 'svg',
		name: 'cleaningmachine',
	},
	{
		key: 'location',
		type: 'fontawesome',
		name: 'fas fa-home',
	},
	{
		key: 'locationunassign',
		type: 'svg',
		name: 'location_remove',
	},
	{
		key: 'zone',
		type: 'fontawesome',
		name: 'fa fa-expand',
	},
	{
		key: 'sensorsgroup',
		type: 'fontawesome',
		name: 'fas fa-cogs',
	},
	{
		key: 'tracker',
		type: 'fontawesome',
		name: 'fa fa-dice-one',
	},
	{
		key: 'beacon',
		type: 'maticon',
		name: 'wifi_tethering',
	},
	{
		key: 'delete',
		type: 'maticon',
		name: 'delete',
	},
	{
		key: 'filter_off',
		type: 'svg',
		name: 'filter_off',
	},
	{
		key: 'email',
		type: 'maticon',
		name: 'email',
	},
	{
		key: 'edit',
		type: 'maticon',
		name: 'edit',
	},
	{
		key: 'user-edit',
		type: 'maticon',
		name: 'swap_horiz',
	},
	{
		key: 'sensor',
		type: 'fontawesome',
		name: 'fas fa-weight',
	},
	{
		key: 'device',
		type: 'fontawesome',
		name: 'fas fa-tablet-alt',
	},
	{
		key: 'machine',
		type: 'fontawesome',
		name: 'fas fa-cogs',
	},
	{
		key: 'sensorsGroup',
		type: 'fontawesome',
		name: 'fas fa-cogs',
	},
	{
		key: 'line',
		type: 'fontawesome',
		name: 'fas fa-ellipsis-h',
	},
	{
		key: 'simcard',
		type: 'fontawesome',
		name: 'fas fa-sim-card',
	},
	{
		key: 'humidity',
		type: 'fontawesome',
		name: 'fas fa-tint',
	},
	{
		key: 'item_counter',
		type: 'fontawesome',
		name: 'fas fa-calculator',
	},
	{
		key: 'item_counter_gain',
		type: 'fontawesome',
		name: 'fas fa-calculator',
	},
	{
		key: 'temperature',
		type: 'fontawesome',
		name: 'fas fa-temperature-low',
	},
	{
		key: 'ambient_light',
		type: 'fontawesome',
		name: 'far fa-lightbulb',
	},
	{
		key: 'light',
		type: 'fontawesome',
		name: 'far fa-lightbulb',
	},
	{
		key: 'pm_2_5',
		type: 'fontawesome',
		name: 'fab fa-cloudversify',
	},
	{
		key: 'pm_10',
		type: 'fontawesome',
		name: 'fas fa-smog',
	},
	{
		key: 'pressure',
		type: 'fontawesome',
		name: 'fas fa-tachometer-alt',
	},
	{
		key: 'tech_rssi',
		type: 'fontawesome',
		name: 'fas fa-broadcast-tower',
	},
	{
		key: 'tech_rsrp',
		type: 'fontawesome',
		name: 'fas fa-broadcast-tower',
	},
	{
		key: 'tech_sinr',
		type: 'fontawesome',
		name: 'fas fa-broadcast-tower',
	},
	{
		key: 'tech_rsrq',
		type: 'fontawesome',
		name: 'fas fa-broadcast-tower',
	},
	{
		key: 'tech_last_xfer_time',
		type: 'fontawesome',
		name: 'fas fa-stopwatch',
	},
	{
		key: 'tech_battery_voltage',
		type: 'fontawesome',
		name: 'fas fa-battery-three-quarters',
	},
	{
		key: 'unknown',
		type: 'fontawesome',
		name: 'far fa-question-circle',
	},
	{
		key: 'refreshing',
		type: 'fontawesome',
		name: 'fas fa-spinner fa-spin',
	},
	{
		key: 'chartarea',
		type: 'fontawesome',
		name: 'fas fa-chart-area fa-lg',
	},
	{
		key: 'alarm',
		type: 'fontawesome',
		name: 'far fa-bell fa-lg',
	},
	{
		key: 'map',
		type: 'fontawesome',
		name: 'fas fa-map',
	},
	{
		key: 'locationmarker',
		type: 'fontawesome',
		name: 'fas fa-map-marker-alt',
	},
	{
		key: 'gas_flamable_digi',
		type: 'fontawesome',
		name: 'fas fa-fire-extinguisher',
	},
	{
		key: 'clone',
		type: 'fontawesome',
		name: 'fas fa-clone',
	},
];
