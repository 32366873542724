import { IDateRange } from '@fitech-workspace/shared/ui/controls-lib';

export interface IChartConfig {
	dateRange?: IDateRange;
	showDateTimePicker?: boolean;
	showFrequency?: boolean;
	autoRefreshChartData?: boolean;
	showStartStopRefreshData?: boolean;
	minMaxSeries?: EnableStatesEnum,
	kpi?: boolean;
	readDataImmediately?: boolean; //read data immediately after date or series changes
	chartOptions: any;
}

export enum EnableStatesEnum {
	EnabledAndSetOn,
	EnabledAndSetOff,
	Disabled
}
