import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionsMap } from '../models';

export abstract class PermissionsMapService {
	isInitialized = false;

	protected abstract readonly map: PermissionsMap;

	private _checkedPermissionsMap: PermissionsMap = new Map<string, string[]>();
	private _backendPermissionsAddedRemovedSubject = new BehaviorSubject<Map<string, boolean>>(new Map<string, boolean>());

	get backendPermissionsAddedRemoved$(): Observable<Map<string, boolean>> {
		return this._backendPermissionsAddedRemovedSubject.asObservable();
	}

	checkFrontendPermissions(keys: string[], updateBackendPermissions: boolean = true): void {
		keys.forEach((key: string) => this.checkFrontendPermission(key));
		if (!updateBackendPermissions) {
			return;
		}
		this.updateBackendPermissions();
	}

	uncheckFrontendPermissions(keys: string[], updateBackendPermissions: boolean = true): void {
		keys.forEach((key: string) => this.uncheckFrontendPermission(key));
		if (!updateBackendPermissions) {
			return;
		}
		this.updateBackendPermissions(keys);
	}

	clearChecked(): void {
		this._checkedPermissionsMap.clear();
		this.isInitialized = false;
	}

	setIsInitialized(): void {
		this.isInitialized = true;
	}

	private checkFrontendPermission(key: string): void {
		const values = this.map.get(key);
		if (!values) {
			return;
		}

		this._checkedPermissionsMap.set(key, [...values]);
	}

	private uncheckFrontendPermission(key: string): void {
		this._checkedPermissionsMap.delete(key);
	}

	private updateBackendPermissions(removedFrontendPermissions: string[] = []): void {
		const permissionsAddedRemoved = new Map<string, boolean>();

		this._checkedPermissionsMap.forEach((values: string[]) => {
			values.forEach((value: string) => permissionsAddedRemoved.set(value, true));
		});

		if (removedFrontendPermissions.length > 0) {
			const removedPermissions: string[] = [];
			removedFrontendPermissions.forEach((permission: string) => removedPermissions.push(...this.map.get(permission)));
			removedPermissions.forEach((permission: string) => permissionsAddedRemoved.set(permission, false));
		}

		this.isInitialized = true;

		this._backendPermissionsAddedRemovedSubject.next(permissionsAddedRemoved);
	}
}
