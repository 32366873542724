<div class="custom-header">
	<div [hidden]="!params.enableMenu" class="customHeaderMenuButton" (click)="onMenuClick()">
		<i class="fa fa-ellipsis-v"></i>
	</div>
	<div class="customHeaderLabel" (click)="onSortRequested($event)">
		{{ params.displayName | translate }}
	</div>
	<div *ngIf="this.sorted === 'asc'" class="customSortLabel">
		<i class="fa fa-arrow-up"></i>
	</div>
	<div *ngIf="this.sorted === 'desc'" class="customSortLabel">
		<i class="fa fa-arrow-down"></i>
	</div>
	<div *ngIf="this.isFiltered" class="customFilterLabel" (click)="clearFilter()">
		<i class="fa fa-times"></i>
	</div>
</div>
