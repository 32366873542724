import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@fitech-workspace/auth-lib';
import { NotificationService } from '@fitech-workspace/core-lib';
import { Subscription } from 'rxjs';
import { CmUserService } from './cm-user.service';
import { CmUser } from '../cm-user.model';
import { RolePermissionFrontendEnum, RolePermissionFrontendModuleEnum } from '../enums';
import { RedirectConfig } from '../models/permissions/redirect-config.model';

@Injectable({
	providedIn: 'root',
})
export class PermissionGuardService implements OnDestroy {
	private _currentUser: CmUser;
	private _subscriptions = new Subscription();
	private _redirectLookup: RedirectConfig[] = [
		{ permission: RolePermissionFrontendEnum.ReportOverallDashboard, redirectUrl: '/overall-dashboard' },
		{ permission: RolePermissionFrontendEnum.ReportShiftDashboard, redirectUrl: '/shift-dashboard' },
		{ permission: RolePermissionFrontendEnum.ReportsProduction, redirectUrl: '/reports/smart-robot' },
		{ permission: RolePermissionFrontendEnum.ReportsAggregate, redirectUrl: '/reports/kpi-utilization' },
		{ permission: RolePermissionFrontendEnum.ReportsTechnical, redirectUrl: '/reports/placement-speed' },
		{ permission: RolePermissionFrontendEnum.MonitoringFloorplan, redirectUrl: '/monitoring/measurements' },
		{ permission: RolePermissionFrontendEnum.MonitoringSensorsAlarms, redirectUrl: '/monitoring/alarms' },
		{ permission: RolePermissionFrontendEnum.MonitoringTrendViewer, redirectUrl: '/monitoring/trend-viewer' },
		{ permission: RolePermissionFrontendEnum.MachinesAlarms, redirectUrl: '/alarms/history' },
		{ permission: RolePermissionFrontendModuleEnum.ManagementLocations, redirectUrl: '/management/locations' },
		{ permission: RolePermissionFrontendModuleEnum.ManagementZones, redirectUrl: '/management/zones' },
		{ permission: RolePermissionFrontendModuleEnum.ManagementLines, redirectUrl: '/management/lines' },
		{ permission: RolePermissionFrontendModuleEnum.ManagementMachines, redirectUrl: '/management/machines' },
		{ permission: RolePermissionFrontendModuleEnum.ManagementDevices, redirectUrl: '/management/devices' },
		{ permission: RolePermissionFrontendModuleEnum.ManagementSensors, redirectUrl: '/management/sensors' },
		{ permission: RolePermissionFrontendModuleEnum.ManagementMachineStates, redirectUrl: '/management/machine-states' },
		{ permission: RolePermissionFrontendModuleEnum.ManagementOperationStatuses, redirectUrl: '/management/single-operation-statuses' },
		{ permission: RolePermissionFrontendModuleEnum.ManagementNotifications, redirectUrl: '/management/notifications' },
		{ permission: RolePermissionFrontendModuleEnum.ManagementShifts, redirectUrl: '/management/shifts' },
		{ permission: RolePermissionFrontendModuleEnum.ManagementClients, redirectUrl: '/management/clients' },
		{ permission: RolePermissionFrontendModuleEnum.ManagementRoles, redirectUrl: '/management/roles' },
		{ permission: RolePermissionFrontendModuleEnum.ManagementUsers, redirectUrl: '/management/users' },
		{ permission: RolePermissionFrontendModuleEnum.ManagementFloorplan, redirectUrl: '/management/plan-editor' },
		{ permission: RolePermissionFrontendModuleEnum.RecipesGenerator, redirectUrl: '/recipes/generator' },
		{ permission: RolePermissionFrontendModuleEnum.RecipesAlgorithms, redirectUrl: '/recipes/algorithms' },
		{ permission: RolePermissionFrontendModuleEnum.RecipesActions, redirectUrl: '/recipes/actions' },
		{ permission: RolePermissionFrontendModuleEnum.Updates, redirectUrl: '/software-packages/updates' },
		{ permission: RolePermissionFrontendModuleEnum.ComponentsItems, redirectUrl: '/components/items' },
		{ permission: RolePermissionFrontendModuleEnum.ComponentsItemClasses, redirectUrl: '/components/item-classes' },
		{ permission: RolePermissionFrontendModuleEnum.ComponentsFeeders, redirectUrl: '/components/feeders' },
		{ permission: RolePermissionFrontendModuleEnum.ComponentsTools, redirectUrl: '/components/tools' },
		{ permission: RolePermissionFrontendModuleEnum.ComponentsFingers, redirectUrl: '/components/fingers' },
		{ permission: RolePermissionFrontendEnum.Dashboard, redirectUrl: '/dashboard/health' },
	];

	private readonly _rolePermissionModules: string[] = Object.values(RolePermissionFrontendModuleEnum);

	constructor(
		private _cmUserService: CmUserService,
		private _userService: UserService,
		private _router: Router,
		private _notificationService: NotificationService
	) {
		this._subscriptions.add(
			this._cmUserService.currentUser$.subscribe((user: CmUser) => {
				this._currentUser = user;
			})
		);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return this._userService.isUserValidAsync().then((valid: boolean) => {
			if (!valid) {
				this._userService.redirectAfterLoginUrl = state.url;
				this._router.navigate(['/login']);
				return false;
			}

			const allowedPermissions = route.data['allowedPermissions'] ?? [];
			const allowedModulePermissions = route.data['allowedModulePermissions'] ?? [];
			const moduleUrl = this.getModuleUrlSegment(state);
			return this.routeGuardLogic(allowedPermissions, allowedModulePermissions, moduleUrl);
		});
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const allowedPermissions = route.data['allowedPermissions'] ?? [];
		const allowedModulePermissions = route.data['allowedModulePermissions'] ?? [];
		const moduleUrl = this.getModuleUrlSegment(state);
		return this.routeGuardLogic(allowedPermissions, allowedModulePermissions, moduleUrl);
	}

	private routeGuardLogic(allowedPermissions: string[], allowedModulePermissions: string[], moduleUrl: string): boolean {
		const isModulePermissions = allowedModulePermissions?.length > 0;
		const isAllowed = isModulePermissions
			? this._currentUser.hasAnyModulePermission(allowedModulePermissions)
			: this._currentUser.hasAnyPermission(allowedPermissions);

		if (isAllowed) {
			return true;
		}

		const redirectConfig = this.getRedirectConfig(moduleUrl);
		let redirectUrl = redirectConfig?.redirectUrl;

		if (!redirectConfig) {
			redirectUrl = '/login';
			this._notificationService.showError('No access. Please contact the administrator.');
		}

		this._router.navigate([redirectUrl]);
		return false;
	}

	private getRedirectConfig(moduleUrl: string): RedirectConfig {
		if (moduleUrl) {
			const moduleRedirectConfigs: RedirectConfig[] = this._redirectLookup.filter(
				(config: RedirectConfig) => config.redirectUrl.startsWith(`/${moduleUrl}/`) || config.redirectUrl === `/${moduleUrl}`
			);
			const availableModuleRedirectConfig: RedirectConfig = moduleRedirectConfigs.find((config: RedirectConfig) => this.hasUserAccess(config));
			if (availableModuleRedirectConfig) {
				return availableModuleRedirectConfig;
			}
		}

		return this._redirectLookup.find((config: RedirectConfig) => this.hasUserAccess(config));
	}

	private hasUserAccess(redirectConfig: RedirectConfig): boolean {
		return this._rolePermissionModules.includes(redirectConfig.permission)
			? this._currentUser.hasModulePermission(redirectConfig.permission)
			: this._currentUser.hasPermission(redirectConfig.permission);
	}

	private getModuleUrlSegment(state: RouterStateSnapshot): string {
		const splitted = state.url.split('/');
		return splitted?.length > 0 ? splitted[1] : '';
	}
}
