import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
	selector: 'fitech-workspace-simple-avatar-renderer',
	templateUrl: './simple-avatar-renderer.component.html',
	styleUrls: ['./simple-avatar-renderer.component.scss'],
})
export class SimpleAvatarRendererComponent implements ICellRendererAngularComp {
	params: any;
	text: string;
	hexColor: string;
	letter: string;
	exclamationMessage: string;
	isExclamation: boolean;
	checkExclamation: (data: any) => boolean;

	refresh(params: any): boolean {
		return true;
	}

	agInit(params: any): void {
		if (params.value) {
			this.text = params.value;
			this.letter = this.text && this.text.length > 0 ? this.text[0] : null;
			this.hexColor = this.stringToHslColor(this.text);
		}

		this.exclamationMessage = params.exclamationMessage;
		this.checkExclamation = params.checkExclamation;
		this.isExclamation = this.checkExclamation && this.checkExclamation(params.data);
	}

	afterGuiAttached?(params?: any): void {
		this.params = params;
	}

	private stringToHslColor(input: string, saturation: number = 70, lightness: number = 50): string {
		let hash = 0;
		for (let i = 0; i < input.length; i++) {
			hash = input.charCodeAt(i) + ((hash << 5) - hash);
		}

		const h = hash % 360;
		return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
	}
}
