export class NumberUtils {
	/**
	 * @description
	 * Check if provided value is valid number.
	 *
	 * @returns True if value is number.
	 */
	static isNumber(value: any): boolean {
		return !isNaN(parseFloat(value)) && isFinite(value);
	}

	/**
	 * @description
	 * Count decimal places in number.
	 *
	 * @returns Number of decimals.
	 */
	static countDecimals(value: number): number {
		if (Math.floor(value) === value) {
			return 0;
		}
		return value.toString().split('.')[1].length || 0;
	}

	/**
	 * @description
	 * Search for maximum number of decimal places in number array.
	 *
	 * @returns Maximal number of decimal places from number array.
	 */
	static maxDecimalPlaces(values: number[]): number {
		const decimalPlaces: number[] = values.map((value: number): number => (value ? this.countDecimals(value) : 0));
		return Math.max(...decimalPlaces);
	}

	/**
	 * @description
	 * Round number to N number of decimal places.
	 *
	 * @returns Rounded number with N decimal places.
	 */
	static toFixedNumber(value: number, decimalPlaces: number): number {
		const power: number = Math.pow(10, decimalPlaces);
		return Math.round(value * power) / power;
	}

	/**
	 * @description
	 * Add two numbers with rounding to maximum decimal place.
	 *
	 * @returns Rounded sum of both values.
	 */
	static addFloatingNumbers(value1: number, value2: number): number {
		const maxDecimalPlaces: number = this.maxDecimalPlaces([value1, value2]);
		const sum: number = value1 + value2;
		return this.toFixedNumber(sum, maxDecimalPlaces);
	}

	static roundToNumber(value?: number, place?: number): number {
		if (value && !Number.isFinite(value)) {
			return value;
		}
		return !value ? null : Number(value.toFixed(place));
	}

	static roundToPercent(value?: any, percent?: number, place?: number): any {
		if (value && !Number.isFinite(value)) {
			return value;
		}
		return !percent ? null : (value * percent).toFixed(place);
	}

	/**
	 * @description
	 * Generates a number based on Date.now() and Math.random() functions. Does not guarantee 100% uniqueness.
	 */
	static generateUniqueNumber(): number {
		return Date.now() + Math.random() * 100000;
	}
}
