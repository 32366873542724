import { AsEnumerable } from 'linq-es2015';
import { MachineMarker } from './machine-marker';

export class MachineIconFactory {
	getIcon(machineMarker: MachineMarker): { iconHtml: string; iconClass: string } {
		{
			const machine = machineMarker.extraData.machine;
			if (machine.defaultSensorState && machine.defaultSensorState.source.type === 'gas_flamable_digi') {
				return this.getFlamableSystemIcon(machineMarker);
			}
			return this.getDefaultIcon(machineMarker);
		}
	}

	getDefaultIcon(machineMarker: MachineMarker) {
		const machine = machineMarker.extraData.machine;
		return {
			iconHtml: `<div class="sensor-detail" data-toggle="tooltip" trackerId="${machineMarker.id}" style="background-color:${machineMarker.stateColor.color}">
        <nobr>
        <i class="sensor-detail-icon fas fa-cogs"></i>
        <span>${machine.source.name}</span>
      </div>`,
			iconClass: 'machine',
		};
	}

	getFlamableSystemIcon(machineMarker: MachineMarker) {
		const machine = machineMarker.extraData.machine;
		const machineTemperatureSensor = AsEnumerable(machine.sensorsStates).FirstOrDefault((x) => x.source.type == 'temperature');
		const machineHumiditySensor = AsEnumerable(machine.sensorsStates).FirstOrDefault((x) => x.source.type == 'humidity');
		return {
			iconHtml: `<div class="sensor-detail sensor-flammable" data-toggle="tooltip" trackerId="${machineMarker.id}"
      style="background-color:${machineMarker.stateColor.color};">
        <i class="sensor-detail-icon fas fa-fire-extinguisher"></i>
        <div class="sensors-container">
          <div class="sensor-value">${machineTemperatureSensor.value} ${machineTemperatureSensor.source.units}</div>
          <div class="sensor-value">${machineHumiditySensor.value} ${machineHumiditySensor.source.units}</div>

     }     </div>
      </div>`,
			iconClass: machine.defaultSensorState.source.type,
		};
	}
}
