<div class="d-flex justify-content-center">
	<button class="btn filter-button filter-measurements-left" (click)="onCurrentStatesClicked()" [ngClass]="{ active: isCurrentStateActive }">
		{{ 'Measurements' | translate }}
	</button>
	<button class="btn filter-button filter-alarms-right" (click)="onAlarmsClicked()" [ngClass]="{ active: !isCurrentStateActive }">
		{{ 'Alarms' | translate }}
	</button>
</div>
<div class="content" [ngClass]="{ 'grid-hidden': !isCurrentStateActive }">
	<div class="content-list" *ngIf="!useHmiSensorList">
		<tra-grid-base
			#currentMachineStatesGrid
			id="data-grid"
			[columnDefs]="currentStateColDefs"
			[rowData]="currentMachineSensorStatesGridData"
			(rowClicked)="onGridRowClicked($event)"></tra-grid-base>
		<tra-quick-filter
			[options]="{ showSearchButton: true, searchOnTextChange: false }"
			(filterChanged)="onQuickFilterChanged($event, 'currentMachineStatesGrid')">
    </tra-quick-filter>
	</div>
	<div class="content-list" *ngIf="useHmiSensorList">
		<fitech-workspace-machine-sensors-hmi-list
			#machineSensorsListHmi
			[currentMachineSensorStatesGridData]="currentMachineSensorStatesGridData"
			[trendSensorIds]="trendSensorIds"
			(sensorAdded)="onSensorAdded($event)"
			(sensorRemoved)="onSensorRemoved($event)">
    </fitech-workspace-machine-sensors-hmi-list>
	</div>
</div>
<div class="content" [ngClass]="{ 'grid-hidden': isCurrentStateActive }">
	<div id="messageBox">
		<i *ngIf="loading" class="fas fa-sync fa-spin refresh-spinner"></i>
	</div>
	<div class="date-picker-container">
		<fitech-workspace-date-time-range-picker-v2
			class="alarm-date-picker"
			#datePicker
			id="datePicker"
			(dateRangeChanged)="onDateRangeChanged($event)">
    </fitech-workspace-date-time-range-picker-v2>
	</div>
	<tra-grid-base
		#machineAlarmsGrid
		id="data-grid"
		[columnDefs]="alarmsColDefs"
		[rowData]="machineAlarmsGridData"
		(rowClicked)="onGridRowClicked($event)">
  </tra-grid-base>
	<tra-quick-filter
		[options]="{ showSearchButton: true, searchOnTextChange: false }"
		(filterChanged)="onQuickFilterChanged($event, 'machineAlarmsGrid')">
  </tra-quick-filter>
</div>
