import { ApolloQueryResult, DocumentNode, FetchPolicy } from '@apollo/client/core';
import { KeyVal } from '@fitech-workspace/core-lib';
import { CreateObjectDto, EditObjectDto } from '@fitech-workspace/shared/data-access/master-data-api-lib';
import { Apollo, MutationResult, QueryRef } from 'apollo-angular';
import { EmptyObject } from 'apollo-angular/types';
import gql from 'graphql-tag';
import { AsEnumerable } from 'linq-es2015';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryNameGraphQLEnum } from '../enums/query-name-graphql-enum';
import { IMasterObjectDefinition } from '../models/master-object-definition.model';
import { IMasterObjectReadDefinition } from '../models/master-object-read-definition.model';

export class MasterObjectsRepositoryQl {
	private _queryFetchPolicy: FetchPolicy = 'network-only'; //https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
	private _baseGraphQlSoftDelete = `mutation softDelete($id: Int!) { $$type$$ { softDelete(id: $id) } }`;

	constructor(private _apollo: Apollo, public definition: IMasterObjectDefinition | IMasterObjectReadDefinition, private _queryName: QueryNameGraphQLEnum) {}

	getAll(): Observable<unknown> {
		return this.getBy();
	}

	getBy(where: string = null): Observable<unknown> {
		let qlQuery = this.definition.graphQlQuery;
		if (!qlQuery) {
			throw 'Missing GraphQl query definition. Check definition property of MasterObjectComponent.';
		}
		const { queriesGroupName, queryName } = this.getQueryData(qlQuery);
		if (where) {
			qlQuery = qlQuery.replace(queryName, `${queryName}(where: ${where})`);
		}
		const query = this.getQlNode(qlQuery);

		return this._apollo
			.query({
				query: query,
				fetchPolicy: this._queryFetchPolicy,
			})
			.pipe(
				map((result: MutationResult<any>) => {
					return result.data[queriesGroupName][queryName];
				})
			);
	}

	getById(id: number): Observable<any> {
		const where = `{id: {eq:${id}}}`;
		return this.getBy(where).pipe(
			map((results: unknown): any => {
				if (!Array.isArray(results)) {
					return results;
				}
				if (results?.length === 1) {
					return results[0];
				}
				if (results?.length > 1) {
					throw new Error(`There are multiple objects with id: ${id}.`);
				}

				throw new Error(`There is no object with id: ${id}.`);
			})
		);
	}

	getByIds(ids: number[]): Observable<any[]> {
		const where = `{id: {in: [${ids}]}}`;
		return this.getBy(where).pipe(
			map((results: unknown): any[] => {
				if (!Array.isArray(results)) {
					throw new Error('Query result is not array.');
				}
				if (results.length !== ids.length) {
					const notExisting: number[] = ids.filter((id: number) => !results.some((result: any) => id === result.id));
					throw new Error(`Some objects with ids (${notExisting}) don't exist.`);
				}

				return results;
			})
		);
	}

	getByClient(clientIds: number[] | number): Observable<unknown> {
		let where = `{clientId: {eq:${clientIds}}}`;
		if (Array.isArray(clientIds)) {
			where = `{clientId: {in: [${clientIds}]}}`;
		}

		return this.getBy(where);
	}

	getSimpleByClient(clientIds: number[] | number): Observable<KeyVal[]> {
		return this.getByClient(clientIds).pipe(
			map((res: any[]) => {
				return AsEnumerable(res)
					.Select((x: any) => {
						return { value: x.name, key: x.id };
					})
					.ToArray();
			})
		);
	}

	getCustomSimpleQuery<T>(fields: string, whereConditions?: string): Observable<T> {
		const whereClause = whereConditions?.length ? `(where: { ${whereConditions} })` : '';

		const graphQlQuerySimple = `
			query {
				${this._queryName} {
					all ${whereClause} {
						${fields}
					}
				}
			}
        `;

		return this.getCustomQuery<T>(graphQlQuerySimple);
	}

	watchQuery<T>(): QueryRef<T, EmptyObject> {
		const qlQuery = this.definition.graphQlQuery;
		const query = this.getQlNode(qlQuery);

		return this._apollo.watchQuery<T>({ query: query });
	}

	createObject(createReq: any): Observable<CreateObjectDto> {
		const masterObjectDefinition = this.definition as IMasterObjectDefinition;

		if (!masterObjectDefinition || !masterObjectDefinition.graphQlCreate) {
			throw new Error('Invalid or missing GraphQl create mutation definition. Check definition property of MasterObjectComponent.');
		}

		const qlMutation = masterObjectDefinition.graphQlCreate;

		if (createReq.dateCreated === '') {
			createReq.dateCreated = null;
		}

		const { typeName } = this.getMutationData(qlMutation);

		return this._apollo
			.mutate({
				mutation: this.getQlNode(qlMutation),
				variables: createReq,
			})
			.pipe(map((result: MutationResult<any>) => result.data[typeName]?.create));
	}

	editObject(editReq: any): Observable<EditObjectDto> {
		const masterObjectDefinition = this.definition as IMasterObjectDefinition;

		if (!masterObjectDefinition || !masterObjectDefinition.graphQlUpdate) {
			throw new Error('Invalid or missing GraphQl create mutation definition. Check definition property of MasterObjectComponent.');
		}

		const qlMutation = masterObjectDefinition.graphQlUpdate;

		const { typeName } = this.getMutationData(qlMutation);

		return this._apollo
			.mutate({
				mutation: this.getQlNode(qlMutation),
				variables: editReq,
			})
			.pipe(
				map((result: MutationResult<any>) => {
					return result.data[typeName]?.update;
				})
			);
	}

	deleteObject(objectId: number, forceChildsUnassigment?: boolean, forceParentsUnassigment?: boolean, forceChildsDeletion?: boolean): Observable<number> {
		const qlMutation =
			this.definition.type.slice(-1) === 's'
				? this._baseGraphQlSoftDelete.replace('$$type$$', this.definition.type + 'es')
				: this._baseGraphQlSoftDelete.replace('$$type$$', this.definition.type + 's');
		const { typeName } = this.getMutationData(qlMutation);

		return this._apollo
			.mutate({
				mutation: this.getQlNode(qlMutation),
				variables: { id: objectId },
			})
			.pipe(
				map((result: MutationResult<any>): number => {
					return result.data[typeName]?.softDelete;
				})
			);
	}

	private getCustomQuery<T>(qlQuery: string): Observable<T> {
		if (!qlQuery) {
			throw 'Missing GraphQl query definition. Check definition property of MasterObjectComponent.';
		}

		const { queriesGroupName, queryName } = this.getQueryData(qlQuery);
		const query = this.getQlNode(qlQuery);

		return this._apollo
			.query({
				query: query,
				fetchPolicy: this._queryFetchPolicy,
			})
			.pipe(
				map((result: ApolloQueryResult<Record<string, Record<string, T>>>): T => {
					return result.data[queriesGroupName][queryName];
				})
			);
	}

	private getQueryData(query: string): { queriesGroupName: string; queryName: string } {
		const querySplited = query.split(/{|\(/).map((x: string) => x.replace(/\s/g, ''));
		const queriesGroupName = querySplited[1];
		const queryName = querySplited[2];
		return { queriesGroupName, queryName };
	}

	private getMutationData(mutation: string): { typeName: string } {
		const querySplited = mutation.split('{').map((x: string) => x.replace(/\s/g, ''));
		const typeName = querySplited[1];
		return { typeName };
	}

	private getQlNode(qlQuery: string): DocumentNode {
		return gql`
			${qlQuery}
		`;
	}
}
