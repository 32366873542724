import { Directive, Input, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[addDynamicComponent]',
})
export class AddDynamicComponentDirective {
	@Input() componentId: string;

	constructor(public viewContainerRef: ViewContainerRef) {}
}
