import { Component, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { ScreenSizeService } from './screen-size.service';
import { SCREEN_SIZE } from './screen-size.enum';
import { SCREEN_ORIENTATION } from './screen-orientation.enum';

@Component({
    selector: 'fitech-workspace-size-detector',
    templateUrl: './size-detector.component.html',
    styleUrls: ['./size-detector.component.scss']
})
export class SizeDetectorComponent implements AfterViewInit {
    prefix = 'size-detector-';
    sizes = [
        {
            id: SCREEN_SIZE.XS, name: 'xs'
        },
        {
            id: SCREEN_SIZE.MD, name: 'md'
        },
        {
            id: SCREEN_SIZE.LG, name: 'lg'
        }
    ];

    constructor(private screenSizeService: ScreenSizeService) { }

    @HostListener("window:resize", ['$event'])
    private onResize(event) {

        this.detectScreenSize(event);
    }

    @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(event) {

        this.detectScreenSize(event);
    }

    ngAfterViewInit() {

        this.screenSizeService.innerWidth = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        this.screenSizeService.innerHeight = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;

        this.detectScreenSize();
    }

    private detectScreenSize(event?: any) {

        if (event) {
            this.screenSizeService.innerWidth = event.target.innerWidth;
            this.screenSizeService.innerHeight = event.target.innerHeight;
        }

        if (this.screenSizeService.innerHeight > this.screenSizeService.innerWidth) {
            this.screenSizeService.orientation = SCREEN_ORIENTATION.PORTRAIT
        } else {
            this.screenSizeService.orientation = SCREEN_ORIENTATION.LANDSCAPE
        }


        const currentSize = this.sizes.find(x => {

            const className = `${this.prefix}${x.name}`;
            const el = document.getElementsByClassName(className);
            if (el.length > 0) {
                const isVisible = window.getComputedStyle(el[0]).display !== 'none';
                return isVisible;
            }
        });
        if (currentSize)
            this.screenSizeService.onResize(currentSize.id);
    }

}