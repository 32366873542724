import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'fitech-workspace-login-redirect',
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.scss']
})
export class LoginRedirectComponent implements OnInit {
  @Input() logoSrc: string;
  returnUrl: any;
  $counter: any;
  secondsToRedirect: number;
  isInProgress: boolean;

  constructor(private userService: UserService,
    private activateRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.activateRoute.params.subscribe(async params => {
      const data = params['data'];
      if (data != null) {
        const loginData = JSON.parse(this.decodeUnicode(data));
        await this.userService.saveTokensFromAd(loginData);
        this.router.navigate(['/']);
      }
    });
  }

  decodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

}
