import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AsEnumerable } from 'linq-es2015';

@Component({
	selector: 'fitech-workspace-chips-with-icon-renderer',
	templateUrl: './chips-with-icon-renderer.component.html',
	styleUrls: ['./chips-with-icon-renderer.component.scss'],
})
export class ChipsWithIconRendererComponent implements ICellRendererAngularComp {
	params: any;
	data: IChipsWithIconData[] = [];

	constructor(private _router: Router) {}

	refresh(params: any): boolean {
		return true;
	}

	agInit(params: any): void {
		let data = params.data.getChipsWithIcon(params.colDef.field, params.cssClass);

		if (params.sortBy) {
			data = AsEnumerable(data)
				.OrderBy((x: any) => x[params.sortBy])
				.ToArray();
		}
		if (params.sortBy && params.sortThanBy) {
			data = AsEnumerable(data)
				.OrderBy((x: any) => x[params.sortBy])
				.ThenBy((x: any) => x[params.sortThanBy])
				.ToArray();
		}

		this.data = data;
	}

	afterGuiAttached?(params?: any): void {
		this.params = params;
	}

	onClick(item: IChipsWithIconData): void {
		if (item.onClickUrl) {
			this.goToPageWithFilter(item.onClickUrl.url, item.onClickUrl.gridFilter);
		}
	}

	goToPageWithFilter(routeName: string, gridFilter?: string): void {
		let params = undefined;
		if (gridFilter) {
			params = { queryParams: { gridFilter: gridFilter } };
		}
		this._router.navigate([routeName], params);
	}
}
export interface IChipsWithIconData {
	cssClass: string;
	value: string;
	icon: string;
	tooltip: string;
	id: number;
	onClickUrl?: { url: string; gridFilter: string };
}
export interface IChipsWithIconDataProvider {
	getChipsWithIcon(field: string, cssClass: string, icon: string): IChipsWithIconData[];
}
