import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as userActions from '../actions/user.actions';
import * as userSettingsSelectors from '../selectors/user-settings.selectors';

@Injectable()
export class UserFacadeService {
	canAppBeClosed$: Observable<boolean> = this._store.pipe(select(userSettingsSelectors.selectIsAllDataSaved));

	constructor(private readonly _store: Store) {}

	loggedInSuccess(): void {
		this._store.dispatch(userActions.loggedInSuccess());
	}

	loggedOutSuccess(): void {
		this._store.dispatch(userActions.loggedOutSuccess());
	}

	beforeCloseApplication(): void {
		this._store.dispatch(userActions.beforeCloseApplication());
	}

	afterHideApplication(): void {
		this._store.dispatch(userActions.afterHideApplication());
	}
}
