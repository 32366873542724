export class StringUtils {
	static isNullOrWhiteSpace(str: string): boolean {
		return !str || !str.trim();
	}

	static isNullOrUndefined(value: string): boolean {
		return typeof value === 'undefined' || value === null;
	}

	static isNotNullOrUndefined(value: string): boolean {
		return !StringUtils.isNullOrUndefined(value);
	}

	static isStartOrEndWithWhitespace(value: string): boolean {
		return /^\s|\s$/.test(value);
	}

	/**
	 * @description
	 * Generate unique ID.
	 */
	static generateUniqueId(): string {
		const id = (length: number): string =>
			Math.random()
				.toString(36)
				.substring(3, 3 + length);

		return `${id(8)}-${id(4)}-${id(4)}-${id(8)}`;
	}
}
