import { HttpClient } from '@angular/common/http';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AsEnumerable } from 'linq-es2015';
import { Observable, Subject, forkJoin } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { Sensor } from '../../../models/sensor.model';
import { State } from '../../../models/state.model';
import { HmiGroupDeclaration } from '../models/hmi-group-declaration.model';

@Component({
	selector: 'fitech-workspace-machine-sensors-hmi-list',
	templateUrl: './machine-sensors-hmi-list.component.html',
	styleUrls: ['./machine-sensors-hmi-list.component.scss'],
})
export class MachineSensorsHmiListComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
	@Input() currentMachineSensorStatesGridData: State[] = [];
	@Input() groupDeclaration: HmiGroupDeclaration[] = [{ label: 'Sensors', statesNames: [] }];
	@Input() loading = false;
	@Input() trendSensorIds: number[] = [];

	@Output() sensorAdded = new EventEmitter<Sensor>();
	@Output() sensorRemoved = new EventEmitter<Sensor>();

	@ViewChild('mainContainer', { static: true }) mainContainerDiv: ElementRef;

	isCurrentStateActive = true;
	isWideContainer1000 = false;
	isWideContainer1500 = false;

	searchFilterControl = new FormControl('');

	groupDeclarationToDisplay: HmiGroupDeclaration[] = [];
	filteredGroupDeclarationToDisplay: HmiGroupDeclaration[] = [];

	private unsubscribe$ = new Subject<void>();

	constructor(private _httpClient: HttpClient) {}

	ngAfterViewChecked(): void {
		this.isWideContainer1000 = this.mainContainerDiv.nativeElement.offsetWidth >= 1000 - 100;
		this.isWideContainer1500 = this.mainContainerDiv.nativeElement.offsetWidth >= 1500 - 100;
	}

	ngOnInit(): void {
		this.readGroupConfigsFromAssets().subscribe(() => {
			this.splitIntoGroups();
		});

		this.searchFilterControl.valueChanges.pipe(startWith(''), debounceTime(500), takeUntil(this.unsubscribe$)).subscribe((filterValue: string) => {
			this.filterIndicators(filterValue);
		});
	}

	ngAfterViewInit(): void {
		this.refresh();
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	readGroupConfigsFromAssets(): Observable<any> {
		const actions = [
			this._httpClient.get('assets/machine-sensors-groups-configs/default.json'),
			this._httpClient.get('assets/machine-sensors-groups-configs/welders.json'),
		];

		this.groupDeclaration = [];
		return forkJoin(actions).pipe(
			map((res) => {
				if (res && res[0]) {
					this.groupDeclaration = this.groupDeclaration.concat(res[0] as any[]);
				}
				if (res && res[1]) {
					this.groupDeclaration = this.groupDeclaration.concat(res[1] as any[]);
				}
			})
		);
	}

	splitIntoGroups(): void {
		const allStatesEnumerable = AsEnumerable(this.currentMachineSensorStatesGridData);

		//add to declared groups
		this.groupDeclaration.forEach((groupDeclaration: HmiGroupDeclaration) => {
			const states = allStatesEnumerable
				.Where(
					(x: State) => x.source && AsEnumerable(groupDeclaration.statesNames).Any((stateName: string) => x.source.sourceProperty.includes(stateName))
				)
				.OrderBy((x: State) => x.source.name)
				.ToArray();
			groupDeclaration.data = states;
		});

		//add items not declared in any group
		const statesAssignedIntoGroups = AsEnumerable(this.groupDeclaration).SelectMany((x: HmiGroupDeclaration) => x.data);
		const statesNotInAnyGroup = allStatesEnumerable.Where((x: State) => !statesAssignedIntoGroups.Any((st: unknown) => st === x)).ToArray();

		if (statesNotInAnyGroup.length > 0) {
			this.groupDeclaration.push({
				label: 'General',
				statesNames: [],
				data: statesNotInAnyGroup,
			});
		}

		const filteredGroupDeclaration = this.groupDeclaration.filter((x: HmiGroupDeclaration) => x.data?.length > 0);
		this.groupDeclarationToDisplay = filteredGroupDeclaration.filter((x: HmiGroupDeclaration) =>
			x.data.filter((y: any) => !(y.source && y.source.type && y.source.type.toLowerCase().startsWith('control')))
		);

		//sort alphabetically
		this.groupDeclarationToDisplay[0].data.sort((a: any, b: any) => a.source.name.localeCompare(b.source.name));
		this.filteredGroupDeclarationToDisplay = this.groupDeclarationToDisplay.map((x: HmiGroupDeclaration) => ({ ...x, data: [...x.data] }));

		this.filterIndicators(this.searchFilterControl.value);
	}

	filterIndicators(filterValue: string): void {
		this.filteredGroupDeclarationToDisplay[0].data = this.groupDeclarationToDisplay[0].data.filter((x: any) =>
			x.source.name.toLowerCase().trim().includes(filterValue.toLowerCase().trim())
		);
	}

	refresh(): void {
		if (this.groupDeclaration?.length > 0) {
			this.splitIntoGroups();
		}
	}

	trackByStateId(index: number, state: State): number {
		return state.sourceId;
	}

	toggleChart(state: State): void {
		if (this.trendSensorIds.includes(state.sourceId)) {
			this.sensorRemoved.emit(state.source as any);
		} else {
			this.sensorAdded.emit(state.source as any);
		}
	}
}
