<div class="trend-sensor" [ngClass]="{ 'trend-sensor--hidden': !trendSensor.visible, 'trend-sensor--last': isLast }">
	<button type="button" class="btn trend-sensor__main-btn" (click)="toggleVisibility()">
		<div class="trend-sensor__color" [style.background-color]="trendSensor.color"></div>
		<div class="trend-sensor__content">
			<div class="trend-sensor__title">
				<div class="trend-sensor__icon" [ngClass]="trendSensor.iconClass"></div>
				<span class="trend-sensor__name">{{ trendSensor.name }}</span>
			</div>
			<span class="trend-sensor__description">{{ trendSensor.machineAlias || trendSensor.machineName || trendSensor.zoneName }}</span>
		</div>
	</button>
	<button
		*ngIf="trendSensor.visible"
		type="button"
		class="btn btn--icon-24 btn--icon-default btn--mr-4 trend-sensor__secondary-btn"
		(click)="toggleChartType()">
		<i class="fas icon" [ngClass]="{ 'fa-chart-line': trendSensor.isLineVisible, 'fa-ellipsis-v icon--rotate-45': !trendSensor.isLineVisible }"></i>
	</button>
	<button type="button" class="btn btn--icon-24 btn--icon-default trend-sensor__secondary-btn" (click)="removeSensor()"><i class="fas fa-trash"></i></button>
</div>
