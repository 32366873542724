import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AddDynamicComponentDirective } from '@fitech-workspace/core-lib';
import { Subscription } from 'rxjs';
import { DynamicComponentData, SwitchQuestion } from '../../models';
import { QuestionBase } from '../../models/question-base';
import { CustomQuestion } from '../../models/question-custom';
import { DropdownQuestion } from '../../models/question-dropdown';
import { QuestionControlService } from '../../services/question-control.service';

@Component({
	selector: 'fitech-workspace-dynamic-form',
	templateUrl: './dynamic-form.component.html',
	styleUrls: ['./dynamic-form.component.scss'],
	providers: [QuestionControlService],
})
export class DynamicFormComponent implements OnInit, AfterViewInit {
	@Input() questions: any;
	@Input() showUndoButton: boolean;
	@Input() isSubmitting: boolean;
	@Input() isSubmitted: boolean;

	@Output() saved: EventEmitter<any> = new EventEmitter();
	@Output() cancelled: EventEmitter<any> = new EventEmitter();

	@ViewChildren(AddDynamicComponentDirective) private _addDynamicComponent!: QueryList<AddDynamicComponentDirective>;

	form: UntypedFormGroup;
	payload = '';
	questionsArray: QuestionBase<any>[];

	private _subscriptions = new Subscription();
	private _initializedCustomComponents: string[] = [];

	get canSave(): boolean {
		return this.form.valid && !this.isSubmitting;
	}

	constructor(private _qcs: QuestionControlService, private _cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.questionsArray = this._qcs.toArray(this.questions);
		this.form = this._qcs.toFormGroup(this.questionsArray);
		this.listenSwitchChanges();
	}

	ngAfterViewInit(): void {
		this.initializeCustomComponents();
	}

	isValid(key: string): boolean {
		return this.form.controls[key].valid;
	}

	isTouched(key: string): boolean {
		return this.form.controls[key].touched;
	}

	getErrorMsg(key: string): string {
		let errorMsg = '';
		if (this.isTouched(key)) {
			if (this.form.controls[key].hasError('required')) {
				errorMsg += 'Field is required. ';
			}
			if (this.form.controls[key].hasError('email')) {
				errorMsg += 'Field does not meet e-mail pattern requirement. ';
			}
			if (this.form.controls[key].hasError('pattern')) {
				errorMsg += 'Field does not meet pattern requirement. ';
			}
			if (this.form.controls[key].hasError('min')) {
				errorMsg += `Minimum value is ${this.form.controls[key].errors.min.min}`;
			}
			if (this.form.controls[key].hasError('custom')) {
				errorMsg += `${this.form.controls[key].errors.custom.msgError}`;
			}
			if (this.form.controls[key].hasError('minOneRequired')) {
				errorMsg += 'Minimum one selection is required. ';
			}
			if (this.form.controls[key].hasError('incorrect')) {
				errorMsg += 'Field contains incorrect data. ';
			}
			if (this.form.controls[key].hasError('listInvalid')) {
				errorMsg += 'Field contains invalid list. ';
			}
			if (this.form.controls[key].hasError('notPositiveNumber')) {
				errorMsg += 'Field is requires positive number. ';
			}
			if (this.form.controls[key].hasError('invalidNumberFormat')) {
				errorMsg += 'Number is not properly defined. ';
			}
			if (this.form.controls[key].hasError('invalidDecimalPrecision')) {
				errorMsg += 'Invalid amount of digits before or after decimal point.';
			}
			if (this.form.controls[key].hasError('isLeadingOrTrailingWhitespace')) {
				errorMsg += 'Field cannot contain leading or trailing whitespaces.';
			}
			if (this.form.controls[key].hasError('isOnlyWhiteSpace')) {
				errorMsg += 'Field cannot contain only whitespaces.';
			}
			if (this.form.controls[key].hasError('invalidTimeFormat')) {
				errorMsg += 'Invalid time format. The correct format is HH:mm:ss.';
			}
			if (this.form.controls[key].hasError('nonUniqueKeys')) {
				errorMsg += 'Parameter keys must be unique.';
			}
			if (this.form.controls[key].hasError('outOfRange')) {
				errorMsg += this.form.controls[key].getError('outOfRange').message;
			}
		}
		return errorMsg;
	}

	onSave(): void {
		const results = this.form.getRawValue();

		for (const element in results) {
			if (!this.questions[element]) {
				continue;
			}

			if (this.questions[element].type === 'number') {
				if (results[element] !== null && results[element] !== '') {
					results[element] = +results[element]; //convert to number
				} else {
					results[element] = null;
				}
			}

			if (this.questions[element] instanceof DropdownQuestion) {
				results[`${element}Id`] = results[element] !== null ? results[element].key : null;
			}
		}

		const data = { ...results, form: { ...this.form } };
		this.saved.emit(data);
	}

	onCancel(): void {
		this.cancelled.emit(this.form.getRawValue());
	}

	private initializeCustomComponents(): void {
		if (!this._addDynamicComponent?.length) {
			return;
		}

		this._subscriptions.add(
			this._addDynamicComponent.changes.subscribe(() => {
				this.loadCustomComponents();
			})
		);
		this.loadCustomComponents();
	}

	private loadCustomComponents(): void {
		this._initializedCustomComponents = this._initializedCustomComponents.filter((componentId: string) =>
			this._addDynamicComponent.some((component: AddDynamicComponentDirective) => component.componentId === componentId)
		);
		this._addDynamicComponent.forEach((item: AddDynamicComponentDirective) => {
			const question: CustomQuestion = this.questionsArray.find((question: QuestionBase<any>) => question.key === item.componentId) as CustomQuestion;
			if (!question?.component || this._initializedCustomComponents.includes(item.componentId)) {
				return;
			}

			const viewContainerRef: ViewContainerRef = item.viewContainerRef;
			viewContainerRef.clear();

			const componentRef = viewContainerRef.createComponent<DynamicComponentData>(question.component);
			componentRef.instance.data = question.data;
			componentRef.instance.form = this.form;
			componentRef.instance.question = question;
			this._initializedCustomComponents.push(item.componentId);
		});
		this._cdr.detectChanges();
	}

	private listenSwitchChanges(): void {
		const switchQuestions = this.questionsArray
			.filter((question: QuestionBase<any>) => question instanceof SwitchQuestion)
			.map((question: QuestionBase<any>) => question as SwitchQuestion);

		if (!switchQuestions?.length) {
			return;
		}
		switchQuestions.forEach((switchQuestion: SwitchQuestion) => {
			if (this.form.contains(switchQuestion.key) && !!switchQuestion.togglingVisibilityKeys?.length) {
				this._subscriptions.add(
					this.form.controls[switchQuestion.key].valueChanges.subscribe((value: boolean) => {
						if (this.questionsArray.every((question: QuestionBase<any>) => !switchQuestion.togglingVisibilityKeys.includes(question.key))) {
							return;
						}
						this.questionsArray.forEach((question: QuestionBase<any>) => {
							if (!switchQuestion.togglingVisibilityKeys.includes(question.key)) {
								return;
							}

							question.isHidden = !value;
						});
					})
				);
			}
		});
	}
}
