import { AgEditorComponent } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { NumberUtils } from '@fitech-workspace/core-lib';
import { KeyboardEventKeyEnum } from '../../enums';
import { GridComponent } from '../../grid/grid.component';

@Component({
	selector: 'numeric-editor',
	templateUrl: './numeric-editor.component.html',
	styleUrls: ['../common/editor-styles.scss'],
})
export class NumericEditorComponent implements AgEditorComponent {
	value: number | null;
	min: number | null;
	max: number | null;
	highlightAllOnFocus = true;

	private _cancelBeforeStart = false;
	private _params: ICellEditorParams<any, number | null, { componentParent: GridComponent }> & { min?: number; max?: number };

	agInit(params: ICellEditorParams<any, number | null, { componentParent: GridComponent }> & { min?: number; max?: number }): void {
		this._params = params;
		this.setInitialState();
	}

	getValue(): number | null {
		return this.value;
	}

	isCancelBeforeStart(): boolean {
		return this._cancelBeforeStart;
	}

	isCancelAfterEnd(): boolean {
		if (this.min !== null && this.value < this.min) {
			return true;
		}
		if (this.max !== null && this.value > this.max) {
			return true;
		}
		return false;
	}

	isPopup(): boolean {
		return this._params.column.getUserProvidedColDef()?.cellEditorPopup ?? true;
	}

	getPopupPosition(): 'over' | 'under' {
		return this._params.column.getUserProvidedColDef()?.cellEditorPopupPosition ?? 'over';
	}

	onKeyDown(event: KeyboardEvent): void {
		if (this.isLeftOrRight(event) || this.isDeleteOrBackspace(event)) {
			event.stopPropagation();
			return;
		}
	}

	private setInitialState(): void {
		let startValue: number | null;
		let highlightAllOnFocus = true;
		const validKeys = '-1234567890.';
		const isKeyPressed = !!this._params.eventKey;
		const isValidKeyPressed: boolean = isKeyPressed && validKeys.indexOf(this._params.eventKey) >= 0;

		if (this._params.eventKey === KeyboardEventKeyEnum.Backspace || this._params.eventKey === KeyboardEventKeyEnum.Delete) {
			startValue = null;
		} else if (isValidKeyPressed) {
			startValue = Number(this._params.eventKey);
			highlightAllOnFocus = false;
		} else {
			startValue = this._params.value;
			highlightAllOnFocus = this._params.eventKey !== KeyboardEventKeyEnum.F2;
		}

		this.min = NumberUtils.isNumber(this._params?.min) ? this._params?.min : null;
		this.max = NumberUtils.isNumber(this._params?.max) ? this._params?.max : null;
		this.value = startValue;
		this.highlightAllOnFocus = highlightAllOnFocus;
		this._cancelBeforeStart = isKeyPressed && !isValidKeyPressed;
	}

	private isDeleteOrBackspace(event: KeyboardEvent): boolean {
		const keys: string[] = [KeyboardEventKeyEnum.Delete, KeyboardEventKeyEnum.Backspace];
		return keys.includes(event.key);
	}

	private isLeftOrRight(event: KeyboardEvent): boolean {
		const keys: string[] = [KeyboardEventKeyEnum.Left, KeyboardEventKeyEnum.Right];
		return keys.includes(event.key);
	}
}
