import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HistoryLog } from '../../models/history-log.model';

@Component({
	selector: 'fitech-workspace-history-dialog',
	templateUrl: './history-dialog.component.html',
	styleUrls: ['./history-dialog.component.scss'],
})
export class HistoryDialogComponent implements OnInit {
	logs: HistoryLog[] = [];
	title: string;

	constructor(@Inject(MAT_DIALOG_DATA) public data: { logs: HistoryLog[]; title: string }, public dialogRef: MatDialogRef<HistoryDialogComponent>) {}

	ngOnInit(): void {
		this.logs = this.data.logs;
		this.title = this.data.title;
	}

	onCancel(): void {
		this.dialogRef.close({ result: false, shouldReloadTargets: false });
	}
}
