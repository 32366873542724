<div class="mat-select-search">
	<input
		#searchInput
		class="mat-select-search__input mat-option-text"
		type="text"
		autocomplete="off"
		[placeholder]="placeholder"
		[formControl]="searchControl"
		[ngClass]="{ 'mat-select-search__input--smaller': isSelectButtons }"
		(keydown.space)="$event.stopPropagation()" />
	<button
		class="mat-select-search__clear"
		[ngClass]="{ 'mat-select-search__clear--smaller': isSelectButtons }"
		*ngIf="searchControl.value"
		matSuffix
		mat-icon-button
		aria-label="Clear"
		(click)="clearValue(true)">
		<mat-icon>close</mat-icon>
	</button>
	<div class="mat-select-search__divider"></div>
	<div *ngIf="isNoResults" class="mat-select-search__no-results">{{ noResultsText }}</div>
</div>
