import { TimeFormatter } from '@fitech-workspace/core-lib';
import * as moment from 'moment';
import { AlarmState } from './alarm-state.model';

export class Alarm {
	guid: string;
	from: Date;
	to: Date;
	type: string;
	sensorId: number;
	sensorName: string;
	sensorUnits: string;
	sensorPointType: string;
	sensorLocationName: string;
	sensorLocationId: number;
	sensorZoneName: string;
	sensorZoneId: number;
	sensorType: string;
	sensorMachineName: string;
	lastState: AlarmState;
	extendedData: any;
	addedToReport = false;
	extremeValue?: number;
	sensorMinValue?: number;
	sensorMaxValue?: number;
	sensorMachineId?: number;
	sensorMachineAlias?: string;

	get durationInSeconds(): number {
		return moment.duration(moment(this.to || moment()).diff(moment(this.from))).asSeconds();
	}

	get duration(): string {
		return TimeFormatter.formatDuration(this.durationInSeconds * 1000, TimeFormatter.shortDurationFormatter);
	}

	constructor(data?: any) {
		if (data) {
			for (const property in data) {
				if (data.hasOwnProperty(property)) {
					(<any>this)[property] = (<any>data)[property];
				}
			}

			this.lastState = data['lastState'];
			this.sensorType = data['sensorPointType'];
		}
	}

	getAlarmColor(): string {
		if (this.type === 'High') {
			return 'rgba(220, 53, 70, 0.906)';
		}
		if (this.type === 'Low') {
			return 'rgba(134, 98, 224, 0.8)';
		}
	}
}
