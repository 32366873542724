export enum GridCellRendererEnum {
	Avatar = 'avatarRenderer',
	SimpleAvatar = 'simpleAvatarRenderer',
	Button = 'buttonRenderer',
	LabelledButton = 'labelledButtonRenderer',
	Chips = 'chipsRenderer',
	SimpleChipsWithIcon = 'simpleChipsWithIconRenderer',
	ChipsWithIcon = 'chipsWithIconRenderer',
	LabelledChips = 'labelledChipsRenderer',
	Checkbox = 'checkboxRenderer',
	Autocomplete = 'autocompleteRenderer',
	Link = 'linkRenderer',
	Text = 'textrenderer',
	Drag = 'dragRenderer',
	Sparkline = 'sparklineRenderer',
}
