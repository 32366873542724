<div id="main-container">
	<div class="buttons-container">
		<div *ngIf="showDateTimePicker" class="date-picker">
			<fitech-workspace-date-time-range-picker-v2
				#dateTimeRangePicker
				id="datePicker"
				[initialDateRange]="dateRange"
				[disabled]="refreshing"
				[showFrequency]="showFrequency"
				(dateRangeChanged)="onDateRangeChanged($event)"
				(frequencyChanged)="onDateRangeChanged($event)">
			</fitech-workspace-date-time-range-picker-v2>
		</div>
		<div *ngIf="showMinMaxButton" class="button-selector">
			<button class="btn btn--icon-primary selectType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="refreshing">
				<i class="fas fa-ellipsis-v"></i>
			</button>

			<div class="dropdown-menu" aria-labelledby="dropdownMenuButton" (click)="$event.stopPropagation()">
				<div class="form-check" style="margin-left: 10px">
					<input
						id="showMinMax"
						type="checkbox"
						class="form-check-input"
						[disabled]="frequency === 0"
						[ngModel]="showMinMax"
						(change)="showMinMaxOnChart($event)" />
					<label class="form-check-label" for="showMinMax">Show min/max</label>
				</div>
			</div>
		</div>
	</div>
	<div id="messageBox">
		<i *ngIf="refreshing" class="fas fa-sync fa-spin refresh-spinner"></i>
		<span *ngIf="!refreshing && noData">{{ 'No data' | translate }}</span>
	</div>
	<div id="main" #mainChart class="chart-container"></div>
</div>
