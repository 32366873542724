import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { CM_BACKEND_API_URL } from './master-data-api.service';
import { HttpParamsBuilder } from '../../../../../../libs/core-lib/src/lib/utils/http-params-builder';
import { VersionsDto } from '../models/versions-dto.model';

@Injectable({
	providedIn: 'root',
})
export class VersionsApiService {
	private get _mainUrl(): string {
		return `${this._apiUrl}versions`;
	}

	constructor(private _httpClient: HttpClient, @Optional() @Inject(CM_BACKEND_API_URL) private _apiUrl?: string) {}

	getVersions(force: boolean = false): Observable<VersionsDto> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create().append('force', force);
		return this._httpClient.get<VersionsDto>(this._mainUrl, { params: httpParamsBuilder.build() });
	}
}
