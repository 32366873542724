import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TrendSensorForViewer } from '../../models/trend-sensor-for-viewer.model';

@Component({
	selector: 'fitech-workspace-trend-sensor',
	templateUrl: './trend-sensor.component.html',
	styleUrls: ['./trend-sensor.component.scss'],
})
export class TrendSensorComponent {
	@Input() trendSensor: TrendSensorForViewer;
	@Input() isLast: boolean;

	@Output() removed = new EventEmitter<void>();
	@Output() chartTypeToggled = new EventEmitter<void>();
	@Output() visibilityToggled = new EventEmitter<void>();

	toggleVisibility(): void {
		this.visibilityToggled.emit();
	}

	removeSensor(): void {
		this.removed.emit();
	}

	toggleChartType(): void {
		this.chartTypeToggled.emit();
	}
}
