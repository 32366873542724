import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
	selector: 'tra-quick-filter',
	templateUrl: './quick-filter.component.html',
	styleUrls: ['./quick-filter.component.scss'],
})
export class QuickFilterComponent implements OnInit, OnDestroy {
	@Input() options: QuickFilterComponentOptions;

	@Output() filterChanged = new EventEmitter<string>();

	quickFilter = '';

	private _timerQuickFilter: any;
	private _text: string;

	ngOnInit(): void {
		if (!this.options) {
			this.options = {
				showSearchButton: false,
				searchOnTextChange: true,
			};
		}
	}

	ngOnDestroy(): void {
		if (this._timerQuickFilter != null) {
			this._timerQuickFilter.clearTimeout();
		}
	}

	clearQuickFilter(): void {
		this.filterChanged.emit('');
		this.quickFilter = '';
		this._text = '';
	}

	applyQuickFilterOnClick(): void {
		this.applyQuickFilter(this._text);
	}

	onKeyDown(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			this.applyQuickFilter(this._text);
		}
	}

	applyQuickFilterOnTextChange(filterValue: string): void {
		this._text = filterValue;

		if (this.options.searchOnTextChange) {
			//delay in filtering
			if (this._timerQuickFilter != null) {
				clearTimeout(this._timerQuickFilter);
			}

			this._timerQuickFilter = setTimeout(() => {
				this.filterChanged.emit(filterValue);
				this.quickFilter = filterValue;
			}, 500);
		}
	}

	applyQuickFilter(filterValue: string): void {
		this.filterChanged.emit(filterValue);
		this.quickFilter = filterValue;
	}
}

export interface QuickFilterComponentOptions {
	showSearchButton?: boolean;
	searchOnTextChange?: boolean;
}
