import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ScreenSizeService } from '@fitech-workspace/core-lib';
import { DateRangeTypeEnum, IDateRange } from '@fitech-workspace/shared/ui/controls-lib';
import * as echarts from 'echarts';
import { LineSeriesOption } from 'echarts';
import { AsEnumerable } from 'linq-es2015';
import * as moment from 'moment';
import { interval } from 'rxjs';
import { LineChartConfigBuilder } from './../../services/line-chart-config.builder';
import { ExportedLineSeries, ExportedLineSeriesData, IChartSeriesConfig, ObjectUtils } from '../../..';
import { EnableStatesEnum, IChartConfig } from '../../models/chart-config';
import {
	ChartSeriesTypeEnum,
	IChartData,
	IChartSeriesWithData,
	IMarkAreaData,
	IMarkAreasConfig,
	IMarkAreasConfigWithData,
} from '../../models/chart-series.model';
import { IYAxisMinMax } from '../../models/chart-yaxis-minmax.model';

@Component({
	selector: 'fitech-workspace-line-chart',
	templateUrl: './line-chart.component.html',
	styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() autoRefreshIntervalSeconds = 20;

	@Output() dateRangeChanged = new EventEmitter<IDateRange>();

	@ViewChild('mainChart', { static: true }) private _chartDiv: ElementRef;

	dateRange: IDateRange = {
		from: moment().startOf('day').toDate(),
		to: moment().startOf('day').add(1, 'days').toDate(),
		type: DateRangeTypeEnum.LastDay,
	};

	frequency?: number;

	noData = true;
	autoRefresh: boolean;
	refreshing: boolean;
	showFrequency = false;
	showStartStopRefreshData = false;
	showMinMaxButton = false;
	showMinMax: boolean;
	showDateTimePicker = true;

	now: Date;

	private _chartView: echarts.ECharts; //echarts object
	private _initialOptions: IChartConfig;
	private _series: IChartSeriesWithData[] = [];
	private _markAreasConfig: IMarkAreasConfigWithData;

	private _onScreenSizeSubs: any;
	private _autoRefreshSubs: any;
	private _isZoomed = false;
	private _YAxisRange: IYAxisMinMax;

	constructor(private _screenSizeService: ScreenSizeService) {}

	ngOnInit(): void {
		this._onScreenSizeSubs = this._screenSizeService.onResize$.subscribe((x) => {
			if (this._chartView) {
				this.resize();
				this.refreshChart();
			}
		});
	}

	ngAfterViewInit(): void {
		this.resize();
	}

	ngOnDestroy(): void {
		this._onScreenSizeSubs?.unsubscribe();

		this.turnAutoRefreshOff();

		if (this._series) {
			this._series.forEach((x: IChartSeriesWithData) => {
				if (x.subscription) {
					x.subscription.unsubscribe();
				}
				x = null;
			});
		}
	}

	initialize(chartConfig: IChartConfig): void {
		this._initialOptions = chartConfig;
		this.showDateTimePicker = this._initialOptions.showDateTimePicker || true;
		this.showFrequency = this._initialOptions.showFrequency || false;
		this.showMinMaxButton =
			this._initialOptions.minMaxSeries === EnableStatesEnum.EnabledAndSetOn || this._initialOptions.minMaxSeries === EnableStatesEnum.EnabledAndSetOff;
		this.showMinMax = this._initialOptions.minMaxSeries === EnableStatesEnum.EnabledAndSetOn || false;
		this.autoRefresh = this._initialOptions.autoRefreshChartData || false;
		this.showStartStopRefreshData = this._initialOptions.showStartStopRefreshData || false;

		this._chartView = echarts.init(this._chartDiv.nativeElement);
		if (this._initialOptions.dateRange) {
			this.dateRange = this._initialOptions.dateRange;
			this.frequency = this.dateRange.frequency;
		}
		this._chartView.setOption(chartConfig.chartOptions, true);

		this._chartView.on('datazoom', () => {
			this._isZoomed = true;
			this.turnAutoRefreshOff();
		});

		this.setAutoRefreshToolboxOnChart(false);

		if (this.autoRefresh) {
			this.turnAutoRefreshOn();
		} else {
			this.turnAutoRefreshOff();
		}

		this._chartView.on('restore', () => {
			this._isZoomed = false;
			if (this._initialOptions.autoRefreshChartData) {
				this.turnAutoRefreshOn();
			}
		});
	}

	refreshAllSeriesVisibility(): void {
		this._series.forEach((series: IChartSeriesWithData) => {
			this.refreshOneSeriesVisibility(series.name, series.visible, series.id);
		});
	}

	changeSeriesVisibility(seriesName: string, visible: boolean, seriesId?: any): void {
		const chartSeries: IChartSeriesWithData = this.getSeries(seriesName, seriesId);
		if (chartSeries) {
			chartSeries.visible = visible;
		}

		this.refreshOneSeriesVisibility(seriesName, visible, seriesId);
	}

	resize(): void {
		if (this._chartView) {
			this._chartView.resize();
		}
	}

	clearData(): void {
		if (this._series.length) {
			this._series.map((series: IChartSeriesWithData) => series.subscription.unsubscribe());
			this._series = [];
		}
		if (this._markAreasConfig) {
			this._markAreasConfig.subscription.unsubscribe();
			this._markAreasConfig = null;
		}
	}

	addSeries(series: IChartSeriesConfig): void {
		const chartSeries: IChartSeriesWithData = this.getSeries(series.name, series.id);
		if (!chartSeries) {
			//DATA SERIES
			if (series.type === ChartSeriesTypeEnum.data || series.type === ChartSeriesTypeEnum.dataStep) {
				const isStep = series.type === ChartSeriesTypeEnum.dataStep;
				const chartConfig = LineChartConfigBuilder.createDataSeries(series.name, 'line', series.color, series.customConfig, isStep, series.id);
				const seriesWithData = series as IChartSeriesWithData;
				seriesWithData.chartConfig = chartConfig;
				seriesWithData.visible = true;
				this._series.push(seriesWithData);
				this.readDataForOneSeries(seriesWithData, true);
			}

			//ALARM THRESHOLD  SERIES
			if (series.type === ChartSeriesTypeEnum.alarmThresholdSolid || series.type === ChartSeriesTypeEnum.alarmThresholdDotted) {
				const dotted = series.type === ChartSeriesTypeEnum.alarmThresholdDotted;
				const chartConfig = LineChartConfigBuilder.createAlarmThresholdSeries(series.name, dotted, series.color, series.id);
				const seriesWithData = series as IChartSeriesWithData;
				seriesWithData.chartConfig = chartConfig;
				this._series.push(seriesWithData);
				this.readDataForOneSeries(seriesWithData, true);
			}

			//WARNING THRESHOLD  SERIES
			if (series.type === ChartSeriesTypeEnum.warningThresholdSolid) {
				const chartConfig = LineChartConfigBuilder.createAlarmThresholdSeries(series.name, false, series.color, series.id);
				const seriesWithData = series as IChartSeriesWithData;
				seriesWithData.chartConfig = chartConfig;
				this._series.push(seriesWithData);
				this.readDataForOneSeries(seriesWithData, true);
			}
		}
	}

	removeSeries(seriesName: string, seriesId?: any, refresh: boolean = true): void {
		const chartSeries: IChartSeriesWithData = this.getSeries(seriesName, seriesId);
		if (chartSeries) {
			chartSeries.subscription?.unsubscribe();
			const idx = this._series.indexOf(chartSeries);
			this._series.splice(idx, 1);
			refresh && this.refreshChart();
		}
		refresh && this.updateRefreshing();
	}

	updateSeriesConfig(seriesName: string, customConfig: LineSeriesOption, seriesId?: any): void {
		const chartSeries: IChartSeriesWithData = this.getSeries(seriesName, seriesId);
		if (!chartSeries) {
			return;
		}

		chartSeries.customConfig = customConfig;
		chartSeries.chartConfig = ObjectUtils.deepSpread(chartSeries.chartConfig, customConfig);
		this.refreshChart();
	}

	addMarkAreas(markAreasConfig: IMarkAreasConfig): void {
		this._markAreasConfig = markAreasConfig;
		this._markAreasConfig.chartConfigs = [];
	}

	readData(clearFirst: boolean): void {
		this.readMarkAreas();
		this.now = moment().toDate();
		this._series.forEach((series: IChartSeriesWithData) => {
			this.readDataForOneSeries(series, clearFirst);
		});
	}

	readMarkAreas(): void {
		if (!this._markAreasConfig) {
			return;
		}

		if (this._markAreasConfig.subscription && !this._markAreasConfig.subscription.closed) {
			return;
		}
		const from = this.dateRange.from;
		const to = this.dateRange.type !== 'Custom' ? moment().toDate() : this.dateRange.to;

		this._markAreasConfig.subscription = this._markAreasConfig.getChartMarkAreasFunc(from, to, null).subscribe((result: IMarkAreaData[]) => {
			this._markAreasConfig.chartConfigs = result.map((res: IMarkAreaData) =>
				LineChartConfigBuilder.createMarkArea(res.name, res.from, res.to, res.color)
			);
			this.refreshChart();
		});
	}

	refreshChart(): void {
		//serries
		let chartSeries = AsEnumerable(this._series)
			.Select((x: IChartSeriesWithData) => x.chartConfig)
			.ToArray();
		if (this._markAreasConfig) {
			const chartMarkAreas = this._markAreasConfig.chartConfigs;
			chartSeries = chartSeries.concat(chartMarkAreas);
		}

		//min/max series
		const minMaxSeries = [];
		this._series.forEach((series: IChartSeriesWithData) => {
			if (series.type === ChartSeriesTypeEnum.data && this.showMinMax) {
				const minMax = this.extractMinMaxSeries(series);
				if (minMax) {
					minMaxSeries.push(minMax.min);
					minMaxSeries.push(minMax.max);
				}
			}
		});
		chartSeries = chartSeries.concat(minMaxSeries);
		//legend - for visibility toggling
		const legend = { data: [], selected: {}, show: false };
		this._series.forEach((series: IChartSeriesWithData) => {
			const isId: boolean = typeof series.id !== 'undefined' && series.id !== null;
			const nameForLegend: string = isId ? series.id : series.name;
			legend.data.push(nameForLegend);
			if (series.visible) {
				legend.selected[nameForLegend] = series.visible;
			}
		});

		if (chartSeries.length > 0) {
			const options = this._chartView.getOption();
			options.series = chartSeries;
			options.legend = legend;
			this._chartView.setOption(options, true);
			const yAxisMinMax = AsEnumerable(this._series)
				.Where((x: IChartSeriesWithData) => x.yAxisMinMax && x.ignoreMinMax != true)
				.ToArray();
			if (yAxisMinMax.length > 0) {
				this._YAxisRange = this.findExtremeYRange(yAxisMinMax.map((y: IChartSeriesWithData) => y.yAxisMinMax));
				this.setDataRangeOnChart(this._YAxisRange);
			}

			this.refreshAllSeriesVisibility();
		} else {
			this.clearChart(false);
		}

		//move end of chart to last data when auto-refresh is on
		const lastTimestamps = AsEnumerable(this._series)
			.Where((x: IChartSeriesWithData) => !!x.lastTimestamp)
			.ToArray();
		const maxLastTimestamp =
			lastTimestamps && lastTimestamps.length > 0
				? AsEnumerable(lastTimestamps)
						.OrderBy((x: IChartSeriesWithData) => x.lastTimestamp.getMilliseconds())
						.Last().lastTimestamp
				: this.dateRange.to;
		if (this.autoRefresh && moment(maxLastTimestamp) > moment(this.dateRange.to)) {
			this.dateRange.to = moment(maxLastTimestamp).toDate();
			this.setDateRangeOnChart();
		}

		this.noData = !AsEnumerable(this._series).Any((x: IChartSeriesWithData) => x.chartConfig.data && x.chartConfig.data.length > 0);
	}

	clearChart(final: boolean): any {
		if (final) {
			this._chartView.clear();
		} else {
			const options = this._chartView.getOption();
			options.series = [];
			this._chartView.setOption(options, true);
		}
	}

	onDateRangeChanged(dateRange: IDateRange): void {
		this.dateRange = dateRange;
		this.frequency = dateRange.frequency;

		if (this.frequency === 0) {
			this.showMinMax = false;
		}

		this.setDateRangeOnChart();
		this.readData(true);

		if (dateRange.type === 'Custom') {
			this.turnAutoRefreshOff();
		}
		this.dateRangeChanged.emit(dateRange);
	}

	setDateRangeOnChart(): void {
		this._chartView.setOption({
			xAxis: {
				min: this.dateRange.from,
				max: this.dateRange.to > new Date() ? new Date() : this.dateRange.to,
			},
		});
	}

	setDataRangeOnChart(minMax: IYAxisMinMax): void {
		this._chartView.setOption({
			yAxis: {
				min: minMax.min,
				max: minMax.max,
			},
		});
	}

	turnAutoRefreshOff(): void {
		if (this._autoRefreshSubs != null) {
			this._autoRefreshSubs.unsubscribe();
			this._autoRefreshSubs = null;
		}

		this.autoRefresh = false;
		this.setAutoRefreshToolboxOnChart(true);
	}

	turnAutoRefreshOn(): void {
		const millisecondsInSecond = 1000;
		this.autoRefresh = true;
		this.setAutoRefreshToolboxOnChart(true);
		if (this._autoRefreshSubs == null) {
			this._autoRefreshSubs = interval(this.autoRefreshIntervalSeconds * millisecondsInSecond).subscribe(() => {
				this.readData(false);
			});
		}
	}

	showMinMaxOnChart(event: any): void {
		this.showMinMax = event.target.checked;
		this.refreshChart();
	}

	// add custom toolbox
	setAutoRefreshToolboxOnChart(onlyIcon: boolean): void {
		if (this.showStartStopRefreshData) {
			this._chartView.setOption({
				toolbox: this.setAutoRefreshToolboxOptions(onlyIcon),
			});
		}
	}

	getExportedSeries(): ExportedLineSeries[] {
		return this._series.map((singleSeries: IChartSeriesWithData): ExportedLineSeries => {
			return {
				id: singleSeries.id,
				name: singleSeries.name,
				units: singleSeries.units,
				data: singleSeries.chartConfig.data?.map(
					(item: any[]): ExportedLineSeriesData => ({
						timestamp: item[0],
						value: item[1],
						min: item[2],
						max: item[3],
					})
				),
			};
		});
	}

	private getSeries(seriesName: string, seriesId?: any): IChartSeriesWithData {
		const isId: boolean = typeof seriesId !== 'undefined' && seriesId !== null;
		return this._series.find((x) => (isId ? x.id === seriesId : x.name === seriesName));
	}

	private refreshOneSeriesVisibility(seriesName: string, visible: boolean, seriesId?: any): void {
		const actionName = visible ? 'legendSelect' : 'legendUnSelect';
		const isId: boolean = typeof seriesId !== 'undefined' && seriesId !== null;
		this._chartView.dispatchAction({
			type: actionName,
			name: isId ? seriesId : seriesName,
		});
	}

	private readDataForOneSeries(series: IChartSeriesWithData, clearFirst: boolean): void {
		this.updateRefreshing();

		// previous request has not ended
		if (series.subscription && !series.subscription.closed) {
			return;
		}

		if (!this.dateRange) {
			return;
		}

		//clear to read for whole selected dateRange (not cleared when using auto-refresh mechanism)
		if (clearFirst) {
			series.chartConfig.data = [];
			series.lastTimestamp = null;
		}
		const from = series.category != 'threshold' ? series.lastTimestamp || this.dateRange.from : this.dateRange.from;
		const to = this.dateRange.type !== 'Custom' && series.category != 'threshold' ? this.now : this.dateRange.to;

		//request
		series.subscription = series.getDataFunc(from, to, this.frequency).subscribe(
			(result: IChartData[]) => {
				let newData = result
					? AsEnumerable(result)
							.OrderBy((x: IChartData) => (x as any).timestamp)
							.Select((x: IChartData) => [moment(x.timestamp).local().toDate(), x.value, x.min, x.max])
							.ToArray()
					: [];

				//filter out data which already are in existing values
				if (series.chartConfig.data.length > 0) {
					// if data already loaded has same timestamp as new data. Copy value from new data to exisiting data. It is because of reading average data from backend, which in two different
					// queries may have different data (average value was recalculated when new data arrived). Ref https://jira.fideltronik.com/browse/IOTFIS-354
					newData.forEach((nd: (number | Date)[]) => {
						const exisitingDataWithSameTimestamp = AsEnumerable(series.chartConfig.data).FirstOrDefault(
							(x: any) => x[0].getTime() === (nd[0] as Date).getTime()
						);
						if (exisitingDataWithSameTimestamp) {
							exisitingDataWithSameTimestamp[1] = nd[1];
						}
					});

					const newDataTemp = AsEnumerable(newData)
						.Where(
							(x: (number | Date)[]) =>
								!AsEnumerable(series.chartConfig.data).Any((y: any) => y[0].getTime() === (x[0] as Date).getTime() && y[1] === x[1])
						)
						.ToArray();
					newData = newDataTemp;
				}

				series.chartConfig.data = series.chartConfig.data.concat(newData);

				if (!series.chartConfig.data?.length) {
					return;
				}

				//save last timestamp from all series
				const lastTimestamp = series.chartConfig.data
					? AsEnumerable(series.chartConfig.data)
							.Select((x: any) => x[0])
							.Last()
					: null;

				if ((lastTimestamp && lastTimestamp > series.lastTimestamp) || !series.lastTimestamp) {
					series.lastTimestamp = lastTimestamp;
				}

				this.refreshChart();
			},
			undefined,
			() => this.updateRefreshing()
		);
	}

	private updateRefreshing(): void {
		this.refreshing = this._series.some((s: IChartSeriesWithData) => s.subscription && !s.subscription.closed);
		//i do not why but it has to be delayed even if i am checking this on subscription complete
		this.delay(500).then(() => {
			this.refreshing = this._series.some((s: IChartSeriesWithData) => s.subscription && !s.subscription.closed);
		});
		this.delay(1500).then(() => {
			this.refreshing = this._series.some((s: IChartSeriesWithData) => s.subscription && !s.subscription.closed);
		});
	}

	// find max Y range, and min Y range in sensorsData[]
	// +curr.min its equivalent to Number(curr.min) etc. cause we got string instead number from data in constructor trend-sensor.model
	private findExtremeYRange(data: IYAxisMinMax[]): { min: number; max: number } {
		const dataWithMinMax = (): {
			min: number;
			max: number;
		} => {
			const minArr = Math.min(...data.map((x: IYAxisMinMax) => x.min));
			const maxArr = Math.max(...data.map((x: IYAxisMinMax) => x.max));
			return { min: minArr, max: maxArr };
		};
		return dataWithMinMax();
	}

	private extractMinMaxSeries(baseSeriesConfig: IChartSeriesWithData): { min: any; max: any } {
		const dataEnumerable = AsEnumerable(baseSeriesConfig.chartConfig.data);
		//there is no min/max data series
		if (dataEnumerable.All((x: any[]) => x.length < 4)) {
			return null;
		}

		const results = LineChartConfigBuilder.createDataMinMaxBands(
			baseSeriesConfig.name,
			baseSeriesConfig.color,
			baseSeriesConfig.id,
			baseSeriesConfig.customConfig
		);

		results.min.data = dataEnumerable.Select((x) => [(x as any[])[0], (x as any[])[2]]).ToArray();
		results.max.data = dataEnumerable.Select((x) => [(x as any[])[0], (x as any[])[3]]).ToArray();

		return results;
	}

	private delay(ms: number): Promise<any> {
		return new Promise((resolve: (value: any) => void) => setTimeout(resolve, ms));
	}

	private onAutoRefreshToolboxClick(autoRefresh: boolean): void {
		if (autoRefresh) {
			this.turnAutoRefreshOff();
		} else {
			this.turnAutoRefreshOn();
		}
	}

	// set custom Toolbox options
	private setAutoRefreshToolboxOptions(onlyIcon: boolean): {
		feature: {
			myAutoRefreshOnOff: object;
		};
	} {
		const toolboxOptions = {
			feature: {
				myAutoRefreshOnOff: {},
			},
		};
		if (onlyIcon) {
			toolboxOptions.feature.myAutoRefreshOnOff = {
				icon: this.autoRefresh ? 'image://assets/icons/stop-circle-regular.svg' : 'image://assets/icons/play-circle-regular.svg',
				title: this.autoRefresh ? 'Turn off\nAuto refresh' : 'Turn on\nAuto refresh',
			};
		} else {
			toolboxOptions.feature.myAutoRefreshOnOff = {
				title: 'Turn off Auto refresh',
				icon: 'image://assets/icons/stop-circle-regular.svg',
				onclick: () => this.onAutoRefreshToolboxClick(this.autoRefresh),
			};
		}
		return toolboxOptions;
	}
}
