import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { Component, ElementRef } from '@angular/core';

interface MyParams extends IHeaderParams {
	menuIcon: string;
}

@Component({
	selector: 'fitech-workspace-custom-header',
	templateUrl: './custom-header.component.html',
	styleUrls: ['./custom-header.component.scss'],
})
export class CustomHeaderComponent implements IHeaderAngularComp {
	params: MyParams;
	sorted: 'asc' | 'desc';

	value = true;
	sortable: boolean;
	isFiltered = false;
	isSortAsc = false;
	isSortDesc = false;

	private _elementRef: ElementRef;

	constructor(elementRef: ElementRef) {
		this._elementRef = elementRef;
	}

	refresh(): boolean {
		return true;
	}

	agInit(params: any): void {
		this.params = params;
		this.sortable = this.params.enableSorting;
		if (this.sortable) {
			this.params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
		}
		this.params.column.addEventListener('filterChanged', this.onFilterChange.bind(this));
		this.onSortChanged();
	}

	onMenuClick(): void {
		this.params.showColumnMenu(this.querySelector('.customHeaderMenuButton'));
	}

	clearFilter(): void {
		const filterComponent = this.params.api.getFilterInstance(this.params.column.getId());
		filterComponent.setModel(null);
		this.params.api.onFilterChanged();
	}

	onSortRequested(event: MouseEvent): void {
		if (!this.sortable) {
			return;
		}

		if (this.sorted === 'asc') {
			this.sorted = 'desc';
		} else if (this.sorted === 'desc') {
			this.sorted = null;
		} else {
			this.sorted = 'asc';
		}
		this.params.setSort(this.sorted, event.shiftKey);
	}

	onFilterChange(): void {
		this.isFiltered = this.params.column.isFilterActive();
	}

	onSortChanged(): void {
		if (this.params.column.isSortAscending()) {
			this.sorted = 'asc';
		} else if (this.params.column.isSortDescending()) {
			this.sorted = 'desc';
		} else {
			this.sorted = null;
		}
	}

	private querySelector(selector: string): HTMLElement {
		return <HTMLElement>this._elementRef.nativeElement.querySelector('.customHeaderMenuButton', selector);
	}
}
