import { AgEditorComponent } from '@ag-grid-community/angular';
import { ColDef, ICellEditorParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { NotificationService } from '@fitech-workspace/core-lib';
import { KeyboardEventKeyEnum } from '../../enums';
import { GridComponent } from '../../grid/grid.component';

@Component({
	selector: 'text-editor',
	templateUrl: './text-editor.component.html',
	styleUrls: ['../common/editor-styles.scss'],
})
export class TextEditorComponent implements AgEditorComponent {
	value: string | null;
	highlightAllOnFocus = true;

	private _cancelBeforeStart = false;
	private _initialValue: string | null;

	private _params: ICellEditorParams<any, string | null, { componentParent: GridComponent }> & {
		patternRegExp?: RegExp;
		patternFailedMessage?: string;
		unique?: boolean;
	};

	private _userColDef: ColDef<string, any>;
	private _patternRegExp: RegExp | undefined;
	private _patternFailedMessage: string | undefined;
	private _unique: boolean | undefined;

	constructor(private _notificationService: NotificationService) {}

	agInit(
		params: ICellEditorParams<any, string | null, { componentParent: GridComponent }> & {
			patternRegExp?: RegExp;
			patternFailedMessage?: string;
			unique?: boolean;
		}
	): void {
		this._params = params;
		this._patternRegExp = this._params.patternRegExp;
		this._patternFailedMessage = this._params.patternFailedMessage;
		this._unique = this._params.unique;

		this._userColDef = this._params.column.getUserProvidedColDef();
		this.setInitialState();
	}

	isPopup(): boolean {
		return this._userColDef?.cellEditorPopup ?? true;
	}

	getPopupPosition(): 'over' | 'under' {
		return this._userColDef?.cellEditorPopupPosition ?? 'over';
	}

	getValue(): string | null {
		return this.value;
	}

	isCancelBeforeStart(): boolean {
		return this._cancelBeforeStart;
	}

	isCancelAfterEnd(): boolean {
		if (this._patternRegExp) {
			const isPatternInvalid = !this._patternRegExp.test(this.value);
			if (isPatternInvalid) {
				this._notificationService.showError(this._patternFailedMessage ?? 'Invalid value. Changes will not be saved.');
				return isPatternInvalid;
			}
		}

		if (this._unique && this.value !== this._initialValue) {
			const columnData: any[] = this._params.context?.componentParent?.getColumnData(this._userColDef.field) ?? [];
			const isUniqueInvalid = columnData.includes(this.value);
			if (isUniqueInvalid) {
				this._notificationService.showError('Value must be unique.');
				return isUniqueInvalid;
			}
		}

		return false;
	}

	onKeyDown(event: KeyboardEvent): void {
		if (this.isLeftOrRight(event) || this.isDeleteOrBackspace(event)) {
			event.stopPropagation();
			return;
		}
		this._params.onKeyDown(event);
	}

	private setInitialState(): void {
		let startValue: string | null;
		let highlightAllOnFocus = true;
		const isValidKeyPressed = this._params.eventKey?.length === 1;

		if (this._params.eventKey === KeyboardEventKeyEnum.Backspace || this._params.eventKey === KeyboardEventKeyEnum.Delete) {
			startValue = null;
		} else if (isValidKeyPressed) {
			startValue = this._params.eventKey;
			highlightAllOnFocus = false;
		} else {
			startValue = this._params.value;
			highlightAllOnFocus = this._params.eventKey !== KeyboardEventKeyEnum.F2;
		}

		this.value = startValue;
		this.highlightAllOnFocus = highlightAllOnFocus;
		this._cancelBeforeStart = false;
		this._initialValue = this._params.value;
	}

	private isDeleteOrBackspace(event: KeyboardEvent): boolean {
		const keys: string[] = [KeyboardEventKeyEnum.Delete, KeyboardEventKeyEnum.Backspace];
		return keys.includes(event.key);
	}

	private isLeftOrRight(event: KeyboardEvent): boolean {
		const keys: string[] = [KeyboardEventKeyEnum.Left, KeyboardEventKeyEnum.Right];
		return keys.includes(event.key);
	}
}
