import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MonoTypeOperatorFunction, Observable, iif, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen, switchMap, tap } from 'rxjs/operators';
import { Globals } from '../../../../../apps/machines-app/src/app/globals';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {
	constructor(private _router: Router, private _notificationService: NotificationService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		Globals.internetConnectionError = false;
		const retryOptions: RetryOptions = this.getRetryOptions(request);
		return next.handle(request).pipe(this.retryPipe(retryOptions));
	}

	private retryPipe<T>(retryOptions: RetryOptions): MonoTypeOperatorFunction<T> {
		return retryWhen<T>((errors: Observable<HttpErrorResponse>) =>
			errors.pipe(
				// Concat map to keep the errors in order and make sure they aren't executed in parallel
				concatMap((error: HttpErrorResponse, retryId: number) =>
					// Executes a conditional Observable depending on the result of the first argument
					iif(
						() => this.shouldNotRetry(retryId, retryOptions, error),
						// If the condition is true we throw the error (the last error)
						this.returnError(error),
						// Otherwise we pipe this back into our stream and delay the retry
						this.retryError(error, retryId, retryOptions.retryDelay)
					)
				)
			)
		);
	}

	private shouldNotRetry(retryId: number, retryOptions: RetryOptions, error: HttpErrorResponse): boolean {
		return (
			retryId >= retryOptions.maximumRetries ||
			error.status === 401 ||
			error.status === 403 ||
			(error.status >= 200 && error.status < 300) ||
			(error.status === 500 && typeof error.error === 'string' && error.error.includes('Execution Timeout Expired'))
		);
	}

	private returnError(error: HttpErrorResponse): Observable<never> {
		return of(error).pipe(
			tap((error: HttpErrorResponse) => {
				if (!navigator.onLine || error.statusText === 'Unknown Error') {
					this._notificationService.showError('No Internet Connection', true);
					Globals.internetConnectionError = true;
				}

				if (error.status === 401) {
					this._notificationService.showError('Session expired. Please login again.', true);
					this._router.navigateByUrl('/login');
				}
			}),
			switchMap(() => throwError(error))
		);
	}

	private retryError(error: HttpErrorResponse, retryId: number, retryDelay: number): Observable<HttpErrorResponse> {
		return of(error).pipe(delay(retryDelay));
	}

	private getRetryOptions(request: HttpRequest<any>): RetryOptions {
		// user validation request (ends with openid-configuration) always runs first
		// and if azure have some delay (webapp cold start) we need to wait longer and retry 3x
		if (request.url.endsWith('openid-configuration')) {
			return {
				maximumRetries: 3,
				retryDelay: 5000,
			};
		}

		// GET method retried once
		// POST/PUT/DELTE not retried
		return {
			maximumRetries: request.method === 'GET' ? 1 : 0,
			retryDelay: 700,
		};
	}
}

interface RetryOptions {
	maximumRetries: number;
	retryDelay: number;
}
