import { DateUtils } from '@fitech-workspace/core-lib';
import { LineSeriesOption } from 'echarts';
import * as moment from 'moment';
import { ChartConfigBuilder, IChartConfigBuilder } from './chart-config.builder';

export class LineChartConfigBuilder implements IChartConfigBuilder {
	private _chartConfig = new ChartConfigBuilder();
	private _chartOptions = this._chartConfig.chartOptions;

	setGrid = this._chartConfig.setGrid;
	setCustom = this._chartConfig.setCustom;
	setXAxisTime = this._chartConfig.setXAxisTime;
	setYAxis = this._chartConfig.setYAxis;
	setTooltip = this._chartConfig.setTooltip;
	addToolbox = this._chartConfig.addToolbox;
	addExportCsvToolbox = this._chartConfig.addExportCsvToolbox;
	build = this._chartConfig.build;

	setDataZoomOnMouseWheel(zoomOnX = true): LineChartConfigBuilder {
		if (!this._chartOptions.dataZoom) {
			this._chartOptions.dataZoom = [];
		}

		if (zoomOnX) {
			this._chartOptions.dataZoom.push({
				type: 'inside',
				id: 'insideX',
				xAxisIndex: 0,
				filterMode: 'none',
				start: 0,
				end: 100,
				zoomOnMouseWheel: true,
				moveOnMouseMove: true,
			});
		}
		return this;
	}

	setDefault(): LineChartConfigBuilder {
		this.setXAxisTime(true, {
			boundaryGap: 'false',
			splitLine: {
				show: true,
				lineStyle: {
					color: ['whitesmoke'],
				},
			},
		})
			.setYAxis('left', 'value', {
				boundaryGap: [0, '100%'],
				splitLine: {
					show: true,
					lineStyle: {
						color: ['whitesmoke'],
					},
				},
			})
			.setGrid()
			.setTooltip(this.lineChartTooltipFormatter)
			.addToolbox(true, true);
		this.setDataZoomOnMouseWheel();
		return this;
	}

	setExportCsv(exportFunc: () => void): LineChartConfigBuilder {
		this.addExportCsvToolbox(exportFunc);
		return this;
	}

	setTooltipById(): LineChartConfigBuilder {
		this._chartConfig.setTooltip(this.lineChartTooltipFormatterById);
		return this;
	}

	static createDataSeries(name: string, type: string, color?: string, rest?: LineSeriesOption, isStepLine?: boolean, id?: any) {
		return ChartConfigBuilder.createDataSeries(name, type, color, rest, isStepLine, id);
	}

	static createDataMinMaxBands(name: string, color: string, id: any, rest?: LineSeriesOption) {
		const minEmphasisColor = '#F50202';
		const maxEmphasisColor = '#23C552';
		rest ??= {};
		const combinedMinOption: LineSeriesOption = {
			...rest,
			lineStyle: {
				...(rest.lineStyle || {}),
				opacity: 0.4,
			},
			itemStyle: {
				...(rest.itemStyle || {}),
				opacity: 0.4,
			},
			emphasis: {
				itemStyle: {
					...(rest.emphasis?.itemStyle || {}),
					color: minEmphasisColor,
					borderColor: minEmphasisColor,
				},
			},
		};

		const combinedMaxOption: LineSeriesOption = {
			...combinedMinOption,
			lineStyle: {
				...rest.lineStyle,
				opacity: 0.5,
			},
			itemStyle: {
				...rest.itemStyle,
				opacity: 0.5,
			},
			emphasis: {
				itemStyle: {
					...combinedMinOption.emphasis.lineStyle,
					color: maxEmphasisColor,
					borderColor: maxEmphasisColor,
				},
			},
		};
		return {
			min: ChartConfigBuilder.createDataSeries(`${name} min`, 'line', color, combinedMinOption, null, id),
			max: ChartConfigBuilder.createDataSeries(`${name} max`, 'line', color, combinedMaxOption, null, id),
		};
	}

	static createAlarmThresholdSeries(name: string, dotted: boolean, color?: string, id?: any) {
		return {
			id,
			type: 'line',
			smooth: false,
			symbol: 'none',
			lineStyle: {
				width: 1,
				color: color || '#dc3545',
				type: dotted ? 'dotted' : 'solid',
				opacity: dotted ? 0.4 : 0.8,
			},
			tooltip: {
				show: false,
			},
			step: 'end',
			data: [],
		};
	}

	static createMarkArea(name: string, from: Date, to: Date, color?: string) {
		return {
			name: name,
			type: 'line',
			silent: true,
			markArea: {
				itemStyle: {
					color: color || 'rgba(220, 53, 70, 0.906)',
					opacity: 0.3,
				},
				data: [[{ xAxis: from }, { xAxis: to }]],
			},
		};
	}

	private lineChartTooltipFormatterById(params): string {
		let tooltip = '';
		let i = 0;
		if (params) {
			params.forEach((param) => {
				if (i === 0) {
					//add date only once
					const { dateOnly, timeOnly } = DateUtils.getSeparateDateAndTime(param.data[0]);
					tooltip += `<div class="chart-tooltip"><strong>${dateOnly} <strong>${timeOnly}</strong></div>`;
				}

				tooltip += `<div><span class="chart-tooltip">${ChartConfigBuilder.extractNameFromId(param.seriesId)} :</span> ${param.data[1]} </div>`;

				i++;
			});
		}

		return tooltip;
	}

	private lineChartTooltipFormatter(params): string {
		let tooltip = '';
		let i = 0;
		if (params) {
			params.forEach((param) => {
				if (i === 0) {
					//add date only once
					const { dateOnly, timeOnly } = DateUtils.getSeparateDateAndTime(param.data[0]);
					tooltip += `<div class="chart-tooltip"> ${dateOnly} <strong>${timeOnly}</strong></div>`;
				}
				tooltip += '<div><span class="chart-tooltip">' + param.seriesName + ':</span> ' + param.data[1] + '</div>';

				i++;
			});
		}

		return tooltip;
	}
}
