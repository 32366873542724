// tslint:disable:max-line-length
import { SCREEN_SIZE } from '@fitech-workspace/core-lib';
import * as moment from 'moment';

export function getAlarmsColumnDefs(screenSize?: SCREEN_SIZE) {
	if (screenSize == null) {
		screenSize = SCREEN_SIZE.LG;
	}

	if (screenSize < SCREEN_SIZE.LG) {
		return getForNarrowScreens(commonFunctions);
	} else {
		return getForWideScreens(commonFunctions);
	}
}

function getForWideScreens({ getQuickFilterText, cellRenderer, cellStyle, filterValueGetter, valueGetter }) {
	return [
		{ headerName: '', width: 20 },
		{
			headerName: '!',
			field: 'to',
			role: 'alarmIcon',
			width: 20,
			cellRenderer,
			getQuickFilterText,
		},
		{
			headerName: 'Sensor',
			field: 'sensorName',
			role: 'name',
			width: 120,
			cellRenderer,
			cellStyle,
			filterValueGetter,
		},
		{ headerName: 'Type', field: 'type', role: 'type', width: 50 },
		{
			headerName: 'Start',
			field: 'from',
			suppressSizeToFit: true,
			role: 'start',
			width: 90,
			valueGetter,
			getQuickFilterText,
		},
		{
			headerName: 'End',
			field: 'to',
			role: 'end',
			suppressSizeToFit: true,
			width: 90,
			hide: true,
			valueGetter,
			getQuickFilterText,
		},
		{
			headerName: 'Time',
			field: 'from',
			role: 'time',
			suppressSizeToFit: true,
			width: 70,
			valueGetter,
		},
		{
			headerName: 'Max/min',
			field: 'extremeValue',
			role: 'maxMin',
			width: 60,
			valueGetter,
			getQuickFilterText,
		},
		{
			headerName: '',
			width: 20,
			role: 'chart',
			cellRenderer,
		},
		{
			headerName: 'guid',
			field: 'guid',
			colId: 'guid',
			role: 'guid',
			hide: true,
			width: 10,
			getQuickFilterText,
		},
		{
			headerName: '',
			width: 10,
		},
	];
}

function getForNarrowScreens({ getQuickFilterText, cellRenderer, cellStyle, filterValueGetter }) {
	return [
		{ headerName: '', width: 5 },
		{
			headerName: 'Sensor',
			field: 'sensorName',
			role: 'name',
			narrow: true,
			width: 95,
			cellStyle,
			cellRenderer,
			filterValueGetter,
		},
		{
			headerName: 'Type',
			field: 'type',
			role: 'type',
			narrow: true,
			width: 60,
			cellRenderer,
			cellStyle,
		},
		{
			headerName: 'Start',
			field: 'from',
			role: 'start',
			narrow: true,
			suppressSizeToFit: true,
			width: 90,
			cellRenderer,
			cellStyle,
			getQuickFilterText,
		},
		{
			headerName: '',
			width: 20,
			role: 'chart',
			narrow: true,
			cellRenderer,
		},
		{
			headerName: 'guid',
			field: 'guid',
			colId: 'guid',
			role: 'guid',
			narrow: true,
			hide: true,
			width: 10,
			getQuickFilterText,
		},
		{ headerName: '', width: 10 },
	];
}

export function getRowColorByDate(params: any): { color: string; background?: string } {
	const alarmColor: string | null = !params.data.to ? params.data.getAlarmColor() : null;
	if (alarmColor) {
		return { background: alarmColor, color: '#ffffff' };
	}
}

const commonFunctions = {
	getQuickFilterText: () => '',
	cellRenderer: (params) => {
		const role = params.colDef.role;
		const narrow = params.colDef.narrow;
		if (role === 'alarmIcon') {
			if (!params.value) {
				return `<i class="far fa-bell"></i>`;
			}
		}
		if (role === 'name') {
			('');
			return params.data.sensorMachineId
				? `<div>${params.data.sensorName}</div>`
				: params.data.sensorName.toString()
				? `<div>${params.data.sensorName}</div>`
				: params.data.sensorName.toString();
		}
		if (role === 'type') {
			if (narrow) {
				('');
				return `<div style="margin-top:-5px; font-size: 0.95em;">${
					params.data.type
				}</div><div style="margin-top:-15px; font-size: 0.6rem; color:#BEBEBE">${params.data.extremeValue + ' ' + params.data.sensorUnits}</div>`;
			}
			return;
		}
		if (role === 'start') {
			if (narrow) {
				('');
				return `<div style="margin-top:-5px;">${moment(params.data.from)
					.local()
					.format('MM-DD HH:mm:ss')}</div><div style="margin-top:-15px; font-size: 0.6rem; color:#BEBEBE">${
					params.data.to
						? moment(params.data.to).diff(params.data.from, 'minutes') + ' min'
						: moment(moment().utc()).diff(params.data.from, 'minutes') + ' min'
				}</div>`;
			}
			return;
		}
		if (role === 'chart') {
			return `<i class="fas fa-chart-area grid-action-element chart-alarm ${params.data.addedToReport ? 'disabled' : ''}"></i>`;
		}
	},
	cellStyle: (params) => {
		const role = params.colDef.role;
		const narrow = params.colDef.narrow;
		if (role === 'name') {
			if (narrow) {
				return { 'font-size': '0.7rem' };
			}
			return params.data.sensorMachineId ? { 'font-size': '0.7rem' } : params.data.sensorName.toString();
		}
		if ((role === 'type' || role === 'start') && narrow) {
			return { 'font-size': '0.7rem' };
		}
	},
	filterValueGetter: (params) => {
		const role = params.colDef.role;
		const narrow = params.colDef.narrow;
		if (role === 'name') {
			return `${params.data.sensorName.replace(' ', '.')}`;
		}
	},
	valueGetter: (params) => {
		const role = params.colDef.role;
		const narrow = params.colDef.narrow;
		if (role === 'type') {
			return moment(params.data.from).local().format('MM-DD HH:mm:ss');
		}
		if (role === 'start') {
			return moment(params.data.from).local().format('MM-DD HH:mm:ss');
		}
		if (role === 'end') {
			return params.data.to ? moment(params.data.to).local().format('MM-DD HH:mm:ss') : '-';
		}
		if (role === 'time') {
			return params.data.to
				? moment(params.data.to).diff(params.data.from, 'minutes') + ' min'
				: moment(moment().utc()).diff(params.data.from, 'minutes') + ' min';
		}
		if (role === 'maxMin') {
			if (params.data.source != null) {
				return params.data.extremeValue + ' ' + params.data.sensorUnits;
			} else {
				return params.data.extremeValue;
			}
		}
	},
};
