import * as moment from 'moment';

export class DateUtils {
	static readonly InvariantDateOnlyFormat = 'YYYY-MM-DD';
	static readonly InvariantDateFormat = 'YYYY-MM-DD HH:mm:ss';
	static readonly FullTimeFormat = 'HH:mm:ss';

	static formatInvariant(date: Date | string, dateOnly: boolean = false): string {
		return date ? moment(date).format(dateOnly ? this.InvariantDateOnlyFormat : this.InvariantDateFormat) : '';
	}

	static formatInvariantDateRange(from: Date | string, to: Date | string, dateOnly: boolean = false): string {
		return from && to ? `${this.formatInvariant(from, dateOnly)} - ${this.formatInvariant(to, dateOnly)}` : '';
	}

	static now(): string {
		return moment().format(this.InvariantDateFormat);
	}

	static getDiffDatesInMinutes(dateOne: Date | moment.Moment | string, dateTwo: Date | moment.Moment | string): number {
		return moment(dateOne).diff(dateTwo) / 60000;
	}

	static getCurrentFullTime(): string {
		return moment().format(this.FullTimeFormat);
	}

	static today(): string {
		return moment().format(this.InvariantDateOnlyFormat);
	}

	static getSeparateDateAndTime(date: Date | moment.Moment | string): { dateOnly: string; timeOnly: string } {
		const momentDate = moment(date).local();
		return {
			dateOnly: momentDate.format(this.InvariantDateOnlyFormat),
			timeOnly: momentDate.format(this.FullTimeFormat),
		};
	}
}
