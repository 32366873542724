import { Observable, Subscriber } from 'rxjs';

export class ArrayBufferFileReader {
	Read(evt: any): Observable<string> {
		return Observable.create((observer: Subscriber<string[][]>): void => {
			try {
				const target: DataTransfer = <DataTransfer>evt.target;
				if (target.files.length !== 1) {
					throw new Error('Cannot use multiple files');
				}

				const reader: FileReader = new FileReader();
				reader.readAsArrayBuffer(target.files[0]);

				reader.onload = (e: any) => {
					const data = e.target.result;
					// on success
					observer.next(data);
					observer.complete();
				};
				// if failed
				reader.onerror = (error: any): void => {
					observer.error(error);
				};
			} catch (error) {
				throw new Error('Error reading text file. ' + error);
			}
		});
	}
}
