import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User, UserService } from '@fitech-workspace/auth-lib';
import { CmUser } from '../cm-user.model';

@Injectable({
	providedIn: 'root',
})
export class CmUserService {
	get currentUser$(): Observable<CmUser> {
		return this._userService.currentUser$.pipe(map((user: User) => new CmUser(user)));
	}

	constructor(private _userService: UserService) {}
}
