<div id="bars" class="box">
	<div class="box-child box-child-1" id="auth-box"></div>
	<div class="box-child box-child-2">
		<form [formGroup]="resetPasswordForm" class="form-container" (ngSubmit)="resetPassword()">
			<div>
				<h4>Reset password</h4>
			</div>
			<div>
				<mat-form-field>
					<mat-label>{{ 'Current password' | translate }}</mat-label>
					<div class="pass-box">
						<input formControlName="currentPassword" matInput type="password" (keydown)="keyDownFunction($event)" password />
					</div>
				</mat-form-field>
				<div class="errorMessage" *ngIf="getErrorMsg('currentPassword').length > 0">
					{{ getErrorMsg('currentPassword') | translate }}
				</div>
			</div>
			<div>
				<mat-form-field>
					<mat-label>{{ 'New password' | translate }}</mat-label>

					<div class="pass-box">
						<input formControlName="newPassword" matInput type="password" (keydown)="keyDownFunction($event)" password />
					</div>
				</mat-form-field>
				<div class="errorMessage" *ngIf="getErrorMsg('newPassword').length > 0">
					{{ getErrorMsg('newPassword') | translate }}
				</div>
			</div>
			<div>
				<mat-form-field>
					<mat-label>{{ 'Confirm password' | translate }}</mat-label>

					<div class="pass-box">
						<input formControlName="confirmPassword" matInput type="password" (keydown)="keyDownFunction($event)" password />
					</div>
				</mat-form-field>
				<div class="errorMessage" *ngIf="getErrorMsg('confirmPassword').length > 0">
					{{ getErrorMsg('confirmPassword') | translate }}
				</div>
			</div>
			<button type="submit" class="btn btn--mt-16 btn--primary btn--primary-wider" [disabled]="shouldDisableAction()">
				<i *ngIf="isSubmitting" class="fas fa-sync fa-spin refresh-spinner icon icon--mr-4"></i>
				{{ 'Reset password' | translate }}
			</button>
		</form>
	</div>
</div>
