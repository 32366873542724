import { User } from '@fitech-workspace/auth-lib';
import { RolePermissionFrontendEnum, RolePermissionFrontendModuleEnum } from './enums';

export class CmUser extends User {
	get isMonitoring(): boolean {
		return this.hasModulePermission(RolePermissionFrontendModuleEnum.Monitoring);
	}

	get isMachinesAlarms(): boolean {
		return this.hasPermission(RolePermissionFrontendEnum.MachinesAlarms);
	}

	get isManagement(): boolean {
		return this.hasModulePermission(RolePermissionFrontendModuleEnum.Management);
	}

	get isReports(): boolean {
		return this.hasModulePermission(RolePermissionFrontendModuleEnum.Reports);
	}

	get isRecipes(): boolean {
		return this.hasModulePermission(RolePermissionFrontendModuleEnum.Recipes);
	}

	get isUpdatesManagement(): boolean {
		return this.hasModulePermission(RolePermissionFrontendModuleEnum.Updates);
	}

	get isComponentsManagement(): boolean {
		return this.hasModulePermission(RolePermissionFrontendModuleEnum.Components);
	}

	get isDashboard(): boolean {
		return this.hasPermission(RolePermissionFrontendEnum.Dashboard);
	}

	constructor(data?: User) {
		super();
		if (data) {
			for (const property in data) {
				if (data.hasOwnProperty(property)) {
					(<any>this)[property] = (<any>data)[property];
				}
			}
		}
	}
}
