import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { State } from '../../models/state.model';

@Component({
	selector: 'fitech-workspace-hmi-indicator',
	templateUrl: './hmi-indicator.component.html',
	styleUrls: ['./hmi-indicator.component.scss'],
})
export class HmiIndicatorComponent implements OnChanges {
	@Input() state: State;
	@Input() showButtons = true;
	@Input() showRawValue = false;
	@Input() isAddedToReport: boolean;
	@Input() placeholder: string;

	@Output() chartToggled: EventEmitter<State> = new EventEmitter();

	title: string;
	subtitle: string;
	isPlaceholder: boolean;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.state) {
			this.title = this.state?.source?.name;
			this.subtitle = this.state?.timestampFromNow;
			this.isPlaceholder = !!this.placeholder && !(!!this.title || !!this.subtitle);
		}
	}

	toggleChart(): void {
		this.chartToggled.emit(this.state);
	}
}
