import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '@fitech-workspace/core-lib';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IGeneralDialogOptions } from '../../models/general-dialog-options.model';

@Component({
	selector: 'fitech-workspace-general-dialog',
	templateUrl: './general-dialog.component.html',
	styleUrls: ['./general-dialog.component.scss'],
})
export class GeneralDialogComponent {
	@Input() headerLabel: string;

	isSubmitting: boolean;
	isSubmitted: boolean;
	isGroupEdit: boolean;

	questions: any[];
	onBeforeSave: (data?: any) => Observable<any>;
	onSave: (data: any) => Observable<any>;
	showUndoButton: boolean;
	contentMaxHeight: string;
	dynamicFormMaxHeight: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<GeneralDialogComponent>,
		private _notificationService: NotificationService
	) {}

	initialize(options: IGeneralDialogOptions): void {
		this.headerLabel = options.headerLabel;
		this.questions = options.questions;
		this.onBeforeSave = options.onBeforeSave || ((data: any): Observable<any> => of(data));
		this.onSave = options.onSave;
		this.showUndoButton = options.showUndoButton || true;
		this.isGroupEdit = options.isGroupEdit || false;
		this.dialogRef.disableClose = true;
		this.contentMaxHeight = options.contentMaxHeight;
	}

	onCancel(): void {
		this.dialogRef.close({ result: false });
	}

	onConfirm(data: any): void {
		try {
			this.isSubmitting = true;
			this.isSubmitted = false;

			this.onBeforeSave(data)
				.pipe(switchMap((data: any) => this.onSave(data)))
				.subscribe(
					() => {
						this.isSubmitting = false;
						this.isSubmitted = true;
						this.dialogRef.close(true);
					},
					() => {
						this.isSubmitting = false;
					}
				);
		} catch (error) {
			this._notificationService.showError('Error occured.');
			this.isSubmitting = false;
		}
	}
}
