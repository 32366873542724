import { Action, ActionReducer } from '@ngrx/store';
import { loggedOutSuccess } from '../actions/user.actions';
import { AppState } from '../models';
import { userSettingsInitialState } from '../reducers/user-settings.reducer';

export function resetAppStateMetaReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
	return (state: AppState, action: Action): AppState => {
		if (action.type === loggedOutSuccess.type) {
			state = {
				...state,
				userSettingsState: userSettingsInitialState,
			};
		}
		return reducer(state, action);
	};
}
