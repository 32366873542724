import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ISimpleConfirmationDialogOptions } from '../../models/simple-confirmation-dialog-options.model';

@Component({
	selector: 'fitech-workspace-simple-confirmation-dialog',
	templateUrl: './simple-confirmation-dialog.component.html',
	styleUrls: ['./simple-confirmation-dialog.component.scss'],
})
export class SimpleConfirmationDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<SimpleConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: Partial<ISimpleConfirmationDialogOptions>
	) {}

	onConfirm(): void {
		this.dialogRef.close(true);
	}

	onCancel(): void {
		this.dialogRef.close(false);
	}
}
