import { ICellRendererParams, ValueGetterParams } from '@ag-grid-community/core';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { UserDto } from '@fitech-workspace/auth-lib';
import { HttpParamsBuilder } from '@fitech-workspace/core-lib';
import {
	ConfirmUserEmailCmd,
	CreateUserCmd,
	EditUserCmd,
	EntityCommandResult,
	ForgotPasswordCmd,
	ResetPasswordCmd,
	SetPasswordCmd,
} from '@fitech-workspace/master-data-lib';
import { API_BASE_URL } from '@fitech-workspace/shared/data-access/master-data-api-lib';
import { CellRendererBuilder, GridCellRendererEnum, GridColumn } from '@fitech-workspace/shared/ui/grid-lib';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoleDto } from '../../master-roles/models';
import { MasterUserData } from '../models/master-user.model';

@Injectable({
	providedIn: 'root',
})
export class MasterUsersService {
	private _apiUrl: string;

	private get _usersUrl(): string {
		return `${this._apiUrl}/api/users`;
	}

	private get _rolesUrl(): string {
		return `${this._apiUrl}/api/roles`;
	}

	constructor(private _httpClient: HttpClient, @Optional() @Inject(API_BASE_URL) apiUrl?: string) {
		this._apiUrl = apiUrl ? apiUrl : '';
	}

	getUsers(clientIds?: number[], email?: string, allowParentData?: boolean): Observable<MasterUserData[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create()
			.appendArray('clientIds', clientIds)
			.append('email', email)
			.append('allowParentData', allowParentData);

		return this._httpClient.get<UserDto[]>(this._usersUrl, { params: httpParamsBuilder.build() }).pipe(
			map((res: UserDto[]) => {
				const users: MasterUserData[] = [];
				res.forEach((user: UserDto) => {
					// hide default roles
					const modifiedUser: UserDto = {
						...user,
						roles: user.roles?.filter((role: RoleDto) => !role.isDefault),
					};
					users.push(new MasterUserData(modifiedUser));
				});
				return users;
			})
		);
	}

	getUserByEmail(email: string): Observable<MasterUserData> {
		return this.getUsers(null, email).pipe(
			map((results: MasterUserData[]): MasterUserData => {
				return results?.length === 1 ? results[0] : null;
			})
		);
	}

	getUserRoles(skipDefault: boolean = true): Observable<RoleDto[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create().append('skipDefault', skipDefault, false);
		return this._httpClient.get<RoleDto[]>(this._rolesUrl, { params: httpParamsBuilder.build() });
	}

	getDefaultColumns(): GridColumn[] {
		return [
			// tslint:disable:max-line-length
			{ field: 'user.id', headerName: 'id', filter: 'agTextColumnFilter', maxWidth: 50, hide: true },
			{
				field: 'user.email',
				headerName: 'email',
				filter: 'agTextColumnFilter',
				pinned: true,
				minWidth: 250,
				maxWidth: 350,
				headerCheckboxSelection: true,
				checkboxSelection: true,
				headerCheckboxSelectionFilteredOnly: true,
			},
			{ field: 'user.fullName', headerName: 'Name', filter: 'agTextColumnFilter', minWidth: 220, maxWidth: 220 },
			{
				field: 'user.emailConfirmed',
				headerName: 'emailConfirmed',
				filter: 'agTextColumnFilter',
				minWidth: 220,
				maxWidth: 230,
				cellRenderer: (params: ICellRendererParams): string => CellRendererBuilder.getIconCellRenderer(params.value),
			},
			{
				field: 'userAllowedClients',
				filter: 'agTextColumnFilter',
				headerName: 'allowedClients',
				cellRenderer: GridCellRendererEnum.Chips,
				valueGetter: (params: ValueGetterParams): string =>
					params.data.user.userAllowedClients.map((item: any) => {
						return item.clientName;
					}),
			},
			{
				field: 'roles',
				filter: 'agTextColumnFilter',
				headerName: 'roles',
				cellRenderer: GridCellRendererEnum.Chips,
				valueGetter: (params: ValueGetterParams): string =>
					params.data.user.roles.map((item: any) => {
						return item.name;
					}),
			},
			{
				field: 'user.lockoutEndDateUtc',
				headerName: 'lockoutEndDateUtc',
				cellRenderer: (params: ICellRendererParams): string =>
					`<i class="fas fa-lock" style="visibility: ${params.data.user.lockoutEndDateUtc ? 'visible' : 'hidden'};"></i> ${
						params.data.lockoutEndDateUtc != null && moment(params.data.lockoutEndDateUtc) < moment(new Date(9999, 1, 1, 1, 1, 1))
							? moment(params.data.lockoutEndDateUtc).local().format('YYYY-MM-DD HH:mm:ss')
							: ''
					}`,
			},
			{
				field: 'user.clientName',
				headerName: 'clientName',
				filter: 'agTextColumnFilter',
				minWidth: 150,
				maxWidth: 300,
				cellRenderer: GridCellRendererEnum.SimpleAvatar,
			},
			{ field: 'user.clientId', headerName: 'clientId', filter: 'agTextColumnFilter', hide: true },
		];
	}

	editUser(userId: number, editCUserReq: EditUserCmd): Observable<EntityCommandResult> {
		return this._httpClient.put<EntityCommandResult>(`${this._usersUrl}/${userId}`, editCUserReq);
	}

	createUser(createUserReq: CreateUserCmd): Observable<EntityCommandResult> {
		return this._httpClient.post<EntityCommandResult>(this._usersUrl, createUserReq);
	}

	confirmUserEmail(confirmUserEmailReq?: ConfirmUserEmailCmd): Observable<void> {
		return this._httpClient.post<void>(`${this._usersUrl}/confirm-email`, confirmUserEmailReq);
	}

	deleteUser(userId: number): Observable<void> {
		return this._httpClient.delete<void>(`${this._usersUrl}/${userId}`);
	}

	getSelfUser(): Observable<UserDto> {
		return this._httpClient.get<UserDto>(`${this._usersUrl}/self`);
	}

	resetPassword(resetPasswordCmd: ResetPasswordCmd): Observable<void> {
		return this._httpClient.post<void>(`${this._usersUrl}/reset-password`, resetPasswordCmd);
	}

	setPassword(setPasswordCmd: SetPasswordCmd): Observable<void> {
		return this._httpClient.post<void>(`${this._usersUrl}/set-password`, setPasswordCmd);
	}

	forgotPassword(forgotPasswordCmd: ForgotPasswordCmd): Observable<void> {
		return this._httpClient.post<void>(`${this._usersUrl}/forgot-password`, forgotPasswordCmd);
	}
}
