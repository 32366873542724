import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DateTimeRangePickerComponent } from './components/date-time-range-picker/date-time-range-picker.component';
import { DateTimeRangePickerV2Component } from './components/date-time-range-picker-v2/date-time-range-picker-v2.component';
import { NoInternetIconComponent } from './components/no-internet-icon/no-internet-icon.component';

@NgModule({
	declarations: [DateRangePickerComponent, DateTimeRangePickerComponent, DateTimeRangePickerV2Component, NoInternetIconComponent],
	imports: [CommonModule, TranslateModule],
	exports: [DateRangePickerComponent, DateTimeRangePickerComponent, NoInternetIconComponent, DateTimeRangePickerV2Component],
})
export class SharedUiInputsModule {}
