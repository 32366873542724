import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { AsEnumerable } from 'linq-es2015';

@Component({
	selector: 'fitech-workspace-chips-renderer',
	templateUrl: './chips-renderer.component.html',
	styleUrls: ['./chips-renderer.component.scss'],
})
export class ChipsRendererComponent implements ICellRendererAngularComp {
	params: any;
	data: IChipsData[] = [];

	refresh(params: any): boolean {
		return true;
	}

	agInit(params: any): void {
		let data: any[] = [];

		if (typeof params.data[params.colDef.field] === 'string') {
			data = this.splitStringToChipsData(params);
		} else {
			if (params.data.getChips) {
				data = params.data.getChips(params.colDef.field, params.cssClass);
			}
		}

		if (params.sortBy) {
			data = AsEnumerable(data)
				.OrderBy((x: any) => x[params.sortBy])
				.ToArray();
			if (params.sortBy && params.sortThanBy) {
				data = AsEnumerable(data)
					.OrderBy((x: any) => x[params.sortBy])
					.ThenBy((x: any) => x[params.sortThanBy])
					.ToArray();
			}
		}
		if (params.sortByDesc) {
			data = AsEnumerable(data)
				.OrderByDescending((x: any) => x[params.sortByDesc])
				.ToArray();
			if (params.sortByDesc && params.sortThanBy) {
				data = AsEnumerable(data)
					.OrderByDescending((x: any) => x[params.sortByDesc])
					.ThenBy((x: any) => x[params.sortThanBy])
					.ToArray();
			}
			if (params.sortByDesc && params.sortThanByDesc) {
				data = AsEnumerable(data)
					.OrderByDescending((x: any) => x[params.sortByDesc])
					.ThenByDescending((x: any) => x[params.sortThanByDesc])
					.ToArray();
			}
		}

		this.data = data;
	}

	afterGuiAttached?(params?: any): void {
		this.params = params;
	}

	private splitStringToChipsData(params: any): any[] {
		const data = [];
		if (params.data[params.colDef.field] === '') {
			return [];
		}

		params.data[params.colDef.field].split(',').forEach((x: any) => {
			data.push({
				cssClass: null,
				value: x,
			});
		});
		return data;
	}
}
export interface IChipsData {
	cssClass: string;
	value: string;
}
export interface ChipsDataProvider {
	getChips(field: string, cssClass: string): IChipsData[];
}
