import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../models/question-base';

@Component({
	selector: 'fitech-workspace-dynamic-form-color-picker',
	templateUrl: './dynamic-form-color-picker.component.html',
	styleUrls: ['./dynamic-form-color-picker.component.scss'],
})
export class DynamicFormColorPickerComponent implements OnInit {
	@Input() question: QuestionBase<any>;
	@Input() form: UntypedFormGroup;
	@Input() headerLabel: string;
	@Input() showUndoButton: boolean;

	color: string;

	ngOnInit(): void {
		this.color = this.question.value;
	}

	undo(): void {
		this.form.controls[this.question.key].setValue(this.question.value);
		this.form.controls[this.question.key].markAsPristine();
		this.form.controls[this.question.key].markAsUntouched();
		this.color = this.form.controls[this.question.key].value;
	}

	onChangedColor(newColor: any): void {
		this.form.controls[this.question.key].setValue(newColor);
		this.form.controls[this.question.key].markAsDirty();
	}
}
