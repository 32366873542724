import * as moment from 'moment';

export class TimeFormatter {
	static formatDuration(
		timeInMs: number,
		formatter: (hours: number, minutes: number, seconds: number) => string = TimeFormatter.defaultDurationFormatter,
	): string {
		const seconds = moment.duration(timeInMs).seconds();
		const minutes = moment.duration(timeInMs).minutes();
		const hours = Math.floor(moment.duration(timeInMs).asHours());
		return formatter(hours, minutes, seconds);
	}

	static formatInt(int: number): string {
		if (!int) {
			return '00';
		}

		if (int < 10) {
			return `0${int}`;
		}
		return `${int}`;
	}

	static defaultDurationFormatter(hours: number, minutes: number, seconds: number): string {
		if (hours > 0) {
			return `${TimeFormatter.formatInt(hours)} h ${TimeFormatter.formatInt(minutes)} m ${TimeFormatter.formatInt(seconds)} s`;
		}
		if (minutes > 0) {
			return `${TimeFormatter.formatInt(minutes)} m ${TimeFormatter.formatInt(seconds)} s`;
		}
		return `${TimeFormatter.formatInt(seconds)} s`;
	}

	static shortDurationFormatter(hours: number, minutes: number, seconds: number): string {
		return `${TimeFormatter.formatInt(hours)}:${TimeFormatter.formatInt(minutes)}:${TimeFormatter.formatInt(seconds)} `;
	}
}
