import { EventEmitter, Inject, Injectable } from '@angular/core';
import { AlarmsApiService, MeasurementsApiService } from '@fitech-workspace/fisense-common-lib';
import { IMapMarkerData } from '@fitech-workspace/shared/ui/map-lib';
import { AsEnumerable } from 'linq-es2015';
import { Observable, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Alarm } from '../models/alarm.model';
import { MachineState } from '../models/machine-state.model';
import { Machine } from '../models/machine.model';
import { IMapMarkerFactory, MAP_MARKERS_FACTORY } from '../models/markers/map-marker.factory';
import { PlanMarker } from '../models/markers/plan-marker';
import { Sensor } from '../models/sensor.model';
import { State } from '../models/state.model';
import { SensorFilterService } from '../services/sensor-filter.service';

@Injectable({
	providedIn: 'root',
})
export class PlanRepositoryService {
	dataChanged: EventEmitter<{ fitMap: boolean; locationId: number }> = new EventEmitter();
	selectionChanged: EventEmitter<{
		item: any;
		sourceOfSelection: string;
	}> = new EventEmitter();

	alarmsAcked = new EventEmitter();

	private _alarms: Alarm[] = [];
	private _currentStates: State[] = [];
	private _planMarkers: IMapMarkerData[] = [];
	private _sensors: Sensor[] = [];
	private _alarmsNotAcked: Alarm[] = [];
	private _selectedItem: any;

	constructor(
		@Inject(MAP_MARKERS_FACTORY) private _mapMarkerFactory: IMapMarkerFactory,
		private _alarmsApi: AlarmsApiService,
		private _measurementsApi: MeasurementsApiService,
		private _sensorFilterService: SensorFilterService
	) {}

	selectItem(item: any, sourceOfSelection: string, emitChange: boolean = true): void {
		this._selectedItem = item;
		if (emitChange) {
			this.selectionChanged.emit({
				item: item,
				sourceOfSelection: sourceOfSelection,
			});
		}
	}

	onDataChange(emitChange: boolean, fitMap: boolean, locationId: number): void {
		if (emitChange) {
			this.dataChanged.emit({ fitMap: fitMap, locationId: locationId });
		}
	}

	reloadFromApi(locationId: number, from: Date, to: Date, fitMap: boolean, showAlarmsDeck: boolean): Observable<IMapMarkerData[]> {
		const actions = [];
		const loadCurrentStates = this.loadCurrentStatesFromApi(locationId);
		const loadAlarmsNotAcked = this.loadAlarmsNotAckedFromApi(showAlarmsDeck ? null : locationId);
		actions.push(loadCurrentStates);
		actions.push(loadAlarmsNotAcked);
		return forkJoin(actions).pipe(
			map((res) => {
				this._currentStates = this.extractStates(this._sensorFilterService.filterStates(res[0] as any));
				if (this._currentStates) {
					this._currentStates = AsEnumerable(this._currentStates).ToArray();
				}

				this._currentStates.forEach((currentState) => {
					if (currentState instanceof MachineState) {
						const machineState = currentState as MachineState;
						machineState.sensorsStates.forEach((sensorsState) => {
							(res[1] as any).forEach((alarm) => {
								if (alarm.sensorId === sensorsState.sourceId) {
									this._alarms.push(alarm);
								}
							});
						});
					}
				});

				this._sensors = State.getSensors(this._currentStates).concat(MachineState.getSensors(this._currentStates));

				this._planMarkers = PlanMarker.getPlanMarkers(
					this._currentStates.map((state) => (state.source instanceof Sensor ? (state as State) : undefined))
				);

				const machineMarkers = AsEnumerable(this._currentStates)
					.Where((state) => state.source instanceof Machine)
					.Select((state) => this._mapMarkerFactory.getMarker(state, state.source.type))
					.ToArray();

				this._planMarkers = this._planMarkers.concat(machineMarkers);

				this.onDataChange(true, fitMap, locationId);
				return this._planMarkers;
			})
		);
	}

	loadAlarmsNotAckedFromApi(locationId?: number): Observable<Alarm[]> {
		return this._alarmsApi.getAlarmsNotAcked(null, locationId).pipe(
			map((res) => {
				this._alarmsNotAcked = res;
				return res;
			})
		);
	}

	loadCurrentStatesFromApi(locationId?: number, onlyWithSamplingMs?: boolean): Observable<State[]> {
		return this._measurementsApi.getCurrentStates(locationId, onlyWithSamplingMs).pipe(
			map((res) => {
				this._currentStates = res;
				return res;
			})
		);
	}

	loadAlarmsFromApi(locationId: number, from: Date, to: Date): Observable<Alarm[]> {
		return this._alarmsApi.getAlarms(from, to, locationId, null).pipe(
			map((res) => {
				this._alarms = res;
				return res;
			})
		);
	}

	extractStates(data: State[]): (State | MachineState)[] {
		const tempMachineStates: MachineState[] = [];
		const tempStates: State[] = [];
		data.map((x) => {
			const sensor = x.source as Sensor;
			if (sensor.machineId) {
				let machineState = tempMachineStates.find((state) => state.sourceId === sensor.machineId);
				if (!machineState) {
					machineState = new MachineState(sensor);
					tempMachineStates.push(machineState);
				}

				machineState.sensorsStates.push(x);
				const lastAlarmSensor = AsEnumerable(machineState.sensorsStates)
					.Where((state) => (state.alarmGuid ? true : false))
					.OrderByDescending((state) => state.timestamp)
					.FirstOrDefault();
				machineState.alarm = lastAlarmSensor ? lastAlarmSensor.alarm : null;

				return;
			}
			tempStates.push(x);
		});
		return [...tempStates, ...tempMachineStates];
	}

	getPlanMarkerById(id: number): IMapMarkerData {
		return this._planMarkers.find((marker: IMapMarkerData) => marker.id === id);
	}

	getPlanMarkers(): IMapMarkerData[] {
		return this._planMarkers;
	}

	getSensorAlarms(): Alarm[] {
		return this._alarms;
	}

	getSensorStates(): State[] {
		return this._currentStates;
	}

	getSensorAlarmsNotAcked(): Alarm[] {
		return this._alarmsNotAcked;
	}

	getSelectedItem(): any {
		return this.selectItem;
	}

	ackAlarms(alarmGuids: string[]): Observable<void> {
		return this._alarmsApi.changeAlarmState(alarmGuids, 'Acknowledged').pipe(tap(() => this.alarmsAcked.emit()));
	}

	setDisplayedAlarms(alarmGuids: string[]): Observable<void> {
		return this._alarmsApi.changeAlarmState(alarmGuids, 'Displayed').pipe(tap(() => this.alarmsAcked.emit()));
	}

	validateAlarms(alarmGuids: string[], isReal: boolean): Observable<void> {
		const state = isReal ? 'VerifiedAsReal' : 'VerifiedAsFalse';
		return this._alarmsApi.changeAlarmState(alarmGuids, state).pipe(tap(() => this.alarmsAcked.emit()));
	}

	ackAlarmsBySensorId(sourceId: number): Observable<void> {
		const guids = AsEnumerable(this._alarmsNotAcked)
			.Where((x: Alarm) => x.sensorId === sourceId)
			.Select((x: Alarm) => x.guid)
			.ToArray();
		return this._alarmsApi.changeAlarmState(guids, 'Acknowledged').pipe(tap(() => this.alarmsAcked.emit()));
	}
}
