import { CommonModule } from '@angular/common';
import { Inject, InjectionToken, NgModule, Optional } from '@angular/core';
import { CoreLibModule } from '@fitech-workspace/core-lib';
import { SharedUiChartLibModule } from '@fitech-workspace/shared/ui/chart-lib';
import { SharedUiInputsModule } from '@fitech-workspace/shared/ui/controls-lib';
import { SharedUiLayoutLibModule } from '@fitech-workspace/shared/ui/layout-lib';
import { SharedUiGridsAddonsModule, SharedUiGridsCoreModule } from '@fitech-workspace/shared/ui/map-lib';
import { AlarmConfirmationDialogComponent } from './alarms/alarms-current/alarm-cofirmation-dialog/alarm-confirmation-dialog.component';
import { AlarmsCurrentComponent } from './alarms/alarms-current/alarms-current.component';
import { AlarmsIndicatorComponent } from './alarms/alarms-indicator/alarms-indicator.component';
import { LocationSelectorComponent } from './location-selector/location-selector.component';
import { LocationSelectorQlComponent } from './location-selector-ql/location-selector-ql.component';
import { HmiButtonComponent } from './machine/hmi-button/hmi-button.component';
import { HmiIndicatorComponent } from './machine/hmi-indicator/hmi-indicator.component';
import { MachineSensorsChartsComponent } from './machine/standard-dialog/machine-sensors-charts/machine-sensors-charts.component';
import { SensorsChartComponent } from './machine/standard-dialog/machine-sensors-charts/sensors-chart/sensors-chart.component';
import { SensorsChartWithAlarmsComponent } from './machine/standard-dialog/machine-sensors-charts/sensors-chart-with-alarms/sensors-chart-with-alarms.component';
import { MachineSensorsHmiListComponent } from './machine/standard-dialog/machine-sensors-hmi-list/machine-sensors-hmi-list.component';
import { MachineSensorsListComponent } from './machine/standard-dialog/machine-sensors-list/machine-sensors-list.component';
import { MachineComponent } from './machine/standard-dialog/machine.component';
import { TrendSensorComponent } from './machine/trend-sensor/trend-sensor.component';
import { AlarmLifetimeThresholdEnum } from './models/alarm-lifetime-threshold.enum';
import { CommonGlobals } from './models/common-globals';

export const ALARM_LIFETIME_THRESHOLD = new InjectionToken<AlarmLifetimeThresholdEnum>('ALARM_LIFETIME_THRESHOLD');

@NgModule({
	declarations: [
		LocationSelectorComponent,
		LocationSelectorQlComponent,
		MachineComponent,
		MachineSensorsListComponent,
		MachineSensorsHmiListComponent,
		MachineSensorsChartsComponent,
		SensorsChartComponent,
		SensorsChartWithAlarmsComponent,
		HmiIndicatorComponent,
		HmiButtonComponent,
		AlarmsCurrentComponent,
		AlarmConfirmationDialogComponent,
		AlarmsIndicatorComponent,
		TrendSensorComponent,
	],
	imports: [
		CoreLibModule,
		CommonModule,
		SharedUiGridsCoreModule,
		SharedUiGridsAddonsModule,
		SharedUiChartLibModule,
		SharedUiLayoutLibModule,
		SharedUiInputsModule,
	],
	exports: [
		LocationSelectorComponent,
		LocationSelectorQlComponent,
		MachineComponent,
		AlarmsCurrentComponent,
		AlarmConfirmationDialogComponent,
		AlarmsIndicatorComponent,
		HmiIndicatorComponent,
		TrendSensorComponent,
	],
})
export class FisenseCommonLibModule {
	constructor(@Optional() @Inject(ALARM_LIFETIME_THRESHOLD) alarmLifetimeThreshold: AlarmLifetimeThresholdEnum) {
		CommonGlobals.alarmLifetimeThreshold = alarmLifetimeThreshold || AlarmLifetimeThresholdEnum.UntilConditionIsTrue;
	}
}
