import { EventEmitter } from '@angular/core';
import { DropdownOption } from './dropdown-option.model';
import { QuestionBase } from './question-base';

export class DropdownQuestion extends QuestionBase<string> {
	optionsHasChanged: EventEmitter<any> = new EventEmitter();

	controlType = 'dropdown';
	icon?: string;

	private _options: DropdownOption[] = [];

	get options(): DropdownOption[] {
		return this._options;
	}

	set options(value: DropdownOption[]) {
		this._options = value;
		this.optionsHasChanged.emit(this._options);
	}

	constructor(options: {} = {}) {
		super({
			value: options['value'],
			key: options['key'],
			label: options['label'],
			required: options['required'],
			disabled: options['disabled'],
			visible: options['visible'],
			order: options['order'],
			controlType: options['controlType'],
			validators: options['validators'],
			onChange: options['onChange'],
			showOnCloning: options['showOnCloning'],
		});
		this.icon = options['icon'];
		this.options = options['options'] || [];
	}
}
