import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
	selector: 'fitech-workspace-text-renderer',
	templateUrl: './text-renderer.component.html',
	styleUrls: ['./text-renderer.component.scss'],
})
export class TextRendererComponent implements ICellRendererAngularComp {
	params: any;
	text: string;
	icon: string;

	refresh(params: any): boolean {
		return true;
	}

	agInit(params: any): void {
		this.params = params;
		const methodName = this.params.getTextMethod;
		if (this[methodName]) {
			const param = this.params.getTextMethodParam;
			this.text = this[methodName](this.params.value, param);
		} else {
			this.text = this.params.value;
		}
		this.icon = this.params.icon;
	}

	afterGuiAttached?(params?: any): void {
		this.params = params;
	}

	getRoundedNumber(num: number, suffix?: string): string {
		if (num) {
			return Math.round(num * 100) / 100 + suffix;
		} else {
			return '0' + suffix;
		}
	}

	getDuration(seconds: number, param?: any): string {
		if (seconds) {
			let minutes = Math.floor(seconds / 60);
			seconds = Math.round(seconds % 60);
			const hours = Math.floor(minutes / 60);
			minutes = minutes % 60;
			if (hours > 0) {
				return `${hours} h ${minutes} min ${seconds} s`;
			}
			if (minutes > 0) {
				return `${minutes} min ${seconds} s`;
			}
			return `${seconds} s`;
		} else {
			return '0 s';
		}
	}
}
