import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { HttpParamsBuilder } from '@fitech-workspace/core-lib';
import { API_BASE_URL } from '@fitech-workspace/shared/data-access/master-data-api-lib';
import { GridCellRendererEnum, GridColumn } from '@fitech-workspace/shared/ui/grid-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityCommandResult } from '../../../models/entity-command-result.model';
import { ClientDto, CreateClientCmd, EditClientCmd, MasterClientData } from '../models';

@Injectable({
	providedIn: 'root',
})
export class MasterClientsService {
	private _apiUrl: string;

	private get _clientsUrl(): string {
		return `${this._apiUrl}/api/clients`;
	}

	constructor(private _httpClient: HttpClient, @Optional() @Inject(API_BASE_URL) apiUrl?: string) {
		this._apiUrl = apiUrl ? apiUrl : '';
	}

	getClients(refreshCache?: boolean): Observable<ClientDto[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create().append('refreshCache', refreshCache);
		return this._httpClient.get<ClientDto[]>(this._clientsUrl, { params: httpParamsBuilder.build() });
	}

	getMasterClients(refreshCache?: boolean): Observable<MasterClientData[]> {
		return this.getClients(refreshCache).pipe(map((clients: ClientDto[]) => clients.map((client: ClientDto) => new MasterClientData(client))));
	}

	getClientsFiltered(clientIds?: number[], allowParentData?: boolean, refreshCache?: boolean): Observable<ClientDto[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create()
			.appendArray('clientIds', clientIds)
			.append('allowParentData', allowParentData)
			.append('refreshCache', refreshCache);

		return this._httpClient.get<ClientDto[]>(`${this._clientsUrl}/filtered`, { params: httpParamsBuilder.build() });
	}

	getClientById(id: number): Observable<ClientDto> {
		return this._httpClient.get<ClientDto>(`${this._clientsUrl}/${id}`);
	}

	createClient(createClientReq: CreateClientCmd): Observable<EntityCommandResult> {
		return this._httpClient.post<EntityCommandResult>(this._clientsUrl, createClientReq);
	}

	editClient(clientId: number, editClientReq: EditClientCmd): Observable<EntityCommandResult> {
		return this._httpClient.put<EntityCommandResult>(`${this._clientsUrl}/${clientId}`, editClientReq);
	}

	getDefaultColumns(): GridColumn[] {
		return [
			{ field: 'id', headerName: 'id', pinned: true, minWidth: 150, maxWidth: 160 },
			{
				field: 'name',
				headerName: 'name',
				cellRenderer: GridCellRendererEnum.SimpleAvatar,
				minWidth: 150,
			},
			{ field: 'reportsLanguage', headerName: 'reportsLanguage', minWidth: 80 },
			{
				field: 'comment',
				headerName: 'comment',
				minWidth: 150,
			},
		];
	}
}
