import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '@fitech-workspace/core-lib';
import { MasterUsersService } from '@fitech-workspace/master-data-lib';
import { ApiService } from '@fitech-workspace/shared/data-access/master-data-api-lib';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import jwt_decode from 'jwt-decode';
import { AsEnumerable } from 'linq-es2015';
import { BehaviorSubject, Observable, Subject, from, of } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { UserAllowedClient, UserDto } from '../models';
import { User } from '../models/user.model';

export const AUTH_CONFIG = new InjectionToken<AuthConfig>('AUTH_CONFIG');
export const AUTH_ENV_CONFIG = new InjectionToken<any>('AUTH_ENV_CONFIG');

@Injectable({
	providedIn: 'root',
})
export class UserService {
	logoSrc: string;
	isValidatingUser: boolean;
	roles = [];
	currentUserChanged: BehaviorSubject<User>;
	redirectAfterLoginUrl: any;

	userLogout = new EventEmitter<void>();

	private _currentUser: User;
	private _currentUserKey = 'currentUser';
	private _authInProgress: any;
	private _authConfig: AuthConfig;
	private _environmentConfig: any;
	private _isLoggedToggleSubject = new Subject<boolean>();
	get currentUser(): User {
		return this._currentUser;
	}

	get userAllowedClientIds(): number[] {
		return this._currentUser.userAllowedClients.map((x: UserAllowedClient) => x.clientId);
	}

	get userAllowedClientsWithChildClientIds(): number[] {
		return this._currentUser.userAllowedClientsWithChildClients.map((x: UserAllowedClient) => x.clientId);
	}

	get accessToken(): string {
		return this._oauthService.getAccessToken();
	}

	get currentUser$(): Observable<User> {
		return this.currentUserChanged.asObservable();
	}

	get isLoggedToggle$(): Observable<boolean> {
		return this._isLoggedToggleSubject.asObservable().pipe(distinctUntilChanged());
	}

	get isLoggedIn$(): Observable<boolean> {
		return this.isLoggedToggle$.pipe(filter((isLoggedIn: boolean) => isLoggedIn));
	}

	set currentUser(value: User) {
		//object has changed
		if ((this._currentUser == null && value != null) || (this._currentUser != null && value == null) || this._currentUser.id !== value.id) {
			this._currentUser = value;
			this.currentUserChanged.next(value); //emit change
		} else {
			this._currentUser = value;
		}
	}

	constructor(
		private _oauthService: OAuthService,
		private _apiClient: ApiService,
		private _masterUsersService: MasterUsersService,
		private _languageService: LanguageService,
		private _router: Router,
		private _httpClient: HttpClient,
		@Inject(AUTH_CONFIG) authConfig: AuthConfig,
		@Inject(AUTH_ENV_CONFIG) environmentConfig: any,
		@Inject(DOCUMENT) private _document: Document
	) {
		this.currentUserChanged = new BehaviorSubject(this.currentUser);

		this._authConfig = authConfig;
		this._environmentConfig = environmentConfig;
		this.configure();
	}

	configure(): void {
		this._oauthService.events.subscribe(async (e) => {
			if (e.type === 'discovery_document_loaded') {
				if (this._authInProgress) {
					return;
				}
				await this.loadUserDataAsync();
			}
		});
		this._oauthService.configure(this._authConfig);
		this._oauthService.tokenValidationHandler = new JwksValidationHandler();
		this._oauthService.loadDiscoveryDocumentAndTryLogin();
		this._oauthService.loadDiscoveryDocument().then(() => {});
	}

	loginWith(login: string, password: string): Observable<any> {
		const loadReq = this._oauthService.loadDiscoveryDocument().then(() => {
			return this._oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(login, password);
		});
		return from(loadReq).pipe(
			map(async (user: object) => {
				if (user) {
					await this.loadUserDataAsync().then(() => {
						try {
							this.redirectAfterLogin();
							this._isLoggedToggleSubject.next(true);
						} catch (ex) {
							console.error(ex);
						}
					});
				}
			})
		);
	}

	loginWithAzureAd(): void {
		const returnUrl = document.location.origin;
		this._document.location.href = this._environmentConfig.masterDataApiUrl + '/api/users/external-login?returnUrl=' + returnUrl;
	}

	login(): Promise<object> {
		// load discovery document to check if auth portal is working
		const promiseLoadDoc = this._oauthService.loadDiscoveryDocument();
		promiseLoadDoc.then(() => {
			this._oauthService.initImplicitFlow(null, {
				culture: this._languageService.currentLanguage,
				context: this._environmentConfig.context,
			});
		});
		return promiseLoadDoc;
	}

	logout(): void {
		this._oauthService.logOut(true);
		this.clearUserData();
		this.userLogout.emit();
		this._isLoggedToggleSubject.next(false);
	}

	requestForRolesForUserAd(userEmail: string, roles: string[]): Observable<any> {
		const body: any = {
			adminEmail: null, //configured in backend-app appsettings
			userEmail: userEmail,
			message: `New user created using Active Directory authorization. User email: ${userEmail}. Requested roles for user: ${roles.join(
				', '
			)}. Request sent from: ${document.location.origin}.`,
		};

		return this._httpClient.post<any>(this._environmentConfig.masterDataApiUrl + '/api/users/user-ad-roles-request', body);
	}

	clearUserData(): void {
		this.currentUser = null;
		this.currentUserChanged.next(null);
		this.redirectAfterLoginUrl = null;
		localStorage.removeItem(this._currentUserKey);
	}

	async retrieveUserDataAsync(): Promise<void> {
		const userFromLocal = localStorage.getItem(this._currentUserKey);
		if (userFromLocal != null) {
			this.currentUser = new User(JSON.parse(userFromLocal));
		}

		if (this.currentUser == null || this.currentUser.id == null) {
			await this.loadUserDataAsync();
		}
	}

	loadUserDataAsync(): Promise<any> {
		this._authInProgress = true;
		const userClaims = this._oauthService.getIdentityClaims() as any;
		if (userClaims != null && userClaims.name != null && this._oauthService.hasValidAccessToken()) {
			return this._masterUsersService
				.getSelfUser()
				.toPromise()
				.then(
					(results: UserDto) => {
						this.currentUser = new User(results);
						localStorage.setItem(this._currentUserKey, JSON.stringify(this.currentUser));
						this._authInProgress = false;
					},
					() => (this._authInProgress = false)
				);
		}
	}

	public async saveTokensFromAd(tokenData: any): Promise<void> {
		localStorage.setItem('access_token', tokenData.accessToken);
		const tokenInfo = jwt_decode(tokenData.accessToken);
		localStorage.setItem('id_token_claims_obj', JSON.stringify(tokenInfo));
		localStorage.setItem('access_token_stored_at', (tokenInfo as any).iat);
		localStorage.setItem('expires_at', (tokenInfo as any).eat);
		localStorage.setItem('refresh_token', tokenData.refreshToken);
		await this.loadUserDataAsync();
		this._isLoggedToggleSubject.next(true);
	}

	hasRole(role: string): boolean {
		return this.roles.map((x: any) => x.name).includes(role);
	}

	hasOneOfRoles(roles: string[]): boolean {
		let result = false;
		roles.forEach((role: string) => {
			if (this.roles.map((x: any) => x.name).includes(role)) {
				result = true;
			}
		});
		return result;
	}

	async refreshTokenAsync(): Promise<void> {
		try {
			console.log('Refreshing token');
			await this._oauthService.refreshToken();
			await this._oauthService.loadUserProfile();
			await this.loadUserDataAsync();
		} catch (err) {
			console.error('Error refreshing token', err);
		}
	}

	getAppContext(): any {
		return this._environmentConfig.context;
	}

	async isUserValidAsync(): Promise<boolean> {
		try {
			this.isValidatingUser = true;
			if (this._oauthService.hasValidAccessToken()) {
				if (this.currentUser == null) {
					await this.loadUserDataAsync();
				}
				this.isValidatingUser = false;
				return Promise.resolve(true);
			}

			await this.refreshTokenAsync();
			const isValid = this._oauthService.hasValidAccessToken();

			if (!isValid && this.currentUser != null) {
				this.logout();
			}
			return isValid;
		} catch (ex) {
			throw ex;
		} finally {
			this.isValidatingUser = false;
		}
	}

	getClientIds(clientId?: number): number[] {
		let clientIds = [];
		if (clientId != null) {
			clientIds = [clientId];
		} else {
			if (this.currentUser.userAllowedClientsWithChildClients != null) {
				clientIds = AsEnumerable(this.currentUser.userAllowedClientsWithChildClients)
					.Select((x) => x.clientId)
					.ToArray();
			}
		}
		return clientIds;
	}

	setLoggedInWithPreviousToken(): void {
		this._isLoggedToggleSubject.next(true);
	}

	isUserEmail(email: string): boolean {
		return this._currentUser?.email === email;
	}

	private redirectAfterLogin(): void {
		if (this.redirectAfterLoginUrl === '/login') {
			this.redirectAfterLoginUrl = null;
			this._router.navigate(['']);
		} else if (this.redirectAfterLoginUrl) {
			this._router.navigateByUrl(this.redirectAfterLoginUrl);
			this.redirectAfterLoginUrl = null;
		} else {
			this._router.navigate(['']);
		}
	}
}
