/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/typedef */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ErrorHandlingService, NotificationService } from '@fitech-workspace/core-lib';
import * as errorHandlingActions from '../actions/error-handling.actions';

@Injectable()
export class ErrorHandlingEffects {
	constructor(private readonly _actions$: Actions, private _erorHandlingService: ErrorHandlingService, private _notificationService: NotificationService) {}

	handleError$ = createEffect(
		() =>
			this._actions$.pipe(
				ofType(errorHandlingActions.handleError),
				tap((action) => this._erorHandlingService.handleError(action.error))
			),
		{ dispatch: false }
	);

	showErrorText$ = createEffect(
		() =>
			this._actions$.pipe(
				ofType(errorHandlingActions.showErrorText),
				tap((action) => this._notificationService.showError(action.error))
			),
		{ dispatch: false }
	);
}
