import { ClientDto, GetClientsStatsDto } from '@fitech-workspace/master-data-lib';
import { IAvatarData, IAvatarDataProvider } from '@fitech-workspace/shared/ui/grid-lib';

export interface IMasterClient extends GetClientsStatsDto, ClientDto {}

export class MasterClientData implements IAvatarDataProvider {
	clientStats: GetClientsStatsDto;
	client: ClientDto;

	constructor(data: ClientDto) {
		this.client = data;
	}

	getAvatarData(): IAvatarData {
		const avatarData: IAvatarData = { value: null, letter: null, color: null };
		if (this.client) {
			if (this.client.name) {
				avatarData.value = this.client.name;
				avatarData.letter = this.client.name.toString().charAt(0);
				avatarData.color = 'color-' + avatarData.letter;
			}
		}
		return avatarData;
	}
}
