import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[disableControl]',
})
export class DisableControlDirective {
	@Input() set disableControl(condition: boolean) {
		if (this._ngControl.control && this._ngControl.name) {
			condition ? this._ngControl.control.disable() : this._ngControl.control.enable();
		}
	}

	constructor(private _ngControl: NgControl) {}
}
