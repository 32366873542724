import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	currentLanguage: string;

	constructor(private _translate: TranslateService, private _http: HttpClient) {
		this._translate.setDefaultLang('en');

		const language = localStorage.getItem('app.language');
		this.useLanguage(language || 'en');
		this._translate.instant;
	}

	useLanguage(language: string): void {
		this.currentLanguage = language.toUpperCase();
		this._translate.use(language);
		localStorage.setItem('app.language', language);
	}

	getReportLanguages(): Observable<any[]> {
		return this._http.get<any>('./assets/languages.json').pipe(
			map((res: any) => {
				return res.reportLanguages;
			})
		);
	}
}
