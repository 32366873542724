import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreLibModule } from '@fitech-workspace/core-lib';
import { IconsComponent } from './components/icon/icon.component';
import { IconTooltipComponent } from './components/icon-tooltip/icon-tooltip.component';

@NgModule({
  declarations: [
    IconsComponent,
    IconTooltipComponent
  ],
  imports: [
    CommonModule,
    CoreLibModule
  ],
  exports: [
    IconsComponent,
    IconTooltipComponent
  ]
})
export class SharedUiIconsModule { }
