<div #gridContainer class="grid-container">
	<div id="tool-panel-container" class="tool-panel" *ngIf="showToolPanel">
		<div class="tool-container">
			<button class="btn btn-transparent btn-tool-panel" [disabled]="!isPrepared" (click)="toggleSidebar()">
				<span>
					<i class="fa fa-columns" aria-hidden="true"></i>
					Columns
				</span>
			</button>
		</div>
	</div>
	<div class="mat-elevation-z8 grid height-100p">
		<aside class="sidebar" *ngIf="isSidebarEnabled && showToolPanel">
			<div class="buttons-container">
				<button class="btn btn--link" (click)="selectAllFilterColumns()">Select all</button>
				<button class="btn btn--link" (click)="clearAllFilterColumns()">Clear all</button>
			</div>
			<div #selectionListContainer class="selection-list-container">
				<mat-selection-list color="primary" [(ngModel)]="visibleColumnNames" (selectionChange)="onColumnVisibleChange($event)">
					<mat-list-option *ngFor="let column of togglingColumns; trackBy: trackByHeaderName" [value]="column.headerName" [disabled]="column.pinned">
						{{ column.headerName }}
					</mat-list-option>
				</mat-selection-list>
			</div>
		</aside>

		<div ag-grid="gridOptions" class="ag-theme-blue height-100p">
			<ag-grid-angular
				*ngIf="isPrepared"
				class="ag-theme-material height-100p"
				[defaultColDef]="defaultColDef"
				[suppressRowClickSelection]="true"
				[suppressScrollOnNewData]="true"
				[rowSelection]="rowSelection"
				[rowDragManaged]="true"
				[rowDragMultiRow]="true"
				[suppressMovableColumns]="true"
				[rowData]="rows"
				[pagination]="pagination"
				[rowHeight]="rowHeight"
				[paginationPageSize]="paginationPageSize"
				[rowModelType]="rowModelType"
				[paginationNumberFormatter]="paginationNumberFormatter"
				[columnDefs]="columns"
				[modules]="modules"
				[context]="context"
				[components]="frameworkComponents"
				[enableCellTextSelection]="enableCellTextSelection"
				[stopEditingWhenCellsLoseFocus]="true"
				[maxBlocksInCache]="maxBlocksInCache"
				[cacheBlockSize]="cacheBlockSize"
				[cacheOverflowSize]="cacheOverflowSize"
				[maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
				[infiniteInitialRowCount]="infiniteInitialRowCount"
				[blockLoadDebounceMillis]="blockLoadDebounceMillis"
				[getRowId]="getRowId"
				[pinnedBottomRowData]="pinnedBottomRowData"
				[rowBuffer]="rowBuffer"
				[suppressColumnVirtualisation]="suppressColumnVirtualisation"
				[suppressRowVirtualisation]="suppressRowVirtualisation"
				[debounceVerticalScrollbar]="debounceVerticalScrollbar"
				(selectionChanged)="onSelectionChanged()"
				(cellDoubleClicked)="onCellDoubleClicked($event)"
				(cellValueChanged)="onCellValueChanged($event)"
				(rowDragEnd)="onRowDragEnd($event)"
				(cellClicked)="onCellClicked($event)"
				(gridReady)="onGridReady($event)"
				(gridSizeChanged)="onGridSizeChanged($event)"
				(modelUpdated)="onModelUpdated($event)"
				(filterChanged)="onFilterChanged()"
				(rowDataUpdated)="onRowDataChanged()">
      		</ag-grid-angular>

			<div *ngIf="pagination" class="pagination-header">
				<mat-form-field class="pagination-form">
					<mat-select (selectionChange)="onPageSizeChanged()" [(ngModel)]="pageSize">
						<mat-option [value]="5">5</mat-option>
						<mat-option [value]="10">10</mat-option>
						<mat-option [value]="50">50</mat-option>
						<mat-option [value]="100">100</mat-option>
						<mat-option [value]="1000">1000</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
	</div>
</div>
