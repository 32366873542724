import * as moment from 'moment';
import { Alarm } from './alarm.model';
import { Machine } from './machine.model';
import { Sensor } from './sensor.model';

export class State {
	rawValue: any;
	sourceId: number;
	isHistoryOn = false;
	min?: number;
	max?: number;
	sensorsGroupType?: string;
	source?: Sensor | Machine;
	alarm?: Alarm;

	private _alarmIsActive?: boolean;
	private _timestamp: Date;
	private _value: any;

	get value(): any {
		return this._value;
	}

	get timestamp(): Date {
		return this._timestamp;
	}

	get alarmIsActive(): boolean {
		return this._alarmIsActive;
	}

	get timestampFromNow(): string {
		return moment(this.timestamp).fromNow();
	}

	get alarmGuid(): string {
		return this.alarm ? this.alarm.guid : null;
	}

	get alarmType(): string {
		return this.alarm ? this.alarm.type : null;
	}

	set value(value: any) {
		this._value = value;
	}

	set timestamp(value: Date) {
		this._timestamp = value;
	}

	set alarmIsActive(value: boolean) {
		this._alarmIsActive = value;
	}

	constructor(data?: any) {
		if (data) {
			this.sourceId = data['sensorId'];
			this.value = data['value'] != null ? data['value'] : data['rawValue'];
			this.rawValue = data['rawValue'];
			this.timestamp = data['timestamp'];
			this.alarmIsActive = data['alarmIsActive'];
			this.alarm = data['alarm'] ? new Alarm(data['alarm']) : null;
			this.source = Sensor.createFromState(data);
		}
	}

	static getSensors(currentStates: State[]): any[] {
		const sensors: any[] = [];
		currentStates.forEach((x) => {
			if (x.source) {
				sensors.push(x.source);
			}
		});
		return sensors;
	}
}
