import { Component, Input } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { SwitchQuestion } from '../../models/question-switch';

@Component({
	selector: 'fitech-workspace-switch-md',
	templateUrl: './dynamic-form-switch-input.component.html',
	styleUrls: ['./dynamic-form-switch-input.component.scss'],
})
export class DynamicFormSwitchInputComponent {
	@Input() question: SwitchQuestion;
	@Input() form: UntypedFormGroup;

	get control(): FormControl<string> {
		return this.form.controls[this.question.key] as FormControl<string>;
	}
}
