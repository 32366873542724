// tslint:disable:max-line-length
import { SCREEN_SIZE } from '@fitech-workspace/core-lib';
import * as moment from 'moment';
import { Sensor } from '../../../../models/sensor.model';

export function getCurrentMachineStatesColumnDefs(screenSize?: SCREEN_SIZE) {
	if (screenSize == null) {
		screenSize = SCREEN_SIZE.LG;
	}

	if (screenSize < SCREEN_SIZE.LG) {
		return getForNarrowScreens(commonFunctions);
	} else {
		return getForWideScreens(commonFunctions);
	}
}

function getForWideScreens({ getQuickFilterText, filterValueGetter, valueGetter, cellRenderer, cellStyle }) {
	return [
		{ headerName: '', width: 5 },
		{
			headerName: 'Sensor',
			field: 'source.name',
			role: 'name',
			width: 180,
			cellRenderer,
			cellStyle,
			filterValueGetter,
		},
		{
			headerName: 'Type',
			field: 'source.type',
			role: 'type',
			width: 30,
			cellRenderer,
		},
		{
			headerName: 'Value',
			field: 'value',
			width: 60,
			valueGetter,
			getQuickFilterText,
		},
		{
			headerName: '!',
			field: 'alarmGuid',
			role: 'alarmGuid',
			width: 20,
			cellRenderer,
			getQuickFilterText,
		},
		{
			headerName: 'Timestamp',
			field: 'timestamp',
			role: 'timestamp',
			width: 110,
			cellRenderer,
			getQuickFilterText,
		},
		{
			headerName: '',
			width: 20,
			role: 'add',
			cellRenderer,
			getQuickFilterText,
		},
		{
			headerName: '',
			width: 20,
			role: 'del',
			cellRenderer,
			getQuickFilterText,
		},
		{ headerName: '', width: 10, getQuickFilterText },
	];
}

function getForNarrowScreens({ getQuickFilterText, filterValueGetter, valueGetter, cellRenderer, cellStyle }) {
	return [
		{ headerName: '', width: 5 },
		{
			headerName: 'Sensor',
			field: 'source.name',
			role: 'name',
			width: 95,
			cellRenderer,
			cellStyle,
			filterValueGetter,
		},
		{
			headerName: 'Type',
			field: 'source.type',
			role: 'type',
			width: 35,
			cellRenderer,
		},
		{
			headerName: 'Value',
			field: 'value',
			role: 'valueNarrow',
			width: 85,
			cellRenderer,
			cellStyle,
			valueGetter,
			getQuickFilterText,
		},
		{
			headerName: '!',
			field: 'alarmGuid',
			role: 'alarmGuid',
			width: 20,
			cellRenderer,
		},
		{
			headerName: '',
			width: 20,
			role: 'add',
			cellRenderer,
			getQuickFilterText,
		},
		{
			headerName: '',
			width: 20,
			role: 'del',
			cellRenderer,
			getQuickFilterText,
		},
	];
}

export function getRowColor(params: any): { color: string; background?: string } {
	const alarmColor: string | null = params.data.alarmIsActive ? params.data.alarm.getAlarmColor() : null;
	if (alarmColor) {
		return { background: alarmColor, color: '#ffffff' };
	}
}

const commonFunctions = {
	getQuickFilterText: () => '',
	filterValueGetter: (params) => `${params.data.source.name.replace(/ /g, '.')}`,
	valueGetter: (params) => {
		return params.data.value + ' ' + params.data.source.units;
	},
	cellStyle: (x) => ({ 'font-size': '0.7rem' }),
	cellRenderer: (params) => {
		const role = params.colDef.role;
		if (role === 'name') {
			return `<div>${params.data.source.name}</div>`;
		}
		if (role === 'type') {
			return '<i class="' + Sensor.getIconClass(params.data.source.type) + '"></i>';
		}
		if (role === 'valueNarrow') {
			return `<div style="margin-top:-5px;">${
				params.data.value + ' ' + params.data.source.units
			}</><div style="margin-top:-15px; font-size: 0.6rem; color:#BEBEBE">${params.data.timestamp ? moment(params.data.timestamp).fromNow() : '-'}</div>`;
		}
		if (role === 'alarmGuid') {
			if (params.value) {
				return `<i class="far fa-bell"></i>`;
			}
		}
		if (role === 'timestamp') {
			return params.value ? moment(params.value).fromNow() : '-';
		}
		if (role === 'add') {
			return `<i class="fas fa-plus grid-action-element add-machine-sensor ${params.data.source.addedToReport ? 'disabled' : ''}"></i>`;
		}
		if (role === 'del') {
			return `<i class="fas fa-trash grid-action-element remove-machine-sensor ${params.data.source.addedToReport ? '' : 'disabled'}"></i>`;
		}
	},
};
