import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpParamsBuilder } from '@fitech-workspace/core-lib';
import { Observable } from 'rxjs';
import { CM_BACKEND_API_URL } from './master-data-api.service';
import { HeartbeatDto } from '../../reports/models/heartbeat-dto.model';

@Injectable({
	providedIn: 'root',
})
export class HeartbeatsApiService {
	private get _mainUrl(): string {
		return `${this._apiUrl}heartbeats`;
	}

	constructor(private _httpClient: HttpClient, @Inject(CM_BACKEND_API_URL) private _apiUrl: string) {}

	getHeartbeats(): Observable<HeartbeatDto[]> {
		return this._httpClient.get<HeartbeatDto[]>(this._mainUrl);
	}

	getHeartbeatByMachineName(machineName: string): Observable<HeartbeatDto> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create().append('machineName', machineName);

		return this._httpClient.get<HeartbeatDto>(`${this._mainUrl}/${machineName}`, { params: httpParamsBuilder.build() });
	}
}
