import { Injectable } from '@angular/core';
import { StringUtils } from '@fitech-workspace/core-lib';
import { Observable, fromEvent } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { MultiScreensMessage } from '../models';

@Injectable({
	providedIn: 'root',
})
export class MultiScreensService {
	private readonly _refreshScreenIdsMessage = '[MultiScreens] Refresh screen Ids';
	private readonly _activeScreensMessageKey = '__active-screens-message';
	private readonly _activeScreensKey = '__active-screens';
	private _screenId: string;

	get screenId(): string {
		return this._screenId;
	}

	generateScreenId(): void {
		this._screenId = StringUtils.generateUniqueId();
		window.localStorage.setItem(this._activeScreensKey, JSON.stringify([this._screenId]));
		this.sendMessage(this._refreshScreenIdsMessage);
	}

	clearScreenId(): void {
		let activeScreens = this.getActiveScreens();
		activeScreens = activeScreens.filter((value: string) => value !== this._screenId);

		if (activeScreens.length) {
			window.localStorage.setItem(this._activeScreensKey, JSON.stringify(activeScreens));
			return;
		}

		window.localStorage.removeItem(this._activeScreensKey);
		window.localStorage.removeItem(this._activeScreensMessageKey);
		this._screenId = null;
	}

	clearLastMessage(): void {
		window.localStorage.removeItem(this._activeScreensMessageKey);
	}

	sendMessage(message: string, payload?: unknown): void {
		const multiScreensMessage: MultiScreensMessage = {
			screenId: this.screenId,
			message: message,
			payload,
		};
		window.localStorage.setItem(this._activeScreensMessageKey, JSON.stringify(multiScreensMessage));
	}

	refreshScreenIdWhenNewScreen$(): Observable<MultiScreensMessage> {
		return this.getOtherScreensMessageByType$(this._refreshScreenIdsMessage).pipe(
			tap(() => {
				const activeScreens = this.getActiveScreens();
				if (!activeScreens.includes(this.screenId)) {
					activeScreens.push(this._screenId);
				}
				window.localStorage.setItem(this._activeScreensKey, JSON.stringify(activeScreens));
			})
		);
	}

	getOtherScreensMessage$(): Observable<MultiScreensMessage> {
		return fromEvent<StorageEvent>(window, 'storage').pipe(
			filter((event: StorageEvent) => event.key === this._activeScreensMessageKey && event.newValue !== null),
			map((event: StorageEvent) => JSON.parse(event.newValue) as MultiScreensMessage),
			filter((multiScreensMessage: MultiScreensMessage) => !!multiScreensMessage && multiScreensMessage.screenId !== this.screenId)
		);
	}

	getOtherScreensMessageByType$(message: string): Observable<MultiScreensMessage> {
		return this.getOtherScreensMessage$().pipe(filter((multiScreensMessage: MultiScreensMessage) => multiScreensMessage.message === message));
	}

	private getActiveScreens(): string[] {
		const activeScreenStr: string = window.localStorage.getItem(this._activeScreensKey);
		return activeScreenStr ? JSON.parse(activeScreenStr) : [];
	}
}
