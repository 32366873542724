import { Injectable } from '@angular/core';
import { UserService } from '@fitech-workspace/auth-lib';
import { KeyVal } from '@fitech-workspace/core-lib';
import { ClientDto } from '@fitech-workspace/master-data-lib';
import { asEnumerable } from 'linq-es2015';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MasterClientsService } from '../features/master-clients/services/master-clients.service';

@Injectable({
	providedIn: 'root',
})
export class GlobalService {
	private _clientsAll: ClientDto[];

	get clients(): ClientDto[] {
		if (this._clientsAll && this._userService.currentUser) {
			const allowedIds: number[] = this._userService.userAllowedClientsWithChildClientIds;
			return this._clientsAll.filter((client: ClientDto): boolean => allowedIds.includes(client.id));
		}

		return null;
	}

	get clientOptions(): KeyVal[] {
		return this.clients?.map((client: ClientDto): KeyVal => ({ value: client.name, key: client.id })) ?? [];
	}

	get isClientsLoaded(): boolean {
		return !!this._clientsAll;
	}

	constructor(private _userService: UserService, private _masterClients: MasterClientsService) {}

	getClients(): Observable<ClientDto[]> {
		if (this.isClientsLoaded) {
			return of(this._clientsAll);
		}

		return this.refreshClients(false);
	}

	refreshClients(refreshCache: boolean = true): Observable<ClientDto[]> {
		return this._masterClients.getClientsFiltered(undefined, undefined, refreshCache).pipe(
			tap((result: ClientDto[]) => {
				this._clientsAll = result;
			})
		);
	}

	clearClients(): void {
		this._clientsAll = null;
	}

	updateClients(clients: ClientDto[]): void {
		this._clientsAll = clients;
	}

	getClientWithChildren(clientId: number): number[] {
		return asEnumerable(this._clientsAll)
			.Where((c: ClientDto) => c.id === clientId)
			.SelectMany((c: ClientDto) => c.relatedClientIdsDown)
			.ToArray();
	}
}
