import { ICellRendererParams } from '@ag-grid-community/core';

export class CellRendererBuilder {
	static getIconCellRenderer(visible: boolean, icon: string = 'fa-check', title: string = ''): string {
		return `<i class="fas ${icon} ${title && 'grey-tooltip'}" style="visibility: ${visible ? 'visible' : 'hidden'}" title="${title}"></i>`;
	}

	static getIconMarker(isAdded: boolean, includeTitle: boolean = true): string {
		return isAdded
			? `<span ${includeTitle ? 'title="Remove sensor from floorplan"' : ''}
        class="fa-stack fa-2x grey-tooltip icon icon--fs-045r">
        <i class="fas fa-map-marker-alt fa-stack-2x"></i>
        <i class="fas fa-slash fa-stack-2x grid-action-element remove-marker"></i>
        </span>`
			: `<i ${includeTitle ? 'title="Place sensor on floorplan"' : ''}
        class="fas fa-map-marker-alt grid-action-element add-marker grey-tooltip icon icon--fs-095r icon--pl-3 icon--active-color">
        </i>`;
	}

	static getIconEdit(): string {
		return `<i title="Go to item configuration" class="far fa-edit grid-action-element edit-marker grey-tooltip icon icon--fs-1r"></i>`;
	}

	static getSquareColor(color: string): string {
		return `<div style="width: 25px; height: 25px; border: 2px solid #000; background-color: ${color}"></div>`;
	}

	static getIconPlusMinus(isAdded: boolean): string {
		return `<i class="grid-action-element select-item ${!isAdded ? 'fas fa-plus' : 'fas fa-minus'}"></i>`;
	}

	static isInitialInfiniteRow(params: ICellRendererParams): boolean {
		return typeof params.data === 'undefined';
	}

	static getInfiniteScrollCell(params: ICellRendererParams): string {
		if (params.node?.failedLoad) {
			return '<i class="fas fa-exclamation-triangle"></i>';
		}
		if (this.isInitialInfiniteRow(params)) {
			return '<i class="fas fa-spinner fa-spin"></i>';
		}
		return params.value;
	}

	static getImage(imgUri: string, imgMaxHeight: number, imgMaxWidth: number, altText: string): string {
		return imgUri ? `<img style="max-height:${imgMaxHeight}px; max-width:${imgMaxWidth}px;" src="${imgUri}" alt="${altText}"></img>` : '';
	}
}
