import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpParamsBuilder } from '@fitech-workspace/core-lib';
import { Alarm } from '@fitech-workspace/fisense-common-lib';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CM_BACKEND_API_URL } from './master-data-api.service';
import { AlarmCountByDeviceDto } from '../models/alarms/alarm-count-by-device-dto.model';

@Injectable({
	providedIn: 'root',
})
export class AlarmsApiService {
	private get _mainUrl(): string {
		return `${this._apiUrl}alarms`;
	}

	constructor(private _httpClient: HttpClient, @Inject(CM_BACKEND_API_URL) private _apiUrl: string) {}

	getAlarms(
		from: Date,
		to: Date,
		locationId?: number,
		sensorId?: number,
		deviceImeis?: string[],
		items?: string[],
		sensorsGroupIds?: number[]
	): Observable<Alarm[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create()
			.append('from', moment(from).toISOString())
			.append('to', moment(to).toISOString())
			.append('locationId', locationId)
			.append('sensorId', sensorId)
			.appendArray('deviceImeis', deviceImeis)
			.appendArray('items', items)
			.appendArray('sensorsGroupIds', sensorsGroupIds);

		return this._httpClient.get<Alarm[]>(`${this._mainUrl}/history`, { params: httpParamsBuilder.build() }).pipe(
			map((res: Alarm[]) => {
				return res.map((data: Alarm) => new Alarm(data));
			})
		);
	}

	getAlarmCount(from: Date, to: Date, lineId: number, frequency: string): Observable<AlarmCountByDeviceDto[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create()
			.append('from', moment(from).toISOString())
			.append('to', moment(to).toISOString())
			.append('lineId', lineId)
			.append('frequency', frequency);

		return this._httpClient.get<AlarmCountByDeviceDto[]>(`${this._mainUrl}/count`, { params: httpParamsBuilder.build() });
	}
}
