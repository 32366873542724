import { Component, Input } from '@angular/core';
import { ControlContainer, FormBuilder, FormControl, FormGroupDirective, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@fitech-workspace/core-lib';
import { QuestionBase } from '../../models';

@Component({
	selector: 'fitech-workspace-dynamic-form-array',
	templateUrl: './dynamic-form-array.component.html',
	styleUrls: ['./dynamic-form-array.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DynamicFormArrayComponent {
	@Input() question: QuestionBase<any>;
	@Input() formArrayName = 'parameters';
	@Input() form: UntypedFormGroup;
	@Input() arrayLabel = 'Parameters';
	@Input() addButtonText = 'Add Item';

	get formArray(): UntypedFormArray {
		return this.form.get(this.formArrayName) as UntypedFormArray;
	}

	constructor(private _formBuilder: FormBuilder) {}

	addParameter(): void {
		const fg = this._formBuilder.group({
			key: new FormControl('', {
				nonNullable: true,
				validators: [Validators.required, CustomValidators.noWhitespaceOnly()],
			}),
			value: new FormControl('', {
				nonNullable: true,
				validators: [Validators.required, CustomValidators.noWhitespaceOnly()],
			}),
		});

		this.formArray.push(fg);
	}

	removeParameter(index: number): void {
		this.formArray.removeAt(index);
	}
}
