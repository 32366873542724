<h1 mat-dialog-title>{{ title | translate }}</h1>
<mat-list style="height: 400px">
	<div style="margin: 6px">
		<mat-accordion *ngFor="let log of logs">
			<mat-expansion-panel>
				<mat-expansion-panel-header class="history-flex-container">
					<mat-panel-title id="history-flex-item">
						<div id="history-type">{{ log.type | translate }}:</div>
						<div id="history-value">
							{{ log.description && log.description.length > 50 ? log.description.substring(0, 50) + '...' : log.description }}
						</div>
					</mat-panel-title>
					<div id="history-date-flex-item">
						{{ log.dateUtc }}
					</div>
				</mat-expansion-panel-header>
				<div class="box">
					<div id="history-label-expanded">{{ 'Description' | translate }}:</div>
					<div>
						{{ log.description }}
					</div>
				</div>
				<div class="box">
					<div id="history-label-expanded">{{ 'User' | translate }}:</div>
					<div>
						{{ log.user }}
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</mat-list>

<div mat-dialog-actions align="end">
	<button class="btn btn--tertiary" (click)="onCancel()">{{ 'Cancel' | translate }}</button>
</div>
