import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { QuestionBase } from '../models/question-base';

@Injectable()
export class QuestionControlService {
	toFormGroup(questionsArray: QuestionBase<any>[]): UntypedFormGroup {
		const group: any = {};

		questionsArray.forEach((question: QuestionBase<any>) => {
			if (question.required) {
				question.validators.push(Validators.required);
			}

			if (question.controlType === 'formarray') {
				const formArray = new UntypedFormArray([], { validators: question.validators });

				if (question.value) {
					question.value.forEach((item: any) => {
						formArray.push(this.createFormGroupForArray(item));
					});
				}

				group[question.key] = formArray;
			} else {
				group[question.key] = question.customControlInit
					? question.customControlInit()
					: new UntypedFormControl({ value: question.value != null ? question.value : '', disabled: question.disabled }, question.validators);
			}

			if (question.onChange) {
				group[question.key].valueChanges.subscribe((value: any) => {
					question.onChange(value);
				});
			}
		});

		return new UntypedFormGroup(group);
	}

	toArray(questions: any): QuestionBase<any>[] {
		return Object.keys(questions).map((questionKey: string): QuestionBase<any> => questions[questionKey]);
	}

	private createFormGroupForArray(item: any): UntypedFormGroup {
		return new UntypedFormGroup({
			key: new UntypedFormControl(item.key || '', Validators.required),
			value: new UntypedFormControl(item.value || '', Validators.required),
		});
	}
}
