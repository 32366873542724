export enum AppNavLinkTitleEnum {
	Reports = 'Reports',
	Monitoring = 'Monitoring',
	Alarms = 'Alarms',
	Management = 'Management',
	Recipes = 'Recipes',
	UpdatesManagement = 'Updates Management',
	Components = 'Components',
	Dashboard = 'Dashboard',
}
