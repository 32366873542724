import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CheckboxQuestion } from '../../models/question-checkbox';

@Component({
	selector: 'fitech-workspace-checkbox-md',
	templateUrl: './dynamic-form-checkbox-input.component.html',
	styleUrls: ['./dynamic-form-checkbox-input.component.scss'],
})
export class DynamicFormCheckboxInputComponent {
	@Input() question: CheckboxQuestion;
	@Input() form: UntypedFormGroup;
	@Input() headerLabel: string;
	@Input() showUndoButton: boolean;

	get isValid(): boolean {
		return this.form.controls[this.question.key].valid;
	}

	getValue(): string | boolean {
		return this.question.type === 'number' ? this.question.value === '1' : this.question.value;
	}

	undo(): void {
		this.form.controls[this.question.key].setValue(this.question.value);
		this.form.controls[this.question.key].markAsPristine();
		this.form.controls[this.question.key].markAsUntouched();
	}

	setInitial(): void {
		this.form.controls[this.question.key].setValue(+this.getValue());
	}
}
