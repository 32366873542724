import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AsEnumerable } from 'linq-es2015';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DropdownOption } from '../../models';
import { DropdownQuestion } from '../../models/question-dropdown';

@Component({
	selector: 'fitech-workspace-autocomplete-md',
	templateUrl: './autocomplete-md.component.html',
	styleUrls: ['./autocomplete-md.component.scss'],
})
export class AutocompleteMdComponent implements OnInit {
	@Input() question: DropdownQuestion;
	@Input() form: UntypedFormGroup;
	@Input() headerLabel: string;
	@Input() showUndoButton: boolean;

	filteredOptions: Observable<DropdownOption[]>;

	ngOnInit(): void {
		this.form.controls[this.question.key].valueChanges.subscribe((x: any) => this.onValueChanged(x));
		this.setOptions();
		this.question.optionsHasChanged.subscribe(() => {
			this.refreshOptions();
		});
	}

	setOptions(): Observable<DropdownOption[]> {
		return (this.filteredOptions = this.form.controls[this.question.key].valueChanges.pipe(
			startWith(''),
			map((value: any) => {
				return AsEnumerable(this.question.options)
					.Where((x: DropdownOption) => {
						const val = this.getInputValueAsString(value);
						return x.value && x.value.toLowerCase().includes(val.toLowerCase());
					})
					.ToArray();
			})
		));
	}

	refreshOptions(): void {
		this.setOptions().subscribe();
		this.validateIfValueIsInData(this.form.controls[this.question.key].value);
	}

	onValueChanged(value: any): void {
		if (value != null) {
			const result = this.validateIfValueIsInData(value);
			if (result && typeof value === 'string') {
				this.setValue(value);
			}
			this.question.icon = value.icon ? value.icon : undefined;
		}
	}

	generateDisplayText(item: any): any {
		if (item) {
			return item.display || item.value;
		}
	}

	undo(): void {
		this.form.controls[this.question.key].setValue(this.question.value);
		this.form.controls[this.question.key].markAsPristine();
		this.form.controls[this.question.key].markAsUntouched();
	}

	private setValue(value: any): void {
		const valueToSet = AsEnumerable(this.question.options)
			.Where((x: DropdownOption) => x.value.toLowerCase() === (value || '').toLowerCase())
			.FirstOrDefault();
		if (valueToSet) {
			this.form.controls[this.question.key].setValue(valueToSet);
		}
	}

	private validateIfValueIsInData(value: any): boolean {
		const val = this.getInputValueAsString(value);
		const isError = !AsEnumerable(this.question.options).Any((x: DropdownOption) => x.value.toLowerCase() === (val || '').toLowerCase());
		if (isError) {
			this.form.controls[this.question.key].setErrors({ incorrect: true });
		} else {
			this.form.controls[this.question.key].setErrors(null);
		}
		return !isError;
	}

	private getInputValueAsString(value: any): string {
		let val = '';
		if (value && typeof value === 'string') {
			val = value;
		} else if (value) {
			val = value.value;
		}
		return val;
	}
}
