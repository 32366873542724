import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import { NotificationService } from '@fitech-workspace/core-lib';
import { timer } from 'rxjs';
import { LoginForm } from '../../models';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'fitech-workspace-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	@Input() logoSrc: string;
	@Input() logoAdSrc: string;
	@Input() sigInAccountEnabled = true;
	@Input() sigInAdEnabled = true;

	returnUrl: any;
	$counter: any;
	secondsToRedirect: number;
	isInProgress: boolean;
	loginForm: FormGroup<LoginForm>;
	loginInput: string;
	passwordInput: string;
	isSubmitting = false;
	isSubmittingAd = false;
	isSubmitted = false;
	isAdLoginSelected: boolean;

	get usernameControl(): AbstractControl<string> {
		return this.loginForm.get('username');
	}

	get passwordControl(): AbstractControl<string> {
		return this.loginForm.get('password');
	}

	constructor(
		private _notificationService: NotificationService,
		private _formBuilder: FormBuilder,
		private _userService: UserService,
		private _activatedRoute: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.createFormGroups(this._formBuilder);
		this._activatedRoute.data.subscribe((data: Data) => {
			if (data != null) {
				if (data.sigInAdEnabled != null) {
					this.sigInAdEnabled = data.sigInAdEnabled;
					this.isAdLoginSelected = this.sigInAdEnabled;
				}
				if (data.sigInAccountEnabled != null) {
					this.sigInAccountEnabled = data.sigInAccountEnabled;
				}
				if (data.logoSrc != null) {
					this.logoSrc = data.logoSrc;
				}
				if (data.logoAdSrc != null) {
					this.logoAdSrc = data.logoAdSrc;
				}
			}
		});
	}

	//for ionic app
	ionViewWillEnter(): void {
		this.isSubmitted = false;
	}

	createFormGroups(fb: FormBuilder): void {
		this.loginForm = fb.group({
			username: new FormControl<string>(''),
			password: new FormControl<string>(''),
		});

		this.usernameControl.setValidators(Validators.required);
		this.passwordControl.setValidators(Validators.required);
	}

	shouldDisableAction(): boolean {
		return this.isSubmitting || this.isSubmitted || this.usernameControl.invalid || this.passwordControl.invalid;
	}

	login(): void {
		this.isSubmitting = true;
		this._userService.loginWith(this.usernameControl.value, this.passwordControl.value).subscribe(
			() => {
				this.isSubmitting = false;
				this.isSubmitted = true;
			},
			(err: any) => {
				this.isSubmitting = false;
				let errMsg = 'Bad login attempt.';
				if (err.error && err.error.error_description) {
					errMsg = err.error.error_description;
				}
				this._notificationService.showError(errMsg);
			}
		);
	}

	loginAd(): void {
		this.isSubmittingAd = true;
		this._userService.loginWithAzureAd();
	}

	selectAdLogin(isAdLoginSelected: boolean): void {
		this.isAdLoginSelected = isAdLoginSelected;
	}

	keyDownFunction(event: any): void {
		if (event.keyCode === 13) {
			this.login();
		}
	}

	isValid(key: any): boolean {
		return this.loginForm.controls[key].valid;
	}

	isTouched(key: any): boolean {
		return this.loginForm.controls[key].touched;
	}

	getErrorMsg(key: any): string {
		let errorMsg = '';
		if (this.isTouched(key)) {
			if (this.loginForm.controls[key].hasError('required')) {
				errorMsg += 'Field is required. ';
			}
		}
		return errorMsg;
	}

	private autoRedirect(): void {
		this.secondsToRedirect = 15;
		const source = timer(1000, 1000);
		const subscribe = source.subscribe(() => {
			this.secondsToRedirect--;
			if (this.secondsToRedirect <= 0) {
				this.login();
			}
		});
	}
}
