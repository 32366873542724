import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
	selector: 'fitech-workspace-link-renderer',
	templateUrl: './link-renderer.component.html',
	styleUrls: ['./link-renderer.component.scss'],
})
export class LinkRendererComponent implements ICellRendererAngularComp {
	params: any;
	data: ILinkData;

	refresh(params: any): boolean {
		return true;
	}

	agInit(params: any): void {
		this.params = params;
		this.data = params.data.getLinkData(params.colDef.field, params.buttonIcon, params.tooltip);
	}

	afterGuiAttached?(params?: any): void {
		this.params = params;
	}
}

export interface ILinkData {
	button: string;
	tooltip: string;
	link: any;
}

export interface ILinkDataProvider {
	getLinkData(field: string, buttonIcon: string, tooltip: string): ILinkData;
}
