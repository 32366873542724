import { Component, OnInit, ViewChild } from '@angular/core';
import { EnableStatesEnum, IChartConfig, LineChartComponent, LineChartConfigBuilder } from '@fitech-workspace/shared/ui/chart-lib';

@Component({
	selector: 'fitech-workspace-sensors-chart',
	templateUrl: './sensors-chart.component.html',
	styleUrls: ['./sensors-chart.component.scss'],
})
export class SensorsChartComponent implements OnInit {
	@ViewChild('chart', { static: true }) lineChart: LineChartComponent;

	lineChartConfig: IChartConfig;

	ngOnInit(): void {
		this.initializeLineChart();
	}

	private initializeLineChart(): void {
		this.lineChartConfig = {
			autoRefreshChartData: true,
			showFrequency: true,
			showStartStopRefreshData: true,
			minMaxSeries: EnableStatesEnum.EnabledAndSetOff,
			chartOptions: new LineChartConfigBuilder()
				.setDefault()
				.setCustom({ toolbox: { show: false } })
				.build(),
		};

		this.lineChart.initialize(this.lineChartConfig);
	}
}
