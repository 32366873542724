import { Injectable } from '@angular/core';
import { GlobalUserSettingDto, GroupedUserSettings, UserSettingBaseDto, UserSettingDto, UserSettingsFacadeBaseService } from '@fitech-workspace/core-lib';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';
import * as userSettingsActions from '../actions/user-settings.actions';
import * as userSettingsSelectors from '../selectors/user-settings.selectors';

@Injectable({
	providedIn: 'root',
})
export class UserSettingsFacadeService implements UserSettingsFacadeBaseService {
	isLocalUserSettingsLoaded$: Observable<boolean> = this._store.pipe(select(userSettingsSelectors.selectIsLocalUserSettingsLoaded));
	globalUserSettings$: Observable<UserSettingBaseDto[]> = this._store.pipe(select(userSettingsSelectors.selectGlobalUserSettings));
	groupedLocalUserSettings$: Observable<GroupedUserSettings[]> = this._store.pipe(select(userSettingsSelectors.selectGroupedLocalUserSettings));
	batchedUserSettings$: Observable<UserSettingDto[]> = this._store.pipe(select(userSettingsSelectors.selectBatchedUserSettings));
	isBatchedUserSettings$: Observable<boolean> = this._store.pipe(select(userSettingsSelectors.selectIsBatchedUserSettings));

	constructor(private readonly _store: Store) {}

	// Selectors
	globalUserSetting$(name: string): Observable<GlobalUserSettingDto> {
		return this._store.pipe(select(userSettingsSelectors.selectGlobalUserSetting(name)));
	}

	localUserSettings$(view: string, section?: string, element?: string): Observable<UserSettingDto[]> {
		return this._store.pipe(
			select(userSettingsSelectors.selectLocalUserSettings(view, section, element)),
			withLatestFrom(this.isLocalUserSettingsLoaded$),
			filter((value: [UserSettingDto[], boolean]) => value[1]),
			map((value: [UserSettingDto[], boolean]) => value[0])
		);
	}

	isProcessingLocalUserSettings$(view: string, section?: string, element?: string): Observable<boolean> {
		return this._store.pipe(
			select(userSettingsSelectors.selectIsProcessingLocalUserSettings(view, section, element)),
			distinctUntilChanged()
		)
	}

	// Actions
	getLocalUserSettings(): void {
		this._store.dispatch(userSettingsActions.getUserSettings({}));
	}

	getGlobalUserSettings(): void {
		this._store.dispatch(userSettingsActions.getGlobalUserSettings({}));
	}

	createGlobalUserSetting(globalUserSetting: UserSettingBaseDto): void {
		this._store.dispatch(userSettingsActions.createGlobalUserSetting({ globalUserSetting }));
	}

	updateGlobalUserSetting(globalUserSetting: UserSettingBaseDto): void {
		this._store.dispatch(userSettingsActions.updateGlobalUserSetting({ globalUserSetting }));
	}

	upsertBatchUserSettings(userSettings: UserSettingDto[]): void {
		this._store.dispatch(userSettingsActions.upsertBatchUserSettings({ userSettings }));
	}

	deleteUserSetting(userSettingId: number): void {
		this._store.dispatch(userSettingsActions.deleteUserSettingById({ userSettingId }));
	}

	deleteUserSettingByName(userSettingName: string, view?: string, section?: string, element?: string): void {
		this._store.dispatch(userSettingsActions.deleteUserSettingByName({ userSettingName, view, section, element }));
	}

	deleteAllUserSettings(): void {
		this._store.dispatch(userSettingsActions.deleteAllUserSettings());
	}

	pushBatchUserSettings(userSettings: UserSettingDto[]): void {
		this._store.dispatch(userSettingsActions.pushBatchedUserSettings({ userSettings }));
	}

	pushBatchUserSetting(userSetting: UserSettingDto): void {
		this._store.dispatch(userSettingsActions.pushBatchedUserSettings({ userSettings: [userSetting] }));
	}

	checkBatchedUserSettings(): void {
		this._store.dispatch(userSettingsActions.checkBatchedUserSettings());
	}
}
