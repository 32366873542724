<div id="bars" class="box">
	<div class="box-child box-child-1" id="auth-box">
		<img id="logo" class="img-fluid logo" src="{{ logoSrc }}" />
	</div>
	<div class="box-child box-child-2">
		<div id="loginAd" *ngIf="sigInAdEnabled && isAdLoginSelected">
			<img id="logo-ad" src="{{ logoAdSrc }}" />
			<button id="button-ad" class="submit-btn" mat-stroked-button (click)="loginAd()">
				{{ 'Log in with Active Directory' | translate }}
				<i *ngIf="isSubmittingAd" class="fas fa-sync fa-spin refresh-spinner"></i>
			</button>
			<button class="back-btn" mat-button (click)="selectAdLogin(false)" *ngIf="sigInAccountEnabled">
				{{ 'Login using application account' | translate }}
			</button>
			<hr />
		</div>
		<form [formGroup]="loginForm" class="form-container" (ngSubmit)="login()">
			<div id="logginAccount" *ngIf="sigInAccountEnabled && !isAdLoginSelected">
				<div>
					<mat-form-field>
						<mat-label>{{ ('User name' | translate) || 'User name' }}</mat-label>
						<input formControlName="username" matInput name="loginInput" />
					</mat-form-field>
					<div class="errorMessage" *ngIf="getErrorMsg('username').length > 0">
						{{ (getErrorMsg('username') | translate) || getErrorMsg('username') }}
					</div>
				</div>
				<div>
					<mat-form-field>
						<mat-label>{{ ('Password' | translate) || 'Password' }}</mat-label>
						<div class="pass-box">
							<input formControlName="password" matInput type="password" name="password" (keydown)="keyDownFunction($event)" password />
						</div>
					</mat-form-field>
					<div class="errorMessage" *ngIf="getErrorMsg('password').length > 0">
						{{ (getErrorMsg('password') | translate) || getErrorMsg('password') }}
					</div>
				</div>
				<button class="submit-btn" mat-stroked-button (click)="login()" [disabled]="shouldDisableAction()">
					{{ ('Log in' | translate) || 'Log in' }}
					<i *ngIf="isSubmitting" class="fas fa-sync fa-spin refresh-spinner"></i>
					<i *ngIf="isSubmitted" class="fas fa-check"></i>
				</button>
				<div>
					<button class="back-btn" mat-button routerLink="/passwordforgot">
						{{ 'Forgot password?' | translate }}
					</button>
				</div>
				<div>
					<button class="back-btn" mat-button (click)="selectAdLogin(true)" *ngIf="sigInAdEnabled">
						{{ 'Login using Active Directory' | translate }}
					</button>
				</div>
			</div>
		</form>
	</div>
</div>
