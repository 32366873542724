<div id="bars" class="box">
	<div class="box-child box-child-1" id="auth-box">
		<img class="img-fluid logo" src="{{ logoSrc }}" />
	</div>
	<div class="box-child box-child-2">
		<form [formGroup]="setPasswordForm" class="form-container" (ngSubmit)="setPassword()">
			<div>
				<h4>Set password</h4>
			</div>
			<div>
				<mat-form-field>
					<mat-label>{{ 'Email' | translate }}</mat-label>
					<input formControlName="email" matInput name="emailInput" />
				</mat-form-field>
				<div class="errorMessage" *ngIf="getErrorMsg('email').length > 0">
					{{ getErrorMsg('email') | translate }}
				</div>
				<mat-form-field>
					<mat-label>{{ 'Password' | translate }}</mat-label>
					<div class="pass-box">
						<input formControlName="newPassword" matInput type="password" (keydown)="keyDownFunction($event)" password />
					</div>
				</mat-form-field>
				<div class="errorMessage" *ngIf="getErrorMsg('newPassword').length > 0">
					{{ getErrorMsg('newPassword') | translate }}
				</div>
			</div>
			<div>
				<mat-form-field>
					<mat-label>{{ 'Confirm password' | translate }}</mat-label>
					<div class="pass-box">
						<input formControlName="confirmPassword" matInput type="password" (keydown)="keyDownFunction($event)" password />
					</div>
				</mat-form-field>
				<div class="errorMessage" *ngIf="getErrorMsg('confirmPassword').length > 0">
					{{ getErrorMsg('confirmPassword') | translate }}
				</div>
			</div>
			<button mat-stroked-button type="submit" class="submit-btn"  color="primary" [disabled]="shouldDisableAction()">
				{{ 'Set password' | translate }}
				<i *ngIf="isSubmitting" class="fas fa-sync fa-spin refresh-spinner"></i>
				<i *ngIf="isSubmitted" class="fas fa-check"></i>
			</button>
			<div>
				<button class="back-btn" mat-button color="primary" routerLink="/login">
					<i class="fas fa-chevron-left"></i>
					{{ 'Go to Login' | translate }}
				</button>
			</div>
		</form>
	</div>
</div>
