import { Injectable } from '@angular/core';
import { DefaultMapMarkerFactory, IMapMarkerFactory } from '@fitech-workspace/fisense-common-lib';
import { IMapMarkerDataV2 } from '@fitech-workspace/shared/ui/map-lib';
import { ThtMachineMarker } from '../helpers/tht-machine.marker';

@Injectable()
export class CmMapMarkerFactoryService extends DefaultMapMarkerFactory implements IMapMarkerFactory {
	constructor() {
		super();
	}

	getMarker(data: any, type: string = null): IMapMarkerDataV2 {
		if (type == 'machine') {
			return new ThtMachineMarker(data);
		} else {
			return super.getMarker(data);
		}
	}
}
