import { NgModule } from '@angular/core';
import { CoreLibModule } from '@fitech-workspace/core-lib';
import { SharedUiInputsModule } from '@fitech-workspace/shared/ui/controls-lib';
import { MomentModule } from 'ngx-moment';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { GanttChartComponent } from './components/gantt-chart/gantt-chart.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';

@NgModule({
	imports: [SharedUiInputsModule, CoreLibModule, MomentModule],
	declarations: [LineChartComponent, BarChartComponent, GanttChartComponent],
	exports: [LineChartComponent, BarChartComponent, GanttChartComponent],
})
export class SharedUiChartLibModule {}
