<div class="md-chips">
	<div *ngFor="let item of data" class="md-chip">
		<div class="md-chip-container">
			<button mat-icon-button color="accent" class="md-chip-btn" matTooltip="{{ item.tooltip | translate }}: {{ item.id }}" (click)="onClick(item)">
				<fitech-workspace-icon id="md-chip-btn-icon" [iconKey]="item.icon"></fitech-workspace-icon>
			</button>
		</div>
		{{ item.value }}
	</div>
</div>
