import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorHandlingService, NotificationService } from '@fitech-workspace/core-lib';
import { MasterUsersService, ResetPasswordCmd } from '@fitech-workspace/master-data-lib';
import { ResetPasswordForm } from '../../models';
import { AUTH_ENV_CONFIG, UserService } from '../../services/user.service';

@Component({
	selector: 'fitech-workspace-password-reset',
	templateUrl: './password-reset.component.html',
	styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
	@Input() logoSrc: string;

	isInProgress: boolean;
	resetPasswordForm: FormGroup<ResetPasswordForm>;
	isSubmitting = false;
	isSubmitted = false;

	private _environmentConfig;

	get currentPasswordControl(): AbstractControl<string> {
		return this.resetPasswordForm.get('currentPassword');
	}

	get newPasswordControl(): AbstractControl<string> {
		return this.resetPasswordForm.get('newPassword');
	}

	get confirmPasswordControl(): AbstractControl<string> {
		return this.resetPasswordForm.get('confirmPassword');
	}

	constructor(
		@Inject(AUTH_ENV_CONFIG) environmentConfig: any,
		private _notificationService: NotificationService,
		private _formBuilder: FormBuilder,
		private _userService: UserService,
		private _masterUsersService: MasterUsersService,
		private _errorHandlingService: ErrorHandlingService
	) {
		this._environmentConfig = environmentConfig;
		if (this._environmentConfig) {
			this.logoSrc = this._environmentConfig.logoSrc;
		}
	}

	ngOnInit(): void {
		this.createFormGroups(this._formBuilder);
	}

	createFormGroups(fb: FormBuilder): void {
		this.resetPasswordForm = fb.group(
			{
				currentPassword: new FormControl<string>(''),
				newPassword: new FormControl<string>(''),
				confirmPassword: new FormControl<string>(''),
			},
			{ validator: this.checkPasswords }
		);

		this.currentPasswordControl.setValidators(Validators.required);
		this.newPasswordControl.setValidators([Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,100}')]);
	}

	checkPasswords(form: FormGroup): any {
		const pass = form.controls.newPassword.value;
		const confirmPass = form.controls.confirmPassword.value;

		let result = null;
		if (pass !== confirmPass) {
			form.controls.confirmPassword.setErrors({ notSamePassword: true });
			result = { notSamePassword: true };
		}
		return result;
	}

	shouldDisableAction(): boolean {
		return (
			this.isSubmitting ||
			this.isSubmitted ||
			this.currentPasswordControl.invalid ||
			this.newPasswordControl.invalid ||
			this.confirmPasswordControl.invalid
		);
	}

	resetPassword(): void {
		this.isSubmitting = true;
		this._masterUsersService.resetPassword(this.createResetPasswordReq()).subscribe(
			() => {
				this.isSubmitting = false;
				this.isSubmitted = true;
				this._notificationService.showSuccess('Password was reset.');
			},
			(error: HttpErrorResponse) => {
				this.isSubmitting = false;
				this._errorHandlingService.handleError(error, 'Password was not reset. Please check does current password is correct.');
			}
		);
	}

	createResetPasswordReq(): ResetPasswordCmd {
		const resetPasswordReq: ResetPasswordCmd = {
			userId: this._userService.currentUser.id,
			oldPassword: this.currentPasswordControl.value,
			newPassword: this.newPasswordControl.value,
			confirmPassword: this.confirmPasswordControl.value,
		};
		return resetPasswordReq;
	}

	keyDownFunction(event: any): void {
		if (event.keyCode === 13) {
			this.resetPassword();
		}
	}

	isValid(key: any): boolean {
		return this.resetPasswordForm.controls[key].valid;
	}

	isTouched(key: any): boolean {
		return this.resetPasswordForm.controls[key].touched;
	}

	getErrorMsg(key: any): string {
		let errorMsg = '';
		if (this.isTouched(key)) {
			if (this.resetPasswordForm.controls[key].hasError('required')) {
				errorMsg += 'Field is required. ';
			}
			if (this.resetPasswordForm.controls[key].hasError('email')) {
				errorMsg += 'Field does not meet e-mail pattern requirement. ';
			}
			if (this.resetPasswordForm.controls[key].hasError('pattern')) {
				errorMsg += 'The password must be at least 6 characters long. Must have lower and upper case and digit.';
			}
			if (key === 'confirmPassword' && this.resetPasswordForm.controls[key].parent.hasError('notSamePassword')) {
				errorMsg += 'The password and confirmation password do not match. ';
			}
		}
		return errorMsg;
	}
}
