import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DateUtils } from '@fitech-workspace/core-lib';
import * as plHolidays from '../../../assets/holidays/pl-holidays.json';
import { DateFrequencyEnum } from '../enums';

@Injectable({
	providedIn: 'root',
})
export class WorkingTimeService {
	private _holidays: Map<string, string[]> = new Map<string, string[]>();

	constructor() {
		this.init();
	}

	getWorkingTimeInSeconds(frequency: string, startDate: string | Date, endDate: string | Date): number | null {
		const isEndDateInFuture: boolean = this.isFutureDate(endDate);

		switch (frequency) {
			case DateFrequencyEnum.EveryHour:
				return isEndDateInFuture ? this.getSecondsTillNow(startDate) : this.getSecondsFromHours(1);
			case DateFrequencyEnum.EveryShift:
				return isEndDateInFuture ? this.getSecondsTillNow(startDate) : this.getSecondsFromHours(8);
			case DateFrequencyEnum.EveryDay:
				return isEndDateInFuture ? this.getSecondsTillNow(startDate) : this.getDatesDiffInSeconds(startDate, endDate);
			case DateFrequencyEnum.EveryMonth:
			case DateFrequencyEnum.EveryQuarter:
			case DateFrequencyEnum.EveryWeek:
				return this.getWorkingFullDaysTimeInSeconds(startDate, endDate, isEndDateInFuture);
			default:
				return null;
		}
	}

	private getDatesDiffInSeconds(startDate: string | Date, endDate: string | Date): number | null {
		if (!startDate || !endDate) {
			return null;
		}
		const startMoment = moment(startDate, DateUtils.InvariantDateFormat);
		if (!this.isWorkingDay(startMoment)) {
			return null;
		}
		const endMoment = moment(endDate, DateUtils.InvariantDateFormat);
		const diff: number = endMoment.diff(startMoment, 'seconds');
		return diff;
	}

	private getWorkingFullDaysTimeInSeconds(startDate: string | Date, endDate: string | Date, isEndDateInFuture: boolean = false): number | null {
		if (!startDate || !endDate) {
			return null;
		}

		const { fullWorkingDays, remainingSeconds } = this.getWorkingDays(startDate, endDate, isEndDateInFuture);
		const secondsInDay: number = this.getSecondsFromHours(24);
		return fullWorkingDays * secondsInDay + remainingSeconds;
	}

	private getWorkingDays(
		startDate: string | Date,
		endDate: string | Date,
		isEndDateInFuture: boolean = false
	): { fullWorkingDays: number; remainingSeconds: number } {
		const startMoment = moment(startDate, DateUtils.InvariantDateOnlyFormat);
		const endMoment = moment(endDate, DateUtils.InvariantDateOnlyFormat);
		if (endMoment.isBefore(startMoment)) {
			return {
				fullWorkingDays: 0,
				remainingSeconds: 0,
			};
		}

		let fullWorkingDays = 0;
		let remainingSeconds = 0;
		const now: moment.Moment = moment();

		for (const currentMoment = moment(startMoment); currentMoment.diff(endMoment, 'days') < 0; currentMoment.add(1, 'days')) {
			const isCurrentInFuture: boolean = isEndDateInFuture && this.isFutureMoment(currentMoment, now);
			const isToday: boolean = isEndDateInFuture && this.isTodayMoment(currentMoment, now);
			fullWorkingDays = !isCurrentInFuture && !isToday && this.isWorkingDay(currentMoment) ? fullWorkingDays + 1 : fullWorkingDays;
			remainingSeconds = isToday ? remainingSeconds + now.diff(currentMoment, 'seconds') : remainingSeconds;
		}

		return {
			fullWorkingDays,
			remainingSeconds,
		};
	}

	private isWorkingDay(date: moment.Moment): boolean {
		return !this.isWeekend(date) && !this.isBankHoliday(date);
	}

	private isBankHoliday(date: moment.Moment): boolean {
		const year = `${date.year()}`;
		if (!this._holidays.has(year)) {
			throw new Error(`File with bank holidays are missing entry for year ${year}.`);
		}

		return this._holidays.get(year).includes(date.format(DateUtils.InvariantDateOnlyFormat)) ?? false;
	}

	private isWeekend(date: moment.Moment): boolean {
		const dayOfWeek: number = date.day();
		return [6, 0].includes(dayOfWeek);
	}

	private getSecondsFromHours(hours: number): number {
		return hours * 60 * 60;
	}

	private getSecondsTillNow(startDate: string | Date): number {
		const startMoment: moment.Moment = moment(startDate, DateUtils.InvariantDateFormat);
		const now: moment.Moment = moment();
		return now.diff(startMoment, 'seconds');
	}

	private isFutureDate(endDate: string | Date): boolean {
		if (!endDate) {
			return false;
		}
		const endMoment: moment.Moment = moment(endDate, DateUtils.InvariantDateFormat);
		const now: moment.Moment = moment();
		return endMoment.isAfter(now, 'seconds');
	}

	private isFutureMoment(currentMoment: moment.Moment, now: moment.Moment): boolean {
		return currentMoment.isAfter(now, 'seconds');
	}

	private isTodayMoment(currentMoment: moment.Moment, now: moment.Moment): boolean {
		const today: string = now.format(DateUtils.InvariantDateOnlyFormat);
		return today === currentMoment.format(DateUtils.InvariantDateOnlyFormat);
	}

	private init(): void {
		// Remark: In order to support multiple files dynamically, it is better to use HttpClient.
		if (!this._holidays.size) {
			Object.keys(plHolidays).forEach((year: string) => {
				this._holidays.set(year, plHolidays[year]);
			});
		}
	}
}
