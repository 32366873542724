<div class="mat-dialog-header">
	<div class="mat-dialog-header-with-button">
		<h5 mat-dialog-title>Versions</h5>
		<fitech-workspace-button-refresh [refreshing]="refreshing" [isSmaller]="true" (refreshed)="refresh()"></fitech-workspace-button-refresh>
	</div>
	<button mat-icon-button (click)="closeModal()">
		<mat-icon>close</mat-icon>
	</button>
</div>
<div mat-dialog-content>
	<div class="versions-content">
		<ng-container *ngTemplateOutlet="version; context: { appLabel: 'Analytics Platform', versionNumber: versions?.analyticsPlatform }"></ng-container>
		<ng-container *ngTemplateOutlet="version; context: { appLabel: 'Frontend API', versionNumber: versions?.frontendApi }"></ng-container>
		<ng-container *ngTemplateOutlet="version; context: { appLabel: 'Processing IoT Hub', versionNumber: versions?.processingIotHub }"></ng-container>
	</div>
</div>
<mat-dialog-actions>
	<button type="button" class="btn btn--tertiary" (click)="closeModal()">Back</button>
</mat-dialog-actions>

<ng-template #version let-appLabel="appLabel" let-versionNumber="versionNumber">
	<span>{{ appLabel }}</span>
	<p class="version-number">
		<strong>{{ versionNumber }}</strong>
	</p>
</ng-template>
