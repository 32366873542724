import { HttpParams } from '@angular/common/http';
import { StringUtils } from '@fitech-workspace/core-lib';

type HttpParamValue = string | number | boolean;

export class HttpParamsBuilder {
	private _params: HttpParams;

	constructor() {
		this._params = new HttpParams();
	}

	static create(): HttpParamsBuilder {
		return new HttpParamsBuilder();
	}

	append(param: string, value: HttpParamValue, rejectDefault: boolean = true): this {
		if (!rejectDefault || (rejectDefault && value)) {
			this._params = this._params.append(param, value);
		}

		return this;
	}

	appendString(param: string, value: string, skipNull: boolean = true): this {
		if (!StringUtils.isNullOrUndefined(value) || !skipNull) {
			this._params = this._params.append(param, value);
		}

		return this;
	}

	appendArray(param: string, array: HttpParamValue[], rejectEmpty: boolean = true): this {
		if (!rejectEmpty || (rejectEmpty && array && array.length > 0)) {
			array?.forEach((value: HttpParamValue) => {
				this._params = this._params.append(param, value);
			});
		}

		return this;
	}

	appendObjectArray(param: string, array: any[], rejectEmpty: boolean = true): this {
		if (!rejectEmpty || (rejectEmpty && array && array.length > 0)) {
			array?.forEach((value: any) => {
				this._params = this._params.append(param, JSON.stringify(value));
			});
		}

		return this;
	}

	build(): HttpParams {
		return this._params;
	}
}
