<ag-grid-angular
	class="ag-theme-balham grid-base"
	[rowData]="rowData"
	[columnDefs]="columnDefs"
	[defaultColDef]="defaultColDef"
	[gridOptions]="gridOptions"
	[suppressMovableColumns]="true"
	[components]="frameworkComponents"
	[overlayLoadingTemplate]="overlayLoadingTemplate"
	(gridReady)="onGridReady($event)"
	(rowClicked)="onRowClicked($event)">
</ag-grid-angular>
