<div id="bars" class="box">
	<div class="box-child box-child-1" id="auth-box">
		<img class="img-fluid logo" src="{{ logoSrc }}" />
	</div>
	<div class="box-child box-child-2">
		<form [formGroup]="remindPasswordForm" class="form-container" (ngSubmit)="forgotPassword()">
			<div>
				<h4>Forgot password?</h4>
				<p>Please enter your email and you will get a link to set new password</p>
			</div>
			<div>
				<mat-form-field>
					<mat-label>{{ 'Email' | translate }}</mat-label>
					<input formControlName="email" matInput name="emailInput" />
				</mat-form-field>
				<div class="errorMessage" *ngIf="getErrorMsg('email').length > 0">
					{{ getErrorMsg('email') | translate }}
				</div>
			</div>
			<button mat-stroked-button type="submit" class="submit-btn"  color="primary"  [disabled]="shouldDisableAction()">
				{{ 'Submit' | translate }}
				<i *ngIf="isSubmitting" class="fas fa-sync fa-spin refresh-spinner"></i>
				<i *ngIf="isSubmitted" class="fas fa-check"></i>
			</button>
			<div>
				<button class="back-btn" mat-button color="primary" routerLink="/login">
					<i class="fas fa-chevron-left"></i>
					{{ 'Go back to Login' | translate }}
				</button>
			</div>
		</form>
	</div>
</div>
