<aside
	*ngIf="!sidePanelDisabled"
	class="side-panel side-panel--left"
	[ngClass]="{
		open: sidePanelPosition === 1,
		'open-short': sidePanelShortOpenEnabled,
		'open-middle': sidePanelMiddleOpenEnabled,
		'open-wide': sidePanelPosition === 2,
		toggling: isSidePanelToggling,
		'hide-print': hidePrint
	}">
	<ng-content select="[side-panel]"></ng-content>
</aside>
<div class="content-panel" [ngClass]="{ 'content-panel--auto-overflow': isContentAutoOverflow }">
	<div class="content-container" [ngClass]="{ closed: sidePanelPosition === 2 && !doNotHideContentOnOpenWide }">
		<ng-content select="[content]"></ng-content>
	</div>
	<div class="side-panel-toggler-container side-panel-toggler-container--left" [ngClass]="{ 'hide-print': hidePrint }">
		<button *ngIf="!sidePanelDisabled && canToggleLeft" class="btn btn--p-default" (click)="toggleSidePanel(true)">
			<i class="fas fa-angle-left to-the-left"></i>
		</button>
		<button *ngIf="!sidePanelDisabled && canToggleRight" class="btn btn--p-default" (click)="toggleSidePanel(false)">
			<i class="fas fa-angle-right to-the-left"></i>
		</button>
	</div>
</div>
