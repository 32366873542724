<div *ngFor="let chip of chips" class="md-chip-container">
	<div *ngIf="chip.value">
		<div class="md-chip-label">
			{{ chip.label }}
		</div>
		<div class="md-chip-value" [ngClass]="chip.cssclass">
			{{ chip.value }}
		</div>
	</div>
</div>
