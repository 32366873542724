import { UserAllowedClient } from './user-clients.model';
import { UserDto } from './user-dto.model';
import { UserRole } from './user-roles.model';

export class User implements UserDto {
	id: number;
	userName: string;
	fullName: string;
	email: string;
	clientId: number;
	clientName: string;
	emailConfirmed: boolean;
	roles: UserRole[] = [];
	lockoutEndDateUtc: Date;
	arch: boolean;
	createdBy: string;
	userAllowedClients: UserAllowedClient[];
	userAllowedClientsWithChildClients: UserAllowedClient[];
	permissions: string[] = [];

	get isActiveDirectoryUser(): boolean {
		return this.createdBy === 'Identity Service';
	}

	constructor(data?: UserDto) {
		if (data) {
			for (const property in data) {
				if (data.hasOwnProperty(property)) {
					(<any>this)[property] = (<any>data)[property];
				}
			}
		}
	}

	init(data?: any): void {
		if (data) {
			this.id = data['id'];
			this.email = data['email'];
			this.clientId = data['clientId'];
			this.clientName = data['clientName'];
			this.emailConfirmed = data['emailConfirmed'];
			this.lockoutEndDateUtc = data['lockoutEndDateUtc'];
			this.createdBy = data['createdBy'];
			if (data['roles'] && data['roles'].constructor === Array) {
				this.roles = [];
				for (const item of data['roles']) {
					this.roles.push(UserRole.fromJS(item));
				}
			}
			if (data['permissions'] && data['permissions'].constructor === Array) {
				this.permissions = [];
				for (const item of data['permissions']) {
					this.permissions.push(item);
				}
			}
			if (data['userAllowedClients'] && data['userAllowedClients'].constructor === Array) {
				this.userAllowedClients = [];
				for (const item of data['userAllowedClients']) {
					this.userAllowedClients.push(UserAllowedClient.fromJS(item));
				}
			}
			if (data['userAllowedClientsWithChildClients'] && data['userAllowedClientsWithChildClients'].constructor === Array) {
				this.userAllowedClientsWithChildClients = [];
				for (const item of data['userAllowedClientsWithChildClients']) {
					this.userAllowedClientsWithChildClients.push(UserAllowedClient.fromJS(item));
				}
			}
		}
	}

	hasRole(role: string): boolean {
		return this.roles.map((x: UserRole) => x.name).includes(role);
	}

	hasPermission(permission: string): boolean {
		return permission ? this.permissions.includes(permission) : false;
	}

	hasAllPermissions(permissions: string[]): boolean {
		return permissions?.length > 0 ? permissions.every((permission: string) => this.permissions.includes(permission)) : false;
	}

	hasAnyPermission(permissions: string[]): boolean {
		return permissions?.length > 0 ? this.permissions.some((permission: string) => permissions.includes(permission)) : false;
	}

	hasModulePermission(modulePermission: string): boolean {
		return modulePermission ? this.permissions.some((permission: string) => permission.startsWith(modulePermission)) : false;
	}

	hasAnyModulePermission(modulePermissions: string[]): boolean {
		return modulePermissions?.length > 0
			? this.permissions.some((permission: string) => modulePermissions.some((modulePermission: string) => permission.startsWith(modulePermission)))
			: false;
	}

	static fromJS(data: any): User {
		data = typeof data === 'object' ? data : {};
		const result = new User();
		result.init(data);
		return result;
	}

	static fromUserInfo(data: any): User {
		data = typeof data === 'object' ? data : {};
		const result = new User();
		result.init(data);
		result.email = data.name;
		return result;
	}
}
