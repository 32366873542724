import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CoreLibModule } from '@fitech-workspace/core-lib';
import { CollapsibleLayoutComponent } from './collapsible-layout/collapsible-layout.component';
import { CollapsibleLayoutRightComponent } from './collapsible-layout-right/collapsible-layout-right.component';
import { CollapsibleMenuRightComponent } from './collapsible-menu-right/collapsible-menu-right.component';

@NgModule({
	imports: [CoreLibModule],
	declarations: [CollapsibleLayoutComponent, CollapsibleMenuRightComponent, CollapsibleLayoutRightComponent],
	exports: [CollapsibleLayoutComponent, CollapsibleMenuRightComponent, CollapsibleLayoutRightComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedUiLayoutLibModule {}
