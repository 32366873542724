import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LanguageService, NotificationService } from '@fitech-workspace/core-lib';
import { ForgotPasswordCmd, MasterUsersService } from '@fitech-workspace/master-data-lib';
import { RemindPasswordForm } from '../../models';
import { AUTH_ENV_CONFIG, UserService } from '../../services/user.service';

@Component({
	selector: 'fitech-workspace-password-forgot',
	templateUrl: './password-forgot.component.html',
	styleUrls: ['./password-forgot.component.scss'],
})
export class PasswordForgotComponent implements OnInit {
	@Input() logoSrc: string;

	returnUrl: any;
	$counter: any;
	secondsToRedirect: number;
	isInProgress: boolean;
	remindPasswordForm: FormGroup<RemindPasswordForm>;
	emailInput: string;
	passwordInput: string;
	isSubmitting = false;
	isSubmitted = false;

	private _environmentConfig;

	get emailControl(): AbstractControl<string> {
		return this.remindPasswordForm.get('email');
	}

	constructor(
		@Inject(AUTH_ENV_CONFIG) environmentConfig: any,
		private _notificationService: NotificationService,
		private _formBuilder: FormBuilder,
		private _userService: UserService,
		private _masterUsersService: MasterUsersService,
		private _languageService: LanguageService
	) {
		this._environmentConfig = environmentConfig;
		if (this._environmentConfig) {
			this.logoSrc = this._environmentConfig.logoSrc;
		}
	}

	ngOnInit(): void {
		this.createFormGroups(this._formBuilder);
		this.onChanges();
	}

	createFormGroups(fb: FormBuilder): void {
		this.remindPasswordForm = fb.group({
			email: new FormControl<string>(''),
		});

		this.emailControl.setValidators([Validators.required, Validators.email]);
	}

	onChanges(): void {
		this.remindPasswordForm.valueChanges.subscribe((val: any) => {
			if (val.email) {
				this.isSubmitted = false;
			}
		});
	}

	shouldDisableAction(): boolean {
		return this.isSubmitting || this.isSubmitted || this.emailControl.invalid;
	}

	forgotPassword(): void {
		this.isSubmitting = true;
		this._masterUsersService.forgotPassword(this.createForgotPasswordReq()).subscribe(
			() => {
				this.isSubmitting = false;
				this.isSubmitted = true;
				this._notificationService.showSuccess('Email was sent.');
			},
			() => {
				this.isSubmitting = false;
				this._notificationService.showError('Email not found.');
			}
		);
	}

	createForgotPasswordReq(): ForgotPasswordCmd {
		const forgotPasswordReq: ForgotPasswordCmd = {
			email: this.emailControl.value,
			context: encodeURIComponent(this._userService.getAppContext()),
			languageCode: this._languageService.currentLanguage || null,
			baseUrl: location.origin,
		};
		return forgotPasswordReq;
	}

	keyDownFunction(event: any): void {
		if (event.keyCode === 13) {
			this.forgotPassword();
		}
	}

	isValid(key: any): boolean {
		return this.remindPasswordForm.controls[key].valid;
	}

	isTouched(key: any): boolean {
		return this.remindPasswordForm.controls[key].touched;
	}

	getErrorMsg(key: any): string {
		let errorMsg = '';
		if (this.isTouched(key)) {
			if (this.remindPasswordForm.controls[key].hasError('required')) {
				errorMsg += 'Field is required. ';
			}
			if (this.remindPasswordForm.controls[key].hasError('email')) {
				errorMsg += 'Field does not meet e-mail pattern requirement. ';
			}
		}
		return errorMsg;
	}
}
