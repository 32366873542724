import { AgGridModule } from '@ag-grid-community/angular';
import { NgModule } from '@angular/core';
import { CoreLibModule } from '@fitech-workspace/core-lib';
import { SharedUiIconsModule } from '@fitech-workspace/shared/ui/controls-lib';
import { CustomHeaderComponent } from './custom-header/custom-header.component';
import { CustomTooltip } from './custom-tooltip/custom-tooltip.component';
import { GridComponent } from './grid/grid.component';
import { NumericEditorComponent } from './grid-editors/numeric-editor/numeric-editor.component';
import { TextEditorComponent } from './grid-editors/text-editor/text-editor.component';
import { GridFilterComponent } from './grid-filter/grid-filter.component';
import { AutocompleteRendererComponent } from './grid-renderers/autocomplete-renderer/autocomplete-renderer.component';
import { AvatarRendererComponent } from './grid-renderers/avatar-renderer/avatar-renderer.component';
import { ButtonRendererComponent } from './grid-renderers/button-renderer/button-renderer.component';
import { CheckboxRendererComponent } from './grid-renderers/checkbox-renderer/checkbox-renderer.component';
import { ChipsRendererComponent } from './grid-renderers/chips-renderer/chips-renderer.component';
import { ChipsWithIconRendererComponent } from './grid-renderers/chips-with-icon-renderer/chips-with-icon-renderer.component';
import { DragButtonRendererComponent } from './grid-renderers/drag-button-renderer/drag-button-renderer.component';
import { LabelledButtonRendererComponent } from './grid-renderers/labelled-button-renderer/labelled-button-renderer.component';
import { LabelledChipsRendererComponent } from './grid-renderers/labelled-chips-renderer/labelled-chips-renderer.component';
import { LinkRendererComponent } from './grid-renderers/link-renderer/link-renderer.component';
import { PinnedCellRendererComponent } from './grid-renderers/pinned-cell-renderer/pinned-cell-renderer.component';
import { SimpleAvatarRendererComponent } from './grid-renderers/simple-avatar-renderer/simple-avatar-renderer.component';
import { SimpleChipsWithIconRendererComponent } from './grid-renderers/simple-chips-with-icon-renderer/simple-chips-with-icon-renderer.component';
import { TextRendererComponent } from './grid-renderers/text-renderer/text-renderer.component';
import { GridToolboxComponent } from './grid-toolbox/grid-toolbox.component';

@NgModule({
	declarations: [
		GridComponent,
		ChipsRendererComponent,
		ChipsWithIconRendererComponent,
		SimpleChipsWithIconRendererComponent,
		AvatarRendererComponent,
		SimpleAvatarRendererComponent,
		ButtonRendererComponent,
		LabelledButtonRendererComponent,
		LabelledChipsRendererComponent,
		LinkRendererComponent,
		TextRendererComponent,
		CustomHeaderComponent,
		GridFilterComponent,
		GridToolboxComponent,
		DragButtonRendererComponent,
		CheckboxRendererComponent,
		NumericEditorComponent,
		TextEditorComponent,
		CustomTooltip,
		AutocompleteRendererComponent,
		PinnedCellRendererComponent,
	],
	imports: [CoreLibModule, SharedUiIconsModule, AgGridModule],
	exports: [GridComponent, GridFilterComponent, GridToolboxComponent],
})
export class SharedUiGridLibModule {}
