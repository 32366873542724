import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { IConfirmationDialogOptions } from '../../models/confirmation-dialog-options.model';

@Component({
	selector: 'fitech-workspace-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
	isSubmitting: boolean;
	isSubmitted: boolean;

	headerLabel: string;
	messages: { label: string; value: string }[];
	data: any;
	onSave: (data: any) => Observable<any>;

	constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {}

	initialize(options: IConfirmationDialogOptions): void {
		this.headerLabel = options.headerLabel;
		this.onSave = options.onSave;

		this.data = options.data;
		if (options.onCancel) {
			this.onCancel = options.onCancel;
		}
		if (options.getMessages) {
			this.getMessages = options.getMessages;
		}
		this.messages = options.messages || this.getMessages();
	}

	getMessages(data?: any): { label: string; value: string }[] {
		const messages = [];
		const rowsLabel = 'Selected rows';
		if (this.data.rows) {
			messages.push({ label: rowsLabel, value: this.data.rows.length.toString() });
		}
		let optionLabel = 'Selected option: ';
		let optionValue = '';
		if (this.data.option) {
			if (this.data.option.data.typeName) {
				optionLabel = this.data.option.data.typeName;
			}
			optionValue = this.data.option.label;
			messages.push({ label: optionLabel, value: optionValue });
		}
		return messages;
	}

	onCancel(): void {
		this.dialogRef.close({ result: false });
	}

	onConfirm(): void {
		this.isSubmitting = true;
		this.isSubmitted = false;
		this.onSave(this.data).subscribe(
			() => {
				this.isSubmitting = false;
				this.isSubmitted = true;
				this.dialogRef.close(true);
			},
			() => {
				this.isSubmitting = false;
				this.dialogRef.close(false);
			}
		);
	}
}
