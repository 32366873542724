/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/typedef */
import { Injectable } from '@angular/core';
import { NavigationEnd, Event as NavigationEvent, Router } from '@angular/router';
import { UserMenuService } from '@fitech-workspace/auth-lib';
import { GlobalUserSettingDto, StringUtils, UserSettingDto } from '@fitech-workspace/core-lib';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { Observable, iif, of, timer } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { UserSettingsApiService } from '../../api/user-settings-api.service';
import { EntitiesCommandRequestResult, EntityCommandRequestResult, ProcessingData } from '../../models';
import { MultiScreensService } from '../../services';
import * as errorHandlingActions from '../actions/error-handling.actions';
import * as userSettingsActions from '../actions/user-settings.actions';
import * as userActions from '../actions/user.actions';
import * as userSettingsSelectors from '../selectors/user-settings.selectors';

@Injectable()
export class UserSettingsEffects {
	private readonly _forceUpsert = true;
	private readonly _intervalBatchUpsertCheckInSec = 300;
	private readonly _maximumRetryUpsertBatchWhenFail = 3;

	private _batchedUserSettingsProcessingData$: Observable<ProcessingData<UserSettingDto[]>> = this._store.pipe(
		select(userSettingsSelectors.selectBatchedUserSettingsProcessingData)
	);

	private _batchedUserSettingsRetryErrors$: Observable<number> = this._store.pipe(select(userSettingsSelectors.selectBatchedUserSettingsRetryErrors));

	constructor(
		private readonly _actions$: Actions,
		private readonly _store: Store,
		private _userSettingsApiService: UserSettingsApiService,
		private _router: Router,
		private _userMenuService: UserMenuService,
		private _multiScreensService: MultiScreensService
	) {}

	getUserSettings$ = createEffect(() =>
		this._actions$.pipe(
			ofType(userSettingsActions.getUserSettings, userActions.loggedInSuccess),
			fetch({
				run: (action) => {
					const isParametersDefined = action.type === userSettingsActions.getUserSettings.type;
					const view = isParametersDefined ? action.view : undefined;
					const section = isParametersDefined ? action.section : undefined;
					const element = isParametersDefined ? action.element : undefined;
					const settingTag = isParametersDefined ? action.settingTag : undefined;
					const skipGlobal = isParametersDefined ? action.skipGlobal : false;

					return this._userSettingsApiService.getUserSettings(view, section, element, settingTag, skipGlobal).pipe(
						map((result: UserSettingDto[]) =>
							userSettingsActions.getUserSettingsSuccess({
								settings: result,
								skipGlobal: skipGlobal ?? true,
								isLocationFiltered:
									StringUtils.isNotNullOrUndefined(view) ||
									StringUtils.isNotNullOrUndefined(section) ||
									StringUtils.isNotNullOrUndefined(element),
								isTagFiltered: StringUtils.isNotNullOrUndefined(settingTag),
							})
						)
					);
				},
				onError: (action, error) => errorHandlingActions.handleError({ error }),
			})
		)
	);

	handleOtherScreensUpsertSuccess$ = createEffect(() =>
		this._multiScreensService
			.getOtherScreensMessageByType$(userSettingsActions.upsertBatchUserSettingsSuccess.type.toString())
			.pipe(switchMap(() => of(userSettingsActions.getUserSettings({ skipGlobal: false }))))
	);

	getGlobalUserSettings$ = createEffect(() =>
		this._actions$.pipe(
			ofType(userSettingsActions.getGlobalUserSettings),
			fetch({
				run: (action) =>
					this._userSettingsApiService
						.getGlobalUserSettings(action.userSettingTag)
						.pipe(map((result: GlobalUserSettingDto[]) => userSettingsActions.getGlobalUserSettingsSuccess({ settings: result }))),
				onError: (action, error) => errorHandlingActions.handleError({ error }),
			})
		)
	);

	getUserSetting$ = createEffect(() =>
		this._actions$.pipe(
			ofType(userSettingsActions.getUserSetting),
			fetch({
				run: (action) =>
					this._userSettingsApiService
						.getUserSetting(action.userSettingId)
						.pipe(map((result: UserSettingDto) => userSettingsActions.getUserSettingSuccess({ setting: result }))),
				onError: (action, error) => errorHandlingActions.handleError({ error }),
			})
		)
	);

	getBatchUserSettings$ = createEffect(() =>
		this._actions$.pipe(
			ofType(userSettingsActions.getBatchUserSettings),
			fetch({
				run: (action) =>
					this._userSettingsApiService
						.getBatchUserSettings(action.userSettingIds)
						.pipe(map((result: UserSettingDto[]) => userSettingsActions.getBatchUserSettingsSuccess({ userSettings: result }))),
				onError: (action, error) => errorHandlingActions.handleError({ error }),
			})
		)
	);

	createGlobalUserSetting$ = createEffect(() =>
		this._actions$.pipe(
			ofType(userSettingsActions.createGlobalUserSetting),
			fetch({
				run: (action) =>
					this._userSettingsApiService
						.createGlobalUserSetting(action.globalUserSetting)
						.pipe(
							switchMap((result: EntityCommandRequestResult) =>
								this._userSettingsApiService
									.getUserSetting(result.entityId)
									.pipe(
										map((globalUserSetting: UserSettingDto) =>
											userSettingsActions.createGlobalUserSettingSuccess({ globalUserSetting: globalUserSetting })
										)
									)
							)
						),
				onError: (action, error) => errorHandlingActions.handleError({ error }),
			})
		)
	);

	updateGlobalUserSetting$ = createEffect(() =>
		this._actions$.pipe(
			ofType(userSettingsActions.updateGlobalUserSetting),
			fetch({
				run: (action) =>
					this._userSettingsApiService
						.updateGlobalUserSetting(action.globalUserSetting)
						.pipe(
							switchMap(() =>
								this._userSettingsApiService
									.getUserSetting(action.globalUserSetting.id)
									.pipe(
										map((globalUserSetting: UserSettingDto) =>
											userSettingsActions.updateGlobalUserSettingSuccess({ globalUserSetting: globalUserSetting })
										)
									)
							)
						),
				onError: (action, error) => errorHandlingActions.handleError({ error }),
			})
		)
	);

	upsertBatchUserSettings$ = createEffect(() =>
		this._actions$.pipe(
			ofType(userSettingsActions.upsertBatchUserSettings),
			fetch({
				run: (action) =>
					this._userSettingsApiService
						.upsertBatchUserSettings({ userSettings: action.userSettings, force: this._forceUpsert })
						.pipe(
							switchMap((result: EntitiesCommandRequestResult) =>
								this._userSettingsApiService
									.getBatchUserSettings(result.entityIds)
									.pipe(map((settings: UserSettingDto[]) => userSettingsActions.upsertBatchUserSettingsSuccess({ userSettings: settings })))
							)
						),
				onError: (action, error) => userSettingsActions.upsertBatchUserSettingsFailure({ error }),
			})
		)
	);

	upsertBatchUserSettingsSuccess$ = createEffect(
		() =>
			this._actions$.pipe(
				ofType(userSettingsActions.upsertBatchUserSettingsSuccess),
				tap((action) => this._multiScreensService.sendMessage(action.type, action.userSettings))
			),
		{ dispatch: false }
	);

	upsertBatchUserSettingsError$ = createEffect(() =>
		this._actions$.pipe(
			ofType(userSettingsActions.upsertBatchUserSettingsFailure),
			concatLatestFrom(() => this._batchedUserSettingsRetryErrors$),
			switchMap(([action, retryErrors]) =>
				iif(
					() => retryErrors === this._maximumRetryUpsertBatchWhenFail,
					of(errorHandlingActions.showErrorText({ error: 'User settings cannot be saved. Please contact Administrator.' })),
					of(errorHandlingActions.handleError({ error: action.error }))
				)
			)
		)
	);

	deleteUserSettingById$ = createEffect(() =>
		this._actions$.pipe(
			ofType(userSettingsActions.deleteUserSettingById),
			fetch({
				run: (action) =>
					this._userSettingsApiService
						.deleteUserSettingById(action.userSettingId)
						.pipe(map(() => userSettingsActions.deleteUserSettingByIdSuccess({ userSettingId: action.userSettingId }))),
				onError: (action, error) => errorHandlingActions.handleError({ error }),
			})
		)
	);

	deleteUserSettingByName$ = createEffect(() =>
		this._actions$.pipe(
			ofType(userSettingsActions.deleteUserSettingByName),
			fetch({
				run: (action) =>
					this._userSettingsApiService.deleteUserSettingByName(action.userSettingName, action.view, action.section, action.element).pipe(
						map(() =>
							userSettingsActions.deleteUserSettingByNameSuccess({
								userSettingName: action.userSettingName,
								view: action.view,
								section: action.section,
								element: action.element,
							})
						)
					),
				onError: (action, error) => errorHandlingActions.handleError({ error }),
			})
		)
	);

	deleteBatchUserSettings$ = createEffect(() =>
		this._actions$.pipe(
			ofType(userSettingsActions.deleteBatchUserSettings),
			fetch({
				run: (action) =>
					this._userSettingsApiService.deleteBatchUserSettings(action.userSettingIds).pipe(
						map(() =>
							userSettingsActions.deleteBatchUserSettingsSuccess({
								userSettingIds: action.userSettingIds,
							})
						)
					),
				onError: (action, error) => errorHandlingActions.handleError({ error }),
			})
		)
	);

	deleteAllUserSettings$ = createEffect(() =>
		this._actions$.pipe(
			ofType(userSettingsActions.deleteAllUserSettings),
			fetch({
				run: () => this._userSettingsApiService.deleteAllUserSettings().pipe(map(() => userSettingsActions.deleteAllUserSettingsSuccess())),
				onError: (action, error) => errorHandlingActions.handleError({ error }),
			})
		)
	);

	intervalPatchBatchUserSettings$ = createEffect(() => this.patchBatchUserSettings(timer(0, this._intervalBatchUpsertCheckInSec * 1000)));

	routingPatchBatchUserSettings$ = createEffect(() =>
		this.patchBatchUserSettings(this._router.events.pipe(filter((value: NavigationEvent) => value instanceof NavigationEnd)))
	);

	beforeLogoutPatchBatchUserSettings$ = createEffect(() => this.patchBatchUserSettings(this._userMenuService.opened$));

	beforeCloseAppPatchBatchUserSettings$ = createEffect(() =>
		this.patchBatchUserSettings(
			this._actions$.pipe(ofType(userActions.beforeCloseApplication, userActions.afterHideApplication, userSettingsActions.checkBatchedUserSettings))
		)
	);

	private patchBatchUserSettings<T>(source: Observable<T>): Observable<Action> {
		return source.pipe(
			concatLatestFrom(() => this._batchedUserSettingsProcessingData$),
			map((value: [T, ProcessingData<UserSettingDto[]>]) => value[1]),
			filter(
				(processingData: ProcessingData<UserSettingDto[]>) =>
					!processingData.isProcessing && processingData.data.length > 0 && processingData.retryErrors < this._maximumRetryUpsertBatchWhenFail
			),
			switchMap((processingData: ProcessingData<UserSettingDto[]>) =>
				of(userSettingsActions.upsertBatchUserSettings({ userSettings: processingData.data }))
			)
		);
	}
}
