import { Injectable } from '@angular/core';
import { Observable, Subject, } from 'rxjs';
import { GridSharedMessage } from './grid-shared-message';

@Injectable({
	providedIn: 'root',
})
export class GridSharedService {
	private _subject = new Subject<GridSharedMessage>();
	private _filterMap = new Map<string, any>();

	sendNotification(message: GridSharedMessage): void {
		this._subject.next(message);
	}

	getNotifications(): Observable<GridSharedMessage> {
		return this._subject.asObservable();
	}

	setFilterModel(viewName: string, model: any): void {
		this._filterMap.set(viewName, model);
	}

	getFilterModel(viewName: string): any {
		return this._filterMap.get(viewName);
	}
}
