<div class="mat-dialog-header">
	<div class="mat-dialog-header__group">
		<h5 class="mat-dialog-header__title" mat-dialog-title>
			{{ title }}
		</h5>
		<span class="mat-dialog-header__subtitle">{{ subTitle }}</span>
	</div>

	<button mat-icon-button (click)="onCancel()">
		<mat-icon>close</mat-icon>
	</button>
</div>
<div mat-dialog-content class="dialog-content">
	<div class="dialog-content__top sensors">
		<div class="sensors__line">
			<!-- machine status -->
			<fitech-workspace-hmi-indicator
				id="machine-status"
				class="sensors__machine-status for-wide-containers-1500"
				placeholder="Machine status"
				[state]="machineStatusState"
				[showButtons]="false"
				[showRawValue]="true">
			</fitech-workspace-hmi-indicator>
			<!-- last item -->
			<fitech-workspace-hmi-indicator
				id="last-item"
				class="sensors__last-item for-wide-containers-1500"
				placeholder="Item"
				[state]="lastItemState"
				[showButtons]="false">
			</fitech-workspace-hmi-indicator>
		</div>
		<div class="sensors__line">
			<!-- pcb counter -->
			<fitech-workspace-hmi-indicator
				id="pcb-counter"
				class="sensors__counter for-wide-containers-1500"
				placeholder="PCB counter"
				[state]="pcbCounterState"
				[showButtons]="false"
				[showRawValue]="true">
			</fitech-workspace-hmi-indicator>
			<!-- element counter -->
			<fitech-workspace-hmi-indicator
				id="element-counter"
				class="sensors__counter for-wide-containers-1500"
				placeholder="Element counter"
				[state]="elementCounterState"
				[showButtons]="false"
				[showRawValue]="true">
			</fitech-workspace-hmi-indicator>
			<!-- global cycle counter -->
			<fitech-workspace-hmi-indicator
				id="global-cycle-counter"
				class="sensors__counter for-wide-containers-1500"
				placeholder="Global cycle counter"
				[state]="globalCycleCounterState"
				[showButtons]="false"
				[showRawValue]="true">
			</fitech-workspace-hmi-indicator>
		</div>
	</div>
	<hr />
	<div class="dialog-content__buttons">
		<div>
			Alarms
			<button class="btn btn--icon-paddings btn--icon-secondary-hover" (click)="getAlarms()">
				<i class="fas fa-sync" [ngClass]="loadingAlarms ? 'fa-spin' : 'fa-refresh'" aria-hidden="true"></i>
			</button>
		</div>
		<div class="spacer"></div>
		<fitech-workspace-date-time-range-picker-v2
			#datePicker
			id="datePicker"
			[initialDateRange]="dateRange"
			(dateRangeChanged)="onDateRangeChanged($event)">
		</fitech-workspace-date-time-range-picker-v2>
	</div>
	<div class="dialog-content__grid">
		<fitech-workspace-grid #grid [gridId]="'alarms'" [rows]="machineAlarmsGridData" [columns]="gridColumns" [pagination]="false"></fitech-workspace-grid>
	</div>
</div>
<mat-dialog-actions>
	<button mat-button class="btn btn--tertiary" (click)="onCancel()">Back</button>
</mat-dialog-actions>
