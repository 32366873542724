import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UserMenuService {
	private _openedSubject = new Subject<void>();

	get opened$(): Observable<void> {
		return this._openedSubject.asObservable();
	}

	setOpenState(): void {
		this._openedSubject.next();
	}
}
