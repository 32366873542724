import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorIndicatorRenderer } from './components/error-indicator.renderer';
import { SparklineRendererComponent } from './components/sparkline-renderer/sparkline-renderer.component';
import { SparklineComponent } from './components/sparkline-renderer/sparkline.component';

@NgModule({
	declarations: [SparklineRendererComponent, SparklineComponent, ErrorIndicatorRenderer],
	imports: [CommonModule],
	exports: [SparklineRendererComponent, SparklineComponent, ErrorIndicatorRenderer],
})
export class SharedUiGridsAddonsModule {}
