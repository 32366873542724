import { IGpsPosition, IMapMarkerData, IMapMarkerDataV2 } from '@fitech-workspace/shared/ui/map-lib';
import * as moment from 'moment';
import { MachineIconFactory } from './machine-icon.factory';
import { MachineState } from '../machine-state.model';
import { Machine } from '../machine.model';
import { Sensor } from '../sensor.model';

export class MachineMarker implements IMapMarkerData, IMapMarkerDataV2 {
	id: any;
	iconClass: string;
	iconHtml: string;
	stateColor: { state: string; color: string; priority: number };
	gps: IGpsPosition;
	lastTimestamp: Date;

	extraData?: {
		machine: MachineState;
		machineName?: string;
		machineAlias?: string;
	};

	get hasAsyncCommunication(): boolean {
		if (this.extraData && this.extraData.machine && this.extraData.machine.defaultSensorState && this.extraData.machine.defaultSensorState.source) {
			return this.extraData.machine.defaultSensorState.source.type.endsWith('digi');
		}
		return null;
	}

	constructor(machine?: MachineState) {
		if (machine) {
			for (const property in machine) {
				if (machine.hasOwnProperty(property)) {
					(<any>this)[property] = (<any>machine)[property];
				}
			}
			this.id = machine.sourceId;
			this.lastTimestamp = machine['timestamp'];

			this.extraData = { machineName: machine.source.name, machineAlias: (machine.source as Machine)?.alias, machine: machine };
			this.setState(machine);
			const iconData = new MachineIconFactory().getIcon(this);

			this.iconHtml = iconData.iconHtml;
			this.iconClass = iconData.iconClass;
			this.gps = {
				lat: machine.source.positionX,
				lng: machine.source.positionY,
			};
		}
	}

	static getMachineMarkers(data: MachineState[]): MachineMarker[] {
		return data?.map((state: MachineState): MachineMarker => new MachineMarker(state)) ?? [];
	}

	static generateTooltipText(marker: IMapMarkerData): string {
		return `<div class="marker-tooltip-text">${marker.extraData.machineName}</div>`;
	}

	generateTooltipText(): string {
		return MachineMarker.generateTooltipText(this);
	}

	openDialog(dialog): void {
		throw new Error('Not implemented');
	}

	setState(machine: MachineState): void {
		if (moment(this.lastTimestamp) < moment().add(-6, 'hours') && !this.hasAsyncCommunication) {
			this.stateColor = Sensor.getSensorStateColor('inactive');
		} else if (machine.alarmIsLive) {
			this.stateColor = Sensor.getSensorStateColor(('alarm-' + machine.alarm.type).toLowerCase());
		} else {
			this.stateColor = Sensor.getSensorStateColor('active');
		}
	}
}
