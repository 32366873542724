import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
	selector: 'fitech-workspace-drag-button-renderer',
	templateUrl: './drag-button-renderer.component.html',
	styleUrls: ['./drag-button-renderer.component.scss'],
})
export class DragButtonRendererComponent implements ICellRendererAngularComp {
	params: any;
	data: IDragButtonData;

	refresh(params: any): boolean {
		return true;
	}

	agInit(params: any): void {
		if (params.data.getDragButtonData()) {
			this.data = params.data.getDragButtonData();
		} else {
			this.data = params as IDragButtonData;
		}
		this.params = params;
	}

	afterGuiAttached?(params?: any): void {
		this.params = params;
	}

	onDragStart(dragEvent: DragEvent): void {
		const userAgent = window.navigator.userAgent;
		const isIE = userAgent.indexOf('Trident/') >= 0;
		dragEvent.dataTransfer.setData(isIE ? 'text' : 'application/json', JSON.stringify(this.data.dragData));
	}
}

export interface IDragButtonData {
	buttonIcon: string;
	tooltip: string;
	isVisible: boolean;
	dragData: any;
}

export interface IDragButtonDataProvider {
	getDragButtonData(): IDragButtonData;
}
