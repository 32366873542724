import { ValueFormatterParams } from '@ag-grid-community/core';
import { DateUtils } from '@fitech-workspace/core-lib';
import { GridColumn } from '@fitech-workspace/shared/ui/grid-lib';
import { TranslateService } from '@ngx-translate/core';

export function getColumnsForThtMachine(translateService: TranslateService): GridColumn[] {
	return [
		{
			headerName: 'Timestamp',
			field: 'from',
			maxWidth: 200,
			valueFormatter: (params: ValueFormatterParams): string => DateUtils.formatInvariant(params.data?.from),
		},
		{
			headerName: 'Alarm',
			field: 'sensorName',
			minWidth: 150,
			valueFormatter: (params: ValueFormatterParams): string => translateService.instant(params.value),
		},
		{
			headerName: 'Type',
			field: 'sensorName',
			maxWidth: 220,
		},
	];
}
