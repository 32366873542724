import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'ellipsisText',
})
export class EllipsisTextPipe implements PipeTransform {
	transform(text: string, end: number): string {
		const length: number = text?.length ?? 0;
		return length > end ? `${text.substring(0, end)}...` : text;
	}
}
