import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'fitech-workspace-collapsible-layout',
	templateUrl: './collapsible-layout.component.html',
	styleUrls: ['./collapsible-layout.component.scss'],
})
export class CollapsibleLayoutComponent implements OnInit, AfterViewInit {
	@Input() sidePanelDisabled = false;
	@Input() sidePanelWidthMode: null | 'short' | 'middle' | 'wide';
	@Input() doNotHideContentOnOpenWide = false;
	@Input() isContentAutoOverflow = false;
	@Input() hidePrint = true;

	@Output() expand = new EventEmitter<void>();
	@Output() collapse = new EventEmitter<void>();

	sidePanelPosition = 1;
	isSidePanelToggling = false;
	sidePanelWideOpenEnabled = false;
	sidePanelMiddleOpenEnabled = false;
	sidePanelShortOpenEnabled = false;

	get canToggleLeft(): boolean {
		if (this.sidePanelWideOpenEnabled) {
			return this.sidePanelPosition > 0;
		} else {
			return this.sidePanelPosition === 1;
		}
	}

	get canToggleRight(): boolean {
		if (this.sidePanelWideOpenEnabled) {
			return this.sidePanelPosition < 2;
		} else {
			return this.sidePanelPosition === 0;
		}
	}

	ngOnInit(): void {
		this.sidePanelShortOpenEnabled = this.sidePanelWidthMode === 'short';
		this.sidePanelMiddleOpenEnabled = this.sidePanelWidthMode === 'middle';
		this.sidePanelWideOpenEnabled = this.sidePanelWidthMode === 'wide';
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.onToggled();
		}, 500);
	}

	toggleSidePanel(left: boolean): void {
		if (left) {
			this.sidePanelPosition--;
		} else {
			this.sidePanelPosition++;
		}

		this.isSidePanelToggling = true;
		setTimeout(() => {
			this.isSidePanelToggling = false;
			this.onToggled();
		}, 500);
	}

	private onToggled(): void {
		if (this.sidePanelPosition > 0) {
			this.expand.emit();
		} else {
			this.collapse.emit();
		}
	}
}
