export {};

if (!Array.prototype.sumBy) {
	Array.prototype.sumBy = function <T>(this: T[], selector: (item: T) => number): number {
		return this.reduce((accumulator: number, currentValue: T) => {
			return currentValue ? accumulator + selector(currentValue) : accumulator;
		}, 0);
	};
}

if (!Array.prototype.count) {
	Array.prototype.count = function <T>(this: T[], predicate?: (item: T) => boolean): number {
		if (typeof predicate === 'undefined') {
			return this.length;
		}
		return this.reduce((accumulator: number, currentValue: T) => {
			return predicate(currentValue) ? accumulator + 1 : accumulator;
		}, 0);
	};
}

if (!Array.prototype.distinctBy) {
	Array.prototype.distinctBy = function <T>(this: T[], selector: (item: T) => string | number): T[] {
		return this.filter((item: T, index: number, self: T[]): boolean => {
			const key: string | number = selector(item);
			return self.findIndex((selftItem: T): boolean => selector(selftItem) === key) === index;
		});
	};
}

if (!Array.prototype.firstOrDefault) {
	Array.prototype.firstOrDefault = function <T>(this: T[], predicate?: (item: T) => boolean): T {
		if (!this || !this.length) {
			return null;
		}
		if (typeof predicate === 'undefined' || predicate === null) {
			return this[0];
		}
		const filtered: T[] = this.filter(predicate);
		return filtered.length ? filtered[0] : null;
	};
}

if (!Array.prototype.lastOrDefault) {
	Array.prototype.lastOrDefault = function <T>(this: T[], predicate?: (item: T) => boolean): T {
		if (!this || !this.length) {
			return null;
		}
		if (typeof predicate === 'undefined' || predicate === null) {
			return this[this.length - 1];
		}
		const filtered: T[] = this.filter(predicate);
		return filtered.length ? filtered[filtered.length - 1] : null;
	};
}

if (!Array.prototype.sortBy) {
	Array.prototype.sortBy = function <T>(this: T[], selector: (item: T) => string | number): T[] {
		if (!this.length) {
			return [];
		}

		return this.slice().sort((a: T, b: T) => {
			const aValue: string | number = a ? selector(a) : null;
			const bValue: string | number = b ? selector(b) : null;
			const isAWithoutValue = typeof aValue === 'undefined' || aValue === null;
			const isBWithoutValue = typeof bValue === 'undefined' || bValue === null;

			if (isAWithoutValue && isBWithoutValue) {
				return 0;
			}

			if (isAWithoutValue) {
				return -1;
			}

			if (isBWithoutValue) {
				return 1;
			}

			const isNumber = typeof aValue === 'number' && typeof bValue === 'number';

			if (isNumber) {
				return aValue - bValue;
			}

			const isString = typeof aValue === 'string' && typeof bValue === 'string';

			if (isString) {
				return aValue.localeCompare(bValue);
			}

			return 0;
		});
	};
}

if (!Array.prototype.sortByDescending) {
	Array.prototype.sortByDescending = function <T>(this: T[], selector: (item: T) => string | number): T[] {
		return this.sortBy(selector).reverse();
	};
}
