import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../models/question-base';

@Component({
	selector: 'fitech-workspace-form-question',
	templateUrl: './dynamic-form-input.component.html',
	styleUrls: ['./dynamic-form-input.component.scss'],
})
export class DynamicFormInputComponent {
	@Input() question: QuestionBase<any>;
	@Input() form: UntypedFormGroup;
	@Input() headerLabel: string;
	@Input() showUndoButton: boolean;

	undo(): void {
		this.form.controls[this.question.key].setValue(this.question.value);
		this.form.controls[this.question.key].markAsPristine();
		this.form.controls[this.question.key].markAsUntouched();
	}

	generateDisplayText(item: any): any {
		if (item) {
			return item.value;
		}
	}

	filterNumericInput(event: InputEvent): void {
		if (this.question.type === 'number') {
			const input = event.target as HTMLInputElement;
			const filteredValue =
				input.value
					.replace(/[^\d.-]|(?<=\..*),/g, '')
					.replace(/(-.*)-/g, '$1')
					.replace(/(\..*)\./g, '$1') || null;

			this.form.controls[this.question.key].patchValue(filteredValue);
		}
	}
}
