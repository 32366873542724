import { LineSeriesOption } from 'echarts';
import { Observable, Subscription } from 'rxjs';
import { IYAxisMinMax } from './chart-yaxis-minmax.model'

export interface IChartData {
    value: number,
    timestamp: Date,
    min?: number,
    max?: number,
    setpoint?: number,
}

//TODO model, will change in the future (after api update)
export interface IGanttChartData {
    machineId: number,
    machineName: string,
    stateId: number,
    stateName: string,
    dateFrom: Date,
    dateTo: Date,
    durationInSec: number,
    sensorId?: number
}

export interface IChartSeriesConfig {
    id?: any;
    name?: string,
    units?: string,
    color?: string,
    category?: string,
    ignoreMinMax?: boolean,
    yAxisMinMax?: IYAxisMinMax,
    getDataFunc: (from: Date, to: Date, frequency?: number, data?: any) => Observable<IChartData[]>;
    type: ChartSeriesTypeEnum;
    customConfig?: LineSeriesOption;
}

export interface IChartSeriesWithData extends IChartSeriesConfig {
    chartConfig: any,
    visible: boolean,
    subscription?: Subscription,
    lastTimestamp?: Date
}

export enum ChartSeriesTypeEnum {
    data,
    dataStep,
    alarmThresholdSolid,
    warningThresholdSolid,
    alarmThresholdDotted,
    assumedPerformanceFactor
}

export interface IMarkAreaData {
    name: string;
    from: Date,
    to: Date,
    color?: string
}

export interface IMarkAreasConfig {
    getChartMarkAreasFunc: (from: Date, to: Date, data?: any) => Observable<IMarkAreaData[]>;
}

export interface IMarkAreasConfigWithData extends IMarkAreasConfig {
    subscription?: Subscription,
    chartConfigs?: any[]
}
