import { NgModule } from '@angular/core';
import { CoreLibModule } from '@fitech-workspace/core-lib';
import { SharedUiChartLibModule } from '@fitech-workspace/shared/ui/chart-lib';
import { SharedUiInputsMdModule, SharedUiInputsModule } from '@fitech-workspace/shared/ui/controls-lib';
import { SharedUiMapsModule } from '@fitech-workspace/shared/ui/map-lib';
import { MomentModule } from 'ngx-moment';
import { ChartDialogComponent } from './components/chart-dialog/chart-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { GeneralDialogComponent } from './components/general-dialog/general-dialog.component';
import { HistoryDialogComponent } from './components/history-dialog/history-dialog.component';
import { HistoryDialogQlComponent } from './components/history-dialog-ql/history-dialog-ql.component';
import { SimpleConfirmationDialogComponent } from './components/simple-confirmation-dialog/simple-confirmation-dialog.component';

@NgModule({
	imports: [CoreLibModule, MomentModule, SharedUiInputsMdModule, SharedUiInputsModule, SharedUiMapsModule, SharedUiChartLibModule],
	declarations: [
		HistoryDialogComponent,
		HistoryDialogQlComponent,
		GeneralDialogComponent,
		ConfirmationDialogComponent,
		ChartDialogComponent,
		SimpleConfirmationDialogComponent,
	],
	exports: [HistoryDialogComponent, HistoryDialogQlComponent, GeneralDialogComponent, ConfirmationDialogComponent, ChartDialogComponent],
})
export class SharedUiDialogLibModule {}
