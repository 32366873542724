<div class="editor">
	<input
		#input
		type="text"
		class="editor__input"
		focusInitial
		[(ngModel)]="value"
		[selectAll]="highlightAllOnFocus"
		[setCaretAtEnd]="true"
		[withDelay]="true"
		(keydown)="onKeyDown($event)" />
</div>
