import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { ITooltipParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
	selector: 'fitech-workspace-tooltip-component',
	templateUrl: './custom-tooltip.component.html',
	styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltip implements ITooltipAngularComp {
	data: string;

	agInit(params: ITooltipParams): void {
		this.data = params.value;
	}
}
