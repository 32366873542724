import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root',
})
export class MatIconsService {
	constructor(private _matIconRegistry: MatIconRegistry, private _domSanitizer: DomSanitizer) {}

	registerIcons(): void {
		this._matIconRegistry.addSvgIcon(`person_remove`, this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/person_remove.svg'));
		this._matIconRegistry.addSvgIcon(`filter_reset`, this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/filter_reset.svg'));
		this._matIconRegistry.addSvgIcon(`filter_off`, this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/filter_off.svg'));
		this._matIconRegistry.addSvgIcon(`location`, this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/location.svg'));
		this._matIconRegistry.addSvgIcon(`location_remove`, this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/location_remove.svg'));
		this._matIconRegistry.addSvgIcon(`cleaningmachine`, this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/cleaningmachine.svg'));
		this._matIconRegistry.addSvgIcon(`trolley`, this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/trolley.svg'));
		this._matIconRegistry.addSvgIcon(`trolley2`, this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/trolley2.svg'));
	}
}
