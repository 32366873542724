import { Injectable, InjectionToken, Inject } from '@angular/core';
import { Icon } from '../models/icon.model';
export const ICONS_CONFIG = new InjectionToken<Icon[]>('ICONS_CONFIG');

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  iconsConfig: Icon[];
  constructor(
    @Inject(ICONS_CONFIG) iconsConfig: any) {
    this.iconsConfig = iconsConfig;
  }

  getIcon(key: string) {
	return this.iconsConfig.firstOrDefault((icon: Icon) => icon.key === key);
  }

}
