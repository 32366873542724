import { UserAllowedClientDto } from './user-allowed-client-dto.model';

export class UserAllowedClient implements UserAllowedClientDto {
	clientId: number;
	clientName: string;

	static fromJS(data: any): UserAllowedClient {
		data = typeof data === 'object' ? data : {};
		const result = new UserAllowedClient();
		result.init(data);
		return result;
	}

	init(data?: any): void {
		if (data) {
			this.clientId = data['clientId'];
			this.clientName = data['clientName'];
		}
	}

	toJSON(data?: any): any {
		data = typeof data === 'object' ? data : {};
		data['clientId'] = this.clientId;
		data['clientName'] = this.clientName;
		return data;
	}
}
