<div class="ac">
	<input
		class="ac__input"
		type="text"
		name="recipe-component-select"
		placeholder="Pick Component"
		aria-label="Text"
		matInput
		[formControl]="searchControl"
		[matAutocomplete]="ac"
		(blur)="handleBlur()" />

	<mat-autocomplete #ac panelWidth="10em" [displayWith]="displayFn" (optionSelected)="handleSelectOption($event)" (closed)="handleSelectClosed()">
		<mat-option
			*ngFor="let item of filteredItems | async; trackBy: trackByItemUniqueProp"
			class="ac__option"
			[value]="item"
			[matTooltip]="item[itemLabelProp]">
			{{ item[itemLabelProp] }}
		</mat-option>
	</mat-autocomplete>
</div>
