/* eslint-disable @typescript-eslint/typedef */
import { DateUtils, StringUtils, UserSettingBaseDto, UserSettingDto, UserSettingsHelper } from '@fitech-workspace/core-lib';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import * as userSettingsActions from '../actions/user-settings.actions';
import { UserSettingsState } from '../models';

export const userSettingsInitialState: UserSettingsState = {
	globalUserSettings: {
		isLoaded: false,
		data: [],
	},
	localUserSettings: {
		isLoaded: false,
		data: [],
	},
	batchedUserSettings: {
		isValid: false,
		isProcessing: false,
		data: [],
		processingData: [],
		isError: false,
		retryErrors: 0,
	},
};

export const userSettingsReducer: ActionReducer<UserSettingsState, Action> = createReducer(
	userSettingsInitialState,
	on(userSettingsActions.getUserSettingsSuccess, (state: UserSettingsState, action) => {
		const localSettings: UserSettingDto[] = UserSettingsHelper.getLocal(action.settings);
		const localSettingIds: number[] = UserSettingsHelper.getIds(localSettings);

		return {
			batchedUserSettings: {
				data: [],
				processingData: [],
				isValid: false,
				isProcessing: false,
				isError: false,
				retryErrors: 0,
			},
			globalUserSettings: !action.skipGlobal
				? {
						isLoaded: true,
						lastUpdate: DateUtils.now(),
						data: UserSettingsHelper.getGlobal(action.settings),
				  }
				: state.globalUserSettings,
			localUserSettings: {
				isLoaded: true,
				lastUpdate: DateUtils.now(),
				data: action.isLocationFiltered
					? [...state.localUserSettings.data.filter((x: UserSettingDto) => !localSettingIds.includes(x.id)), ...localSettings]
					: localSettings,
			},
		};
	}),
	on(userSettingsActions.upsertBatchUserSettings, (state: UserSettingsState, action) => {
		return {
			...state,
			batchedUserSettings: {
				...state.batchedUserSettings,
				data: [],
				processingData: [...action.userSettings],
				isProcessing: true,
			},
		};
	}),
	on(userSettingsActions.getBatchUserSettingsSuccess, userSettingsActions.upsertBatchUserSettingsSuccess, (state: UserSettingsState, action) => {
		const globalUserSettings = UserSettingsHelper.getGlobal(action.userSettings);
		const localUserSettings = UserSettingsHelper.getLocal(action.userSettings);

		return {
			...state,
			batchedUserSettings: {
				data: [...state.batchedUserSettings.data],
				processingData: [],
				isValid: false,
				isProcessing: false,
				isError: false,
				retryErrors: 0,
			},
			globalUserSettings: {
				...state.globalUserSettings,
				data:
					globalUserSettings.length > 0
						? UserSettingsHelper.appendMany(globalUserSettings, state.globalUserSettings.data)
						: state.globalUserSettings.data,
			},
			localUserSettings: {
				...state.localUserSettings,
				data:
					localUserSettings.length > 0
						? UserSettingsHelper.appendMany(localUserSettings, state.localUserSettings.data)
						: state.localUserSettings.data,
			},
		};
	}),
	on(userSettingsActions.upsertBatchUserSettingsFailure, (state: UserSettingsState) => {
		return {
			...state,
			batchedUserSettings: {
				...state.batchedUserSettings,
				data: UserSettingsHelper.appendMany(state.batchedUserSettings.data, state.batchedUserSettings.processingData),
				processingData: [],
				isError: true,
				retryErrors: state.batchedUserSettings.retryErrors + 1,
				isProcessing: false,
			},
		};
	}),
	on(userSettingsActions.getGlobalUserSettingsSuccess, (state: UserSettingsState, action) => {
		return {
			...state,
			globalUserSettings: {
				isLoaded: true,
				lastUpdate: DateUtils.now(),
				data: action.settings,
			},
		};
	}),
	on(userSettingsActions.getUserSettingSuccess, (state: UserSettingsState, action) => {
		const isGlobal = action.setting.isGlobal;
		return {
			...state,
			globalUserSettings: isGlobal
				? {
						...state.globalUserSettings,
						data: UserSettingsHelper.append(action.setting, state.globalUserSettings.data),
				  }
				: state.globalUserSettings,
			localUserSettings: !isGlobal
				? {
						...state.localUserSettings,
						data: UserSettingsHelper.append(action.setting, state.localUserSettings.data),
				  }
				: state.localUserSettings,
		};
	}),
	on(userSettingsActions.createGlobalUserSettingSuccess, (state: UserSettingsState, action) => {
		return {
			...state,
			globalUserSettings: {
				...state.globalUserSettings,
				data: [...state.globalUserSettings.data, action.globalUserSetting],
			},
		};
	}),
	on(userSettingsActions.updateGlobalUserSettingSuccess, (state: UserSettingsState, action) => {
		return {
			...state,
			globalUserSettings: {
				...state.globalUserSettings,
				data: UserSettingsHelper.append(action.globalUserSetting, state.globalUserSettings.data),
			},
		};
	}),
	on(userSettingsActions.deleteAllUserSettingsSuccess, () => {
		return {
			batchedUserSettings: {
				isProcessing: false,
				isValid: false,
				data: [],
				processingData: [],
				isError: false,
				retryErrors: 0,
			},
			globalUserSettings: {
				isLoaded: true,
				lastUpdate: DateUtils.now(),
				data: [],
			},
			localUserSettings: {
				isLoaded: true,
				lastUpdate: DateUtils.now(),
				data: [],
			},
		};
	}),
	on(userSettingsActions.deleteBatchUserSettingsSuccess, (state: UserSettingsState, action) => {
		return {
			...state,
			globalUserSettings: {
				...state.globalUserSettings,
				data: UserSettingsHelper.removeMany(state.globalUserSettings.data, action.userSettingIds),
			},
			localUserSettings: {
				...state.localUserSettings,
				data: UserSettingsHelper.removeMany(state.localUserSettings.data, action.userSettingIds),
			},
		};
	}),
	on(userSettingsActions.deleteUserSettingByIdSuccess, (state: UserSettingsState, action) => {
		return {
			...state,
			globalUserSettings: {
				...state.globalUserSettings,
				data: UserSettingsHelper.removeMany(state.globalUserSettings.data, [action.userSettingId]),
			},
			localUserSettings: {
				...state.localUserSettings,
				data: UserSettingsHelper.removeMany(state.localUserSettings.data, [action.userSettingId]),
			},
		};
	}),
	on(userSettingsActions.deleteUserSettingByNameSuccess, (state: UserSettingsState, action) => {
		const isGlobal =
			StringUtils.isNullOrUndefined(action.view) && StringUtils.isNullOrUndefined(action.section) && StringUtils.isNullOrUndefined(action.element);
		return {
			...state,
			globalUserSettings: {
				...state.globalUserSettings,
				data: isGlobal
					? state.globalUserSettings.data.filter((x: UserSettingBaseDto) => x.name !== action.userSettingName)
					: state.globalUserSettings.data,
			},
			localUserSettings: {
				...state.localUserSettings,
				data: !isGlobal
					? state.localUserSettings.data.filter(
							(x: UserSettingDto) =>
								!(
									x.name === action.userSettingName &&
									x.userSettingsLocation.view === action.view &&
									x.userSettingsLocation.section === action.section &&
									x.userSettingsLocation.element === action.element
								)
					  )
					: state.localUserSettings.data,
			},
		};
	}),
	on(userSettingsActions.pushBatchedUserSettings, (state: UserSettingsState, action) => {
		return {
			...state,
			batchedUserSettings: {
				...state.batchedUserSettings,
				isValid: true,
				lastUpdate: DateUtils.now(),
				data: [
					...state.batchedUserSettings.data.filter(
						(previousUserSetting: UserSettingDto) =>
							!action.userSettings.some((currentUserSetting: UserSettingDto) =>
								UserSettingsHelper.areEqual(previousUserSetting, currentUserSetting)
							)
					),
					...action.userSettings,
				],
			},
		};
	})
);
