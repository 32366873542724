import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { GuardsCheckEnd, NavigationEnd, Event as NavigationEvent, Router } from '@angular/router';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { delay, filter, map, take, tap } from 'rxjs/operators';
import { UserMenuOption, UserService } from '@fitech-workspace/auth-lib';
import { GlobalService } from '@fitech-workspace/master-data-lib';
import { DialogService, ICustomDialogOptions } from '@fitech-workspace/shared/ui/dialog-lib';
import { VersionsDialogComponent } from './shared/components/versions-dialog/versions-dialog.component';
import { NavLinkParams } from './shared/models/nav-link-params.model';
import { MatIconsService, MultiScreensService } from './shared/services';
import { AppNavLinksService } from './shared/services/app-navlinks.service';
import { DatePickerService } from './shared/services/date-picker.service';
import { UserFacadeService } from './shared/state/facades/user-facade.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	currentYear: number;
	showHeader = false;
	showFooter = false;
	navLinks$: Observable<NavLinkParams[]>;

	userMenuOptions: UserMenuOption[] = [{ key: 'about', label: 'About' }];

	isVersionsDialogOpen = false;

	private _canAppBeClosed: boolean;
	private _subscriptions = new Subscription();

	constructor(
		private _userService: UserService,
		private _router: Router,
		private _datePickerService: DatePickerService,
		private _userFacadeService: UserFacadeService,
		private _multiScreenService: MultiScreensService,
		private _navLinksService: AppNavLinksService,
		private _dialogService: DialogService,
		private _matIconsService: MatIconsService,
		private _globalService: GlobalService
	) {}

	async ngOnInit(): Promise<void> {
		this._matIconsService.registerIcons();
		this.navLinks$ = this._navLinksService.navLinks$;
		await this._userService.retrieveUserDataAsync();
		this.currentYear = new Date().getFullYear();
		this._multiScreenService.generateScreenId();

		this.initializeSubscriptions();
	}

	ngOnDestroy(): void {
		this._multiScreenService.clearScreenId();
	}

	@HostListener('window:beforeunload', ['$event'])
	beforeUnload(event: BeforeUnloadEvent): void {
		if (!this._canAppBeClosed) {
			event.returnValue = '';
			this._userFacadeService.beforeCloseApplication();
			return;
		}

		this._multiScreenService.clearScreenId();
	}

	@HostListener('document:visibilitychange', ['$event'])
	onDocumentVisibilityChange(): void {
		if (document.visibilityState === 'hidden') {
			this._userFacadeService.afterHideApplication();
		}
	}

	selectUserMenuOption(option: UserMenuOption): void {
		switch (option.key) {
			case 'about':
				this.openVersionsDialog();
				break;
		}
	}

	openVersionsDialog(): void {
		const dialogOptions: ICustomDialogOptions = {
			hasBackdrop: true,
			width: '25%',
		};
		this._dialogService.openCustomDialog(VersionsDialogComponent, dialogOptions);
	}

	//#region Private methods
	private isPublicPage(url: string): boolean {
		return url.startsWith('/login') || url.startsWith('/password');
	}

	private initializeSubscriptions(): void {
		this._subscriptions.add(
			this._userService.isLoggedToggle$.subscribe((isLoggedIn: boolean) => {
				if (isLoggedIn) {
					this._datePickerService.getShiftsFromApi();
					this._userFacadeService.loggedInSuccess();
					return;
				}
				this._globalService.clearClients();
				this._userFacadeService.loggedOutSuccess();
				this._multiScreenService.clearLastMessage();
			})
		);

		this._subscriptions.add(
			this._userService.isLoggedToggle$
				.pipe(
					filter((isLoggedIn: boolean) => !isLoggedIn),
					delay(500)
				)
				.subscribe(() => {
					this._router.navigate(['/login']);
				})
		);

		this._router.events
			.pipe(
				filter(
					(event: NavigationEvent) =>
						event instanceof GuardsCheckEnd &&
						event.shouldActivate &&
						!this.isPublicPage(event.url) &&
						(!event.urlAfterRedirects || !this.isPublicPage(event.urlAfterRedirects))
				),
				take(1),
				tap(() => this._userService.setLoggedInWithPreviousToken())
			)
			.subscribe();

		this._subscriptions.add(
			this._router.events
				.pipe(
					filter((event: NavigationEvent) => event instanceof NavigationEnd),
					map((event: NavigationEnd) => this.isPublicPage(event.url) || this.isPublicPage(event.urlAfterRedirects))
				)
				.subscribe((hideBars: boolean) => {
					this.showHeader = !hideBars;
					this.showFooter = !hideBars;
				})
		);
		this._subscriptions.add(
			fromEvent(document, 'wheel')
				.pipe(
					map(() => <HTMLInputElement>document.activeElement),
					filter((activeElement: HTMLInputElement) => activeElement?.type === 'number')
				)
				.subscribe((activeElement: HTMLInputElement) => activeElement.blur())
		);

		this._subscriptions.add(
			this._userFacadeService.canAppBeClosed$.subscribe((canAppBeClosed: boolean) => {
				this._canAppBeClosed = canAppBeClosed;
			})
		);
		this._subscriptions.add(this._multiScreenService.refreshScreenIdWhenNewScreen$().subscribe());
	}
	//#endregion
}
