import * as _ from 'lodash';
export class ArrayUtils {
	/**
	 * @description Swap two elements in array and create new instance.
	 * @param arr Any not empty array.
	 * @throws Error if array is empty or index parameters exceeding array dimensions.
	 * @returns New array with swapped elements.
	 */
	static swapArrayElementsImmutable(arr: any[], indexToSwapFrom: number, swapWithIndex: number): any[] {
		const arrLength = arr.length;

		if (indexToSwapFrom > arrLength || swapWithIndex > arrLength || indexToSwapFrom < 0 || swapWithIndex < 0) {
			throw new Error('Wrong parameters passed. Index parameters exceeding array size.');
		}

		if (arrLength === 0) {
			throw new Error('Wrong parameters passed. Array is empty.');
		}

		// eslint-disable-next-line @typescript-eslint/naming-convention
		const _arr = [...arr];
		const temp = _arr[indexToSwapFrom];
		_arr[indexToSwapFrom] = _arr[swapWithIndex];
		_arr[swapWithIndex] = temp;

		return _arr;
	}

	/**
	 * @description
	 * Takes an Array<T> and a grouping function
	 * and returns a Map of the array grouped by the grouping function.
	 *
	 * @param array An array of type T.
	 * @param keyGetter A Function that takes the the Array type T as an input, and returns a value of type K.
	 *                  K is generally intended to be a property key of T.
	 *
	 * @returns Map of the array grouped by the grouping function.
	 */
	static groupBy<K, T>(array: T[], keyGetter: (t: T) => K): Map<K, T[]> {
		const map: Map<K, T[]> = new Map<K, T[]>();

		array.forEach((item: T): void => {
			const key: K = keyGetter(item);
			const groupedArray: T[] = map.get(key);
			if (!groupedArray) {
				map.set(key, [item]);
			} else {
				groupedArray.push(item);
			}
		});
		return map;
	}

	/**
	 * @description
	 * Takes two any arrays and returns true if the arrays are equal regardless of order or not
	 * otherwise returns false.
	 *
	 * @param arr1 An any array.
	 * @param arr2 An any array.
	 * @param withSortBy Boolean whether `isEqual` method should use also `sortBy` method or not.
	 *
	 * @returns true or false.
	 */
	static isEqual(arr1: any[], arr2: any[], withSortBy: boolean = false): boolean {
		return withSortBy ? _.isEqual(_.sortBy(arr1), _.sortBy(arr2)) : _.isEqual(arr1, arr2);
	}
}
