import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { HttpParamsBuilder } from '@fitech-workspace/core-lib';
import { AlarmHistoryThresholdDto, BACKEND_API_HOSTMASTERKEY, BACKEND_API_URL, BACKEND_CLIENT_TAG } from '@fitech-workspace/fisense-common-lib';
import { AlarmThresholdData, IChartData } from '@fitech-workspace/shared/ui/chart-lib';
import { withCache } from '@ngneat/cashew';
import { AsEnumerable } from 'linq-es2015';
import { IGrouping } from 'linq-es2015/lib/enumerable';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Alarm } from '../models/alarm.model';


@Injectable({
	providedIn: 'root',
})
export class AlarmsApiService {
	protected apiUrl: string;
	protected clientTag: string;
	protected apiHostMasterKey: string;

	private get _alarmsApiUrl(): string {
		return `${this.apiUrl}alarms`;
	}

	constructor(
		protected httpClient: HttpClient,
		@Optional()
		@Inject(BACKEND_API_URL)
		trackersApiUrl?: string,
		@Optional()
		@Inject(BACKEND_API_HOSTMASTERKEY)
		apiHostMasterKey?: string,
		@Optional()
		@Inject(BACKEND_CLIENT_TAG)
		clientTag?: string
	) {
		this.apiUrl = trackersApiUrl;
		this.apiHostMasterKey = apiHostMasterKey;
		this.clientTag = clientTag;
	}

	getAlarmsGroupedByTypeWithStyles(from: Date, to: Date, locationId?: number, sensorId?: number): Observable<any[][]> {
		return this.getAlarms(from, to, locationId, sensorId).pipe(
			map((res: Alarm[]) => {
				const alarms = AsEnumerable(res)
					.OrderBy((x: Alarm) => x.type)
					.GroupBy((x: Alarm) => x.type)
					.Select((x: IGrouping<string, Alarm>) =>
						AsEnumerable(x)
							.Select((y: Alarm) => ({ data: y, color: y.getAlarmColor() }))
							.ToArray()
					)
					.ToArray();
				return alarms;
			})
		);
	}

	getAlarms(from: Date, to: Date, locationId?: number, sensorId?: number): Observable<Alarm[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create()
			.append('from', moment(from).toISOString())
			.append('to', moment(to).toISOString())
			.append('code', this.apiHostMasterKey)
			.append('locationId', locationId)
			.append('sensorId', sensorId);

		return this.httpClient.get<Alarm[]>(`${this._alarmsApiUrl}/history`, { params: httpParamsBuilder.build() }).pipe(
			map((res: Alarm[]) => {
				return res.map((data: Alarm) => new Alarm(data));
			})
		);
	}

	getAlarmsThresholds(from: Date, to: Date, sensorId?: number): Observable<AlarmThresholdData> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create()
			.append('from', moment(from).toISOString())
			.append('to', moment(to).toISOString())
			.append('code', this.apiHostMasterKey)
			.append('sensorId', sensorId);

		return this.httpClient
			.get<AlarmHistoryThresholdDto[]>(`${this._alarmsApiUrl}/thresholds`, {
				params: httpParamsBuilder.build(),
				context: withCache(),
			})
			.pipe(
				map((res: AlarmHistoryThresholdDto[]) => {
					const results: AlarmThresholdData = { high: [], low: [], highHysteresis: [], lowHysteresis: [] };

					if (res == null || res.length === 0) {
						return results;
					}

					if (AsEnumerable(res).Count((x: AlarmHistoryThresholdDto) => x.alarmType === 'High') > 0) {
						results.high = AsEnumerable(res)
							.Where((x: AlarmHistoryThresholdDto) => x.alarmType === 'High')
							.Select((x: AlarmHistoryThresholdDto) => {
								return { value: x.value, timestamp: x.timestamp } as IChartData;
							})
							.ToArray();
					}
					if (AsEnumerable(res).Count((x: AlarmHistoryThresholdDto) => x.alarmType === 'Low') > 0) {
						results.low = AsEnumerable(res)
							.Where((x: AlarmHistoryThresholdDto) => x.alarmType === 'Low')
							.Select((x: AlarmHistoryThresholdDto) => {
								return { value: x.value, timestamp: x.timestamp } as IChartData;
							})
							.ToArray();
					}
					if (AsEnumerable(res).Count((x: AlarmHistoryThresholdDto) => x.alarmType === 'HighHysteresis') > 0) {
						results.highHysteresis = AsEnumerable(res)
							.Where((x: AlarmHistoryThresholdDto) => x.alarmType === 'HighHysteresis')
							.Select((x: AlarmHistoryThresholdDto) => {
								return { value: x.value, timestamp: x.timestamp } as IChartData;
							})
							.ToArray();
					}
					if (AsEnumerable(res).Count((x: AlarmHistoryThresholdDto) => x.alarmType === 'LowHysteresis') > 0) {
						results.lowHysteresis = AsEnumerable(res)
							.Where((x: AlarmHistoryThresholdDto) => x.alarmType === 'LowHysteresis')
							.Select((x: AlarmHistoryThresholdDto) => {
								return { value: x.value, timestamp: x.timestamp } as IChartData;
							})
							.ToArray();
					}
					if (AsEnumerable(res).Count((x: AlarmHistoryThresholdDto) => x.alarmType === 'HighWarning') > 0) {
						results.highWarning = AsEnumerable(res)
							.Where((x: AlarmHistoryThresholdDto) => x.alarmType === 'HighWarning')
							.Select((x: AlarmHistoryThresholdDto) => {
								return { value: x.value, timestamp: x.timestamp } as IChartData;
							})
							.ToArray();
					}
					if (AsEnumerable(res).Count((x: AlarmHistoryThresholdDto) => x.alarmType === 'LowWarning') > 0) {
						results.lowWarning = AsEnumerable(res)
							.Where((x: AlarmHistoryThresholdDto) => x.alarmType === 'LowWarning')
							.Select((x: AlarmHistoryThresholdDto) => {
								return { value: x.value, timestamp: x.timestamp } as IChartData;
							})
							.ToArray();
					}
					return results;
				})
			);
	}

	getAlarmsNotAcked(from: Date, locationId?: number): Observable<Alarm[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create()
			.append('from', moment(from).toISOString())
			.append('code', this.apiHostMasterKey)
			.append('locationId', locationId);

		return this.httpClient.get<Alarm[]>(`${this._alarmsApiUrl}/not-acked`, { params: httpParamsBuilder.build() }).pipe(
			map((res: Alarm[]) => {
				return res.map((data: Alarm) => new Alarm(data));
			})
		);
	}

	changeAlarmState(alarmGuids: string[], state: string): Observable<void> {
		const body: any = {
			alarmGuids: alarmGuids,
			state: state,
		};

		if (this.apiHostMasterKey) {
			body.code = this.apiHostMasterKey;
		}

		return this.httpClient.post<void>(`${this._alarmsApiUrl}/change-state`, body);
	}
}
