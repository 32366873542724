import { Component, Input, ViewChild } from '@angular/core';
import { Sensor } from '@fitech-workspace/fisense-common-lib';
import { IChartSeriesConfig } from '@fitech-workspace/shared/ui/chart-lib';
import { SensorsChartComponent } from './sensors-chart/sensors-chart.component';
import { SensorsChartWithAlarmsComponent } from './sensors-chart-with-alarms/sensors-chart-with-alarms.component';
import { ISensorAddedWithAlarm } from '../models/machine-components.model';

@Component({
	selector: 'fitech-workspace-machine-sensors-charts',
	templateUrl: './machine-sensors-charts.component.html',
	styleUrls: ['./machine-sensors-charts.component.scss'],
})
export class MachineSensorsChartsComponent {
	@Input() isCurrentStateActive: boolean;

	@ViewChild('sensorsChart', { static: true }) sensorsChart: SensorsChartComponent;
	@ViewChild('sensorsChartWithAlarms', { static: true }) sensorsChartWithAlarms: SensorsChartWithAlarmsComponent;

	chartWithoutAlarmsResize(): void {
		this.sensorsChart.lineChart.resize();
	}

	chartWithAlarmsResize(): void {
		this.sensorsChartWithAlarms.lineChart.resize();
	}

	addSeriesWithAlarm(options: ISensorAddedWithAlarm): void {
		this.sensorsChartWithAlarms.addSeriesWithAlarm(options);
	}

	addSeries(chartSeries: IChartSeriesConfig): void {
		this.sensorsChart.lineChart.addSeries(chartSeries);
	}

	removeSeries(sensor: Sensor): void {
		this.sensorsChart.lineChart.removeSeries(sensor.name);
	}
}
