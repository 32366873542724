<nav class="navbar navbar-expand-lg" [ngClass]="isMainHeader ? 'main-navbar navbar-dark' : 'sub-navbar navbar-light'">
	<div class="container-fluid content">
		<img *ngIf="isMainHeader" class="img-fluid logo" src="assets/logo/logo.svg" />
		<button
			[class]="isMainHeader ? 'navbar-toggler' : 'navbar-toggler sub-navbar-toggler'"
			type="button"
			[attr.data-target]="'#' + navbarId"
			[attr.aria-controls]="navbarId"
			data-toggle="collapse"
			aria-expanded="false"
			aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" [id]="navbarId">
			<div class="navbar-nav">
				<ng-container *ngFor="let navlink of navLinks; trackBy: trackByRoute">
					<a
						*ngIf="navlink.isVisible"
						[class]="isMainHeader ? 'main-toolbar-label' : 'sub-toolbar-label'"
						[routerLinkActive]="isMainHeader ? 'main-toolbar-accent' : 'sub-toolbar-accent'"
						[routerLink]="navlink.routerLink"
						[attr.data-target]="isMainHeader && '#' + navbarId"
						data-toggle="collapse"
						(click)="blockNavbarCollapsingWhenInBackground($event)">
						{{ navlink.title }}
					</a>
				</ng-container>
			</div>
			<div class="navbar-nav mr-auto"></div>
			<div class="navbar-nav right-custom-elem">
				<!-- <fitech-workspace-language-picker *ngIf="isMainHeader"></fitech-workspace-language-picker> -->
				<ng-content select="[custom-right-elem]"></ng-content>
			</div>
		</div>
	</div>
</nav>
