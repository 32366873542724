import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
	selector: 'fitech-workspace-pinned-cell-renderer',
	templateUrl: './pinned-cell-renderer.component.html',
	styleUrls: ['./pinned-cell-renderer.component.scss'],
})
export class PinnedCellRendererComponent implements ICellRendererAngularComp {
	params: ICellRendererParams<any, any, any>;
	title: string;

	agInit(params: ICellRendererParams<any, any, any> & { title: string }): void {
		this.params = params;
		this.title = params.title ? params.title : 'Quantity';
	}

	refresh(): boolean {
		return false;
	}
}
