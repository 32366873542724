import { NgModule } from '@angular/core';
import { CoreLibModule } from '@fitech-workspace/core-lib';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { CookieModule } from 'ngx-cookie';
import { LoginComponent } from './components/login/login.component';
import { LoginRedirectComponent } from './components/login-redirect/login-redirect.component';
import { LoginRequestForRolesComponent } from './components/login-request-for-roles/login-request-for-roles.component';
import { PasswordForgotComponent } from './components/password-forgot/password-forgot.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { PasswordSetComponent } from './components/password-set/password-set.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { PasswordDirective } from './directives/password.directive';

@NgModule({
	imports: [
		CoreLibModule,
		CookieModule.withOptions(),
		OAuthModule.forRoot({
			resourceServer: {
				sendAccessToken: true,
			},
		}),
	],
	providers: [{ provide: OAuthStorage, useValue: localStorage }],
	declarations: [
		LoginComponent,
		LoginRedirectComponent,
		UserInfoComponent,
		PasswordResetComponent,
		PasswordForgotComponent,
		PasswordSetComponent,
		PasswordDirective,
		LoginRequestForRolesComponent,
	],
	exports: [
		LoginComponent,
		LoginRequestForRolesComponent,
		LoginRedirectComponent,
		UserInfoComponent,
		PasswordResetComponent,
		PasswordForgotComponent,
		PasswordSetComponent,
	],
})
export class AuthLibModule {}
