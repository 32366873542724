import { AsEnumerable } from 'linq-es2015';
import { AlarmLifetimeThresholdEnum } from './alarm-lifetime-threshold.enum';
import { CommonGlobals } from './common-globals';
import { Machine } from './machine.model';
import { Sensor } from './sensor.model';
import { State } from './state.model';

export class MachineState extends State {
	sensorsStates: State[] = [];

	get defaultSensorState(): State {
		const defaultSensor = AsEnumerable(this.sensorsStates).FirstOrDefault((state) => {
			if (state.source instanceof Sensor) {
				return state.source.isMachineDefaultSensor === true;
			}
			return false;
		});
		return defaultSensor ? defaultSensor : null;
	}

	get value(): any {
		return this.defaultSensorState ? this.defaultSensorState.value : null;
	}

	get timestamp(): Date {
		return this.defaultSensorState ? this.defaultSensorState.timestamp : null;
	}

	get alarmIsActive(): boolean {
		return this.defaultSensorState ? this.defaultSensorState.alarmIsActive : false;
	}

	get alarmIsLive(): boolean {
		const alarmLastState: string = this.alarm ? this.alarm.lastState.state : null;
		let result = false;

		if (
			this.alarmIsActive ||
			(CommonGlobals.alarmLifetimeThreshold === AlarmLifetimeThresholdEnum.UntilUserAcknowledged &&
				alarmLastState &&
				alarmLastState !== 'Acknowledged' &&
				!alarmLastState.startsWith('Verified')) ||
			(CommonGlobals.alarmLifetimeThreshold === AlarmLifetimeThresholdEnum.UntilUserValidatedAsRealFalse &&
				alarmLastState &&
				!alarmLastState.startsWith('Verified'))
		) {
			result = true;
		}
		return result;
	}

	get alarmGuid(): string {
		return this.alarm ? this.alarm.guid : null;
	}

	get alarmType(): string {
		return this.alarm ? this.alarm.type : null;
	}

	constructor(data: any) {
		super();

		if (data instanceof Sensor) {
			this.sourceId = data.machineId;
			this.source = new Machine({
				name: data.machineName,
				alias: data.machineAlias,
				id: data.machineId,
				zoneName: data.zoneName,
				positionX: data.machinePositionX,
				positionY: data.machinePositionY,
				units: '',
				sensorsGroupType: data.sensorsGroupType,
			});
		}
	}

	static getSensors(currentStates: State[]): any[] {
		const sensors: any[] = [];
		currentStates.forEach((x) => {
			if (x instanceof MachineState) {
				x.sensorsStates.forEach((y) => {
					if (y.source) {
						sensors.push(y.source);
					}
				});
			}
		});
		return sensors;
	}

	addSensorsStates(state: State[]): void {
		this.sensorsStates = [...this.sensorsStates, ...state];
	}
}
