import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ArrayUtils } from '@fitech-workspace/core-lib';
import { Subscription } from 'rxjs';
import { SelectOption, SelectOptionKey } from '../../models';
import { SelectListQuestion } from '../../models/question-selectlist';
import { MatSelectExtendedComponent } from '../mat-select-extended/mat-select-extended.component';

@Component({
	selector: 'fitech-workspace-multi-dropdown-md',
	templateUrl: './dynamic-form-multi-dropdown-input.component.html',
	styleUrls: ['./dynamic-form-multi-dropdown-input.component.scss'],
})
export class DynamicFormMultiDropdownInputComponent implements OnInit, OnDestroy {
	@Input() question: SelectListQuestion;
	@Input() form: UntypedFormGroup;
	@Input() showUndoButton: boolean;

	@ViewChild(MatSelectExtendedComponent) matSelectExtendedComponent: MatSelectExtendedComponent;

	matSelectControl: UntypedFormControl;
	showAddNewItem = false;
	isSelectButtons: boolean;
	singleSelect: boolean;
	items: SelectOption[] = [];

	private _subscriptions = new Subscription();

	get isValid(): boolean {
		return this.form.controls[this.question.key].valid;
	}

	private get _itemKeys(): SelectOptionKey[] {
		return this.getKeys(this.items);
	}

	private get _questionControl(): UntypedFormControl {
		return this.form.controls[this.question.key] as UntypedFormControl;
	}

	ngOnInit(): void {
		this.singleSelect = !!this.question.singleSelect;
		this.isSelectButtons = !this.singleSelect;
		this.showAddNewItem = !!this.question.addNewItemOnFilter;
		this.matSelectControl = new UntypedFormControl(this.question.selectedOptions, this.question.validators);
		this.refreshOptions();
		this._subscriptions.add(
			this.question.optionsHasChanged.subscribe(() => {
				this.refreshOptions();
			})
		);

		this._subscriptions.add(
			this.matSelectControl.valueChanges.subscribe((keys: SelectOptionKey[]) => {
				if (!this._questionControl.dirty) {
					this._questionControl.markAsDirty();
				}
				this.question.selectedOptions = keys?.length > 0 ? [...keys] : [];
				this._questionControl.setValue(this.getItemsByKeys(keys));
			})
		);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	selectAll(): void {
		this.matSelectControl.setValue(this._itemKeys);
	}

	clearAll(): void {
		this.matSelectControl.setValue([]);
	}

	addNewItem(value: string): void {
		const key = -(this.items.filter((item: SelectOption) => item.isNew).length + 1);
		this.items.push({ value: value, key: key, isNew: true });
		const currentItems: any[] = Array.isArray(this.matSelectControl.value) ? this.matSelectControl.value : [];
		currentItems.push(key);
		this.matSelectControl.setValue(currentItems);
		this.matSelectExtendedComponent.setItems();
		this.matSelectExtendedComponent.resetSearch();
	}

	changeOpened(): void {
		if (this._questionControl.touched) {
			return;
		}

		this._questionControl.markAsTouched();
	}

	private refreshOptions(): void {
		this.items = this.question?.options ? [...this.question.options] : [];

		const selectedItems = this.getItemsByKeys(this.question.selectedOptions);

		if (!ArrayUtils.isEqual(this._questionControl.value, selectedItems, true)) {
			this._questionControl.setValue(selectedItems);
		}
	}

	private getKeys(items: SelectOption[]): SelectOptionKey[] {
		return items.map((item: SelectOption): SelectOptionKey => item.key);
	}

	private getItemsByKeys(keys: SelectOptionKey[]): SelectOption[] {
		return this.items?.filter((item: SelectOption): boolean => keys?.includes(item.key)) ?? [];
	}
}
