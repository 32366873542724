import { Injectable } from '@angular/core';
import { Sensor, State } from '@fitech-workspace/fisense-common-lib';
import { PlanItem } from '../models/plan-item.model';

@Injectable({
	providedIn: 'root',
})
export class SensorFilterService {
	private readonly _techSensorPrefix = 'tech_';
	private readonly _genAlarmSensorPrefix = 'gen_alarm';
	private readonly _genTextSensorPrefix = 'gen_text';
	private readonly _digiSensorSuffix = '_digi';

	filterStates(sensors: State[]): State[] {
		return this.filterSensors(
			sensors,
			(sensor: State): string => sensor.source.type,
			(sensor: State): boolean => !!(sensor.source as Sensor)?.visible
		);
	}

	filterPlanItems(sensors: PlanItem[]): PlanItem[] {
		return this.filterSensors(
			sensors,
			(sensor: PlanItem): string => sensor.pointType,
			(): boolean => true
		);
	}

	filterStatesOnlyNonTech(sensors: State[]): State[] {
		return sensors.filter((sensor: State): boolean => !(sensor.source as Sensor)?.type?.toLocaleLowerCase().startsWith(this._techSensorPrefix));
	}

	filterStatesOnlyNonDigi(sensors: State[]): State[] {
		return sensors.filter((sensor: State): boolean => !(sensor.source as Sensor)?.type?.toLocaleLowerCase().endsWith(this._digiSensorSuffix));
	}

	private filterSensors<T>(sensors: T[], getSensorType: (sensor: T) => string, getSensorVisibility: (sensor: T) => boolean): T[] {
		return sensors.filter(getSensorVisibility).filter((sensor: T): boolean => {
			const sensorType: string = getSensorType(sensor);
			return (
				!!sensorType &&
				!sensorType.toLocaleLowerCase().startsWith(this._genAlarmSensorPrefix) &&
				!sensorType.toLocaleLowerCase().startsWith(this._genTextSensorPrefix)
			);
		});
	}
}
