import { Injectable } from '@angular/core';
import { UserSettingParameterValueTypeEnum } from '../enums';
import { UserSettingsHelper } from '../helpers';
import { UserSettingDto, UserSettingParameterDto, UserSettingParameterValue, UserSettingsLocationDto } from '../models';

@Injectable({
	providedIn: 'root',
})
export class UserSettingsFactoryService {
	createLocalSimple(
		location: UserSettingsLocationDto,
		name: string,
		parameterKey: string,
		parameterValue: UserSettingParameterValue,
		tag?: string
	): UserSettingDto {
		return {
			id: 0,
			name,
			tag,
			parameters: [this.createSimpleParameter(parameterKey, parameterValue)],
			userSettingsLocation: location,
			isGlobal: false,
		};
	}

	updateWithSimpleParameter(userSetting: UserSettingDto, parameterKey: string, parameterValue: UserSettingParameterValue): UserSettingDto {
		const parameter: UserSettingParameterDto = UserSettingsHelper.getParameterByKey(userSetting, parameterKey);
		const exists = !!parameter;

		return {
			...userSetting,
			parameters: exists
				? userSetting.parameters.map((parameter: UserSettingParameterDto) =>
						parameter.key === parameterKey ? this.updateParameterValue(parameter, parameterValue) : parameter
				  )
				: [...userSetting.parameters, this.createSimpleParameter(parameterKey, parameterValue)],
		};
	}

	createUserSettingsLocation(view: string, section: string, element: string): UserSettingsLocationDto {
		return {
			view,
			section,
			element,
		};
	}

	private updateParameterValue(parameter: UserSettingParameterDto, value: UserSettingParameterValue): UserSettingParameterDto {
		return {
			...parameter,
			value: `${value}`,
		};
	}

	private createSimpleParameter(key: string, value: UserSettingParameterValue): UserSettingParameterDto {
		return {
			id: 0,
			key,
			value: `${value}`,
			type: this.resolveParameterType(value),
		};
	}

	private resolveParameterType(value: UserSettingParameterValue): UserSettingParameterValueTypeEnum {
		if (typeof value === 'boolean') {
			return UserSettingParameterValueTypeEnum.Boolean;
		}
		if (typeof value === 'number') {
			return UserSettingParameterValueTypeEnum.Double;
		}

		return UserSettingParameterValueTypeEnum.String;
	}
}
