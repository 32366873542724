<h1 mat-dialog-title>{{ title | translate }}</h1>
<mat-dialog-content>
	<p *ngIf="isNoHistory">There is no history.</p>
	<div *ngIf="isLogsLoading" class="refreshing-box refreshing-box--no-offset">
		<i class="fas fa-sync fa-spin refresh-spinner"></i>
	</div>

	<div class="logs-container">
		<mat-accordion multi>
			<mat-expansion-panel
				*ngFor="let log of logs$ | async; let first = first; let last = last"
				class="log-panel"
				[ngClass]="{ 'log-panel--first': first, 'log-panel--last': last }"
				hideToggle>
				<mat-expansion-panel-header class="log-panel__header">
					<mat-panel-title class="log-panel__header-title">{{ log.changeType | translate }}:</mat-panel-title>
					<mat-panel-description class="log-panel__header-description">
						<span>{{ log.description | ellipsisText : 50 }}</span>
						<span>{{ log.date | date : 'yyyy-MM-dd HH:mm:ss' }}</span>
					</mat-panel-description>
				</mat-expansion-panel-header>

				<div class="log-panel__content">
					<div class="log-panel__row log-panel__row--first">
						<span class="log-panel__row-title">{{ 'Description' | translate }}:</span>
						<span class="log-panel__row-value">{{ log.description }}</span>
					</div>
					<div class="log-panel__row">
						<span class="log-panel__row-title">{{ 'User' | translate }}:</span>
						<span class="log-panel__row-value">{{ log.userId }}</span>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button type="button" class="btn btn--tertiary" (click)="onCancel()">Cancel</button>
</mat-dialog-actions>
