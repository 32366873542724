<div class="hmi-indicator">
	<div class="alarm-indicator" [ngClass]="{ alarm: state?.alarmIsActive, 'no-alarm': !state?.alarmIsActive }"></div>
	<div class="hmi-content">
		<div class="hmi-content__top">
			<div *ngIf="isPlaceholder" class="hmi-content__placeholder">{{ placeholder }}</div>
			<div class="hmi-content__title">{{ title ?? '-' }}</div>
			<div class="hmi-content__subtitle">{{ subtitle }}</div>
		</div>

		<div *ngIf="state?.source.displayType !== 'discrete'" class="hmi-content__analog-value">
			{{ showRawValue ? state?.rawValue : state?.value }}
			<span class="hmi-content__analog-units">{{ state?.source.units }}</span>
		</div>
		<div
			*ngIf="state?.source.displayType === 'discrete'"
			class="hmi-content__discrete-value"
			[ngClass]="{ 'hmi-content__discrete-value--on': state?.value > 0, 'hmi-content__discrete-value--nok': state?.alarmIsActive }">
			<span>{{ state?.value > 0 ? 'ACTIVE' : ('NOT ACTIVE' | translate) }}</span>
		</div>
	</div>
	<div class="hmi-content__buttons" *ngIf="showButtons">
		<button class="hmi-content__btn" (click)="toggleChart()">
			<i class="fas grid-action-element" [ngClass]="isAddedToReport ? 'fa-trash hmi-content__remove-icon' : 'fa-plus add-machine-sensor'"></i>
		</button>
	</div>
</div>
