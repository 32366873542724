import { HttpErrorResponse } from '@angular/common/http';
import { GlobalUserSettingDto, UserSettingDto } from '@fitech-workspace/core-lib';
import { createAction, props } from '@ngrx/store';

export const getUserSettings = createAction(
	'[UserSettings/API] Get User Settings',
	props<{ view?: string; section?: string; element?: string; settingTag?: string; skipGlobal?: boolean }>()
);

export const getUserSettingsSuccess = createAction(
	'[UserSettings/API] Get User Settings Success',
	props<{ settings: UserSettingDto[]; skipGlobal: boolean; isLocationFiltered: boolean; isTagFiltered: boolean }>()
);

export const getGlobalUserSettings = createAction('[UserSettings/API] Get Global User Settings', props<{ userSettingTag?: string }>());

export const getGlobalUserSettingsSuccess = createAction('[UserSettings/API] Get Global User Settings Success', props<{ settings: GlobalUserSettingDto[] }>());

export const getUserSetting = createAction('[UserSettings/API] Get User Setting', props<{ userSettingId: number }>());

export const getUserSettingSuccess = createAction('[UserSettings/API] Get User Setting Success', props<{ setting: UserSettingDto }>());

export const getBatchUserSettings = createAction('[UserSettings/API] Get Batch User Setting', props<{ userSettingIds: number[] }>());

export const getBatchUserSettingsSuccess = createAction('[UserSettings/API] Get Batch User Setting Success', props<{ userSettings: UserSettingDto[] }>());

export const createGlobalUserSetting = createAction('[UserSettings/API] Create Global User Setting', props<{ globalUserSetting: GlobalUserSettingDto }>());

export const createGlobalUserSettingSuccess = createAction(
	'[UserSettings/API] Create Global User Setting Success',
	props<{ globalUserSetting: GlobalUserSettingDto }>()
);

export const updateGlobalUserSetting = createAction('[UserSettings/API] Update Global User Setting', props<{ globalUserSetting: GlobalUserSettingDto }>());

export const updateGlobalUserSettingSuccess = createAction(
	'[UserSettings/API] Update Global User Setting Success',
	props<{ globalUserSetting: GlobalUserSettingDto }>()
);

export const upsertBatchUserSettings = createAction('[UserSettings/API] Upsert User Settings', props<{ userSettings: UserSettingDto[] }>());

export const upsertBatchUserSettingsSuccess = createAction('[UserSettings/API] Upsert User Settings Success', props<{ userSettings: UserSettingDto[] }>());

export const upsertBatchUserSettingsFailure = createAction('[UserSettings/API] Upsert User Settings Failure', props<{ error: HttpErrorResponse }>());

export const deleteUserSettingById = createAction('[UserSettings/API] Delete User Setting By Id', props<{ userSettingId: number }>());

export const deleteUserSettingByIdSuccess = createAction('[UserSettings/API] Delete User Setting By Id Success', props<{ userSettingId: number }>());

export const deleteUserSettingByName = createAction(
	'[UserSettings/API] Delete User Setting By Name',
	props<{ userSettingName: string; view?: string; section?: string; element?: string }>()
);

export const deleteUserSettingByNameSuccess = createAction(
	'[UserSettings/API] Delete User Setting By Name Success',
	props<{ userSettingName: string; view?: string; section?: string; element?: string }>()
);

export const deleteBatchUserSettings = createAction('[UserSettings/API] Delete Batch User Settings', props<{ userSettingIds: number[] }>());

export const deleteBatchUserSettingsSuccess = createAction('[UserSettings/API] Delete Batch User Settings Success', props<{ userSettingIds: number[] }>());

export const deleteAllUserSettings = createAction('[UserSettings/API] Delete All User Settings');

export const deleteAllUserSettingsSuccess = createAction('[UserSettings/API] Delete All User Settings Success');

export const pushBatchedUserSettings = createAction('[UserSettings] Push batched user settings', props<{ userSettings: UserSettingDto[] }>());

export const checkBatchedUserSettings = createAction('[UserSettings] Check batched user settings');
