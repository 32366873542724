<div
	[formGroup]="form"
	class="dynamic-input-form"
	[ngClass]="{ 'dynamic-edit-input': showUndoButton, dirty: form.controls[question.key].dirty && showUndoButton && form.controls[question.key].valid }"
	(keydown.enter)="$event.preventDefault()">
	<mat-icon
		*ngIf="question.isGroupEditInconsistent"
		class="icon icon--group-inconsistency"
		aria-hidden="false"
		matTooltip="Property has different values in selected rows">
		double_arrow
	</mat-icon>
	<mat-form-field class="dynamic-input-form">
		<mat-label>{{ question.label | translate }}</mat-label>
		<div class="input-icon-container">
			<i *ngIf="question.icon" class="icons-fontawesome input-icon" [ngClass]="question.icon"></i>
			<input
				type="text"
				[id]="question.key"
				placeholder="Start typing to search..."
				aria-label="Number"
				matInput
				class="dynamic-input"
				[formControlName]="question.key"
				[matAutocomplete]="autoObjectType" />
		</div>
		<mat-autocomplete autoActiveFirstOption #autoObjectType="matAutocomplete" [displayWith]="generateDisplayText.bind(this)">
			<mat-option *ngFor="let opt of filteredOptions | async" [value]="opt">
				<i *ngIf="opt.icon" class="icons-fontawesome" [ngClass]="opt.icon"></i>
				{{ opt.display || opt.value }}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
	<button
		*ngIf="form.controls[question.key].dirty && showUndoButton"
		mat-icon-button
		matTooltip="Undo changes"
		aria-label="Undo changes"
		class="btn-undo"
		(click)="undo()">
		<mat-icon>undo</mat-icon>
	</button>
</div>
