import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { HttpParamsBuilder } from '@fitech-workspace/core-lib';
import { BACKEND_API_HOSTMASTERKEY, BACKEND_API_URL, BACKEND_CLIENT_TAG, MeasurementHistoryMinMaxDto } from '@fitech-workspace/fisense-common-lib';
import { asEnumerable } from 'linq-es2015';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { State } from '../models/state.model';

@Injectable({
	providedIn: 'root',
})
export class MeasurementsApiService {
	protected apiUrl: string;
	protected clientTag: string;
	protected apiHostMasterKey: string;

	private get _measurementsApiUrl(): string {
		return `${this.apiUrl}measurements`;
	}

	constructor(
		protected httpClient: HttpClient,
		@Optional()
		@Inject(BACKEND_API_URL)
		trackersApiUrl?: string,
		@Optional()
		@Inject(BACKEND_API_HOSTMASTERKEY)
		apiHostMasterKey?: string,
		@Optional()
		@Inject(BACKEND_CLIENT_TAG)
		clientTag?: string
	) {
		this.apiUrl = trackersApiUrl;
		this.apiHostMasterKey = apiHostMasterKey;
		this.clientTag = clientTag;
	}

	getCurrentStates(locationId: number, onlyWithSamplingMs?: boolean, sensorsGroupType?: string, sensorSourceProperties?: string[]): Observable<State[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create()
			.append('code', this.apiHostMasterKey)
			.append('locationId', locationId)
			.append('onlyWithSamplingMs', onlyWithSamplingMs)
			.appendString('sensorsGroupType', sensorsGroupType)
			.appendArray('sensorSourceProperties', sensorSourceProperties);

		return this.httpClient.get<State[]>(`${this._measurementsApiUrl}/current`, { params: httpParamsBuilder.build() }).pipe(
			map((res: State[]) => {
				return res.map((data: State) => new State(data));
			})
		);
	}

	getStateHistory(from: Date, to: Date, sensorId?: number, frequency?: number): Observable<State[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create()
			.append('from', moment(from).toISOString())
			.append('to', moment(to).toISOString())
			.append('code', this.apiHostMasterKey)
			.append('frequencyInSeconds', frequency)
			.append('sensorId', sensorId)
			.appendArray('sensorIds', [sensorId]);

		return this.httpClient.get<MeasurementHistoryMinMaxDto[]>(`${this._measurementsApiUrl}/history-aggregated`, { params: httpParamsBuilder.build() }).pipe(
			map((res: MeasurementHistoryMinMaxDto[]) => {
				return asEnumerable(res)
					.Select((data: MeasurementHistoryMinMaxDto) => {
						return {
							timestamp: data.timestamp,
							value: data.value || 0,
							min: data.min,
							max: data.max,
						};
					})
					.ToArray() as State[];
			})
		);
	}
}
