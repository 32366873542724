import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloLink } from '@apollo/client/core';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { NotificationService } from '@fitech-workspace/core-lib';
import { GraphQLError } from 'graphql';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class GraphQlResponseHandlingService {
	constructor(private _notificationService: NotificationService) {}

	createErrorLink(): ApolloLink {
		return onError((errorResponse: ErrorResponse) => {
			let showedError = false;

			if (errorResponse.graphQLErrors?.length) {
				const messages: string[] = errorResponse.graphQLErrors.map((value: GraphQLError) => value.message);
				this._notificationService.showError(messages.join('\r\n'));
				showedError = true;
			}
			if (errorResponse.networkError && errorResponse.networkError instanceof HttpErrorResponse) {
				const httpErrorResponse = errorResponse.networkError as HttpErrorResponse;
				const errors: string[] = httpErrorResponse?.error?.errors.map((error: { message: string }): string => error?.message ?? '') ?? [];
				if (errors?.length) {
					this._notificationService.showError(errors.join('\r\n'));
					showedError = true;
				}
			}
			if (!showedError) {
				this._notificationService.showError('An error occurred while processing your request.');
			}
		});
	}

	handleRequest<T>(request$: Observable<T>): Observable<T> {
		return request$.pipe(tap((results: any) => this.handleSuccess(results)));
	}

	handleSuccess(results: any): void {
		let resCount = 1;

		if (results && results['successed']) {
			resCount = results['successed'].length;
		} else if (Array.isArray(results)) {
			resCount = results.length;
		}

		this._notificationService.showSuccess(`Successfully saved changes (${resCount}).`);
	}
}
