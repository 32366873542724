import { ComponentType } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@fitech-workspace/core-lib';
import { IChartConfig, IChartSeriesConfig, IMarkAreasConfig } from '@fitech-workspace/shared/ui/chart-lib';
import { Observable, of } from 'rxjs';
import { concatMap, delay, take, tap } from 'rxjs/operators';
import { ChartDialogComponent } from '../components/chart-dialog/chart-dialog.component';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { GeneralDialogComponent } from '../components/general-dialog/general-dialog.component';
import { HistoryDialogComponent } from '../components/history-dialog/history-dialog.component';
import { HistoryDialogQlComponent } from '../components/history-dialog-ql/history-dialog-ql.component';
import { SimpleConfirmationDialogComponent } from '../components/simple-confirmation-dialog/simple-confirmation-dialog.component';
import { IConfirmationDialogOptions } from '../models/confirmation-dialog-options.model';
import { ICustomDialogOptions } from '../models/custom-dialog-options.model';
import { IGeneralDialogOptions } from '../models/general-dialog-options.model';
import { HistoryLog } from '../models/history-log.model';
import { ISimpleConfirmationDialogOptions } from '../models/simple-confirmation-dialog-options.model';

@Injectable({
	providedIn: 'root',
})
export class DialogService {
	isDialogOpen = false;
	autoFocus = false;
	dialogWidth = '70%';
	dialogMinWidth = '400px';

	private _renderer: Renderer2;

	constructor(
		public dialog: MatDialog,
		@Inject(DOCUMENT) private _document: Document,
		private _translateService: TranslateService,
		private _rendererFactory: RendererFactory2
	) {
		this._renderer = this._rendererFactory.createRenderer(null, null);
	}

	openHistoryDialog(logs: HistoryLog[], title?: string): void {
		if (this.isDialogOpen) {
			return;
		}

		this.isDialogOpen = true;

		const dialogRef = this.dialog.open(HistoryDialogComponent, {
			width: this.dialogWidth,
			autoFocus: this.autoFocus,
			data: {
				logs: logs,
				title: title ? title : this._translateService.instant('Actions history'),
			},
			disableClose: true,
		});

		this.setDialogRefMethods(dialogRef);
	}

	openHistoryDialogQl(logs$: Observable<HistoryLog[]>, title?: string): void {
		if (this.isDialogOpen) {
			return;
		}

		this.isDialogOpen = true;

		const dialogRef = this.dialog.open(HistoryDialogQlComponent, {
			width: this.dialogWidth,
			autoFocus: this.autoFocus,
			data: {
				logs$: logs$,
				title: title ? title : this._translateService.instant('Actions history'),
			},
			disableClose: true,
		});

		this.setDialogRefMethods(dialogRef);
	}

	openConfirmationDialog(options: IConfirmationDialogOptions): void {
		if (this.isDialogOpen) {
			return;
		}

		this.isDialogOpen = true;

		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: options.dialogWidth ? options.dialogWidth : this.dialogWidth,
			autoFocus: this.autoFocus,
			disableClose: true,
		});

		dialogRef.componentInstance.initialize(options);

		this.setDialogRefMethods(dialogRef);
	}

	openSimpleConfirmationDialog(options: ISimpleConfirmationDialogOptions): MatDialogRef<SimpleConfirmationDialogComponent, boolean> {
		if (this.isDialogOpen) {
			return;
		}
		this.isDialogOpen = true;

		const dialogRef = this.dialog.open<SimpleConfirmationDialogComponent, ISimpleConfirmationDialogOptions, boolean>(SimpleConfirmationDialogComponent, {
			width: options.dialogWidth || this.dialogMinWidth,
			autoFocus: options.autoFocus ? options.autoFocus : this.autoFocus,
			data: {
				title: options.title ? options.title : 'Confirmation alert',
				content: options.content,
			},
			disableClose: true,
		});

		this.setDialogRefMethods(dialogRef);

		return dialogRef;
	}

	openGeneralDialog(options: IGeneralDialogOptions): void {
		if (this.isDialogOpen) {
			return;
		}

		this.isDialogOpen = true;

		const dialogRef = this.dialog.open(GeneralDialogComponent, {
			minWidth: options.minWidth || this.dialogMinWidth,
			width: options.width || this.dialogWidth,
			maxWidth: options.maxWidth,
			autoFocus: this.autoFocus,
			disableClose: true,
		});

		dialogRef.componentInstance.initialize(options);

		this.setDialogRefMethods(dialogRef);
	}

	openChartDialog(title: string, subTitle: string, chartConfig: IChartConfig, seriesConfigs: IChartSeriesConfig[], markAreasConfig?: IMarkAreasConfig): void {
		if (this.isDialogOpen) {
			return;
		}

		this.isDialogOpen = true;

		const dialogRef = this.dialog.open(ChartDialogComponent, {
			width: this.dialogWidth,
			autoFocus: this.autoFocus,
			disableClose: true,
		});

		dialogRef.componentInstance.initialize(title, subTitle, chartConfig, seriesConfigs, markAreasConfig);

		this.setDialogRefMethods(dialogRef);
	}

	openCustomDialog<T>(dialogComponent: ComponentType<T>, options: ICustomDialogOptions): MatDialogRef<T> {
		if (this.isDialogOpen) {
			return;
		}

		this.isDialogOpen = true;

		const dialogRef = this.dialog.open<T>(dialogComponent, {
			minWidth: options.minWidth || this.dialogMinWidth,
			width: options.width || this.dialogWidth,
			maxWidth: options.maxWidth,
			height: options.height,
			autoFocus: this.autoFocus,
			disableClose: options.hasBackdrop ?? true,
			backdropClass: options.hasBackdrop ? 'mat-dialog-backdrop' : null,
			panelClass: 'custom-dialog',
			data: options.data,
		});

		let observer: ResizeObserver;
		dialogRef.afterOpened().subscribe(() => (observer = this.attachResizeObserverToContent()));
		dialogRef.afterClosed().subscribe(() => observer?.disconnect());
		this.setDialogRefMethods(dialogRef);

		return dialogRef;
	}

	private animateMatDialog(): Observable<boolean> {
		const modalElement = this._document.querySelector('.mat-mdc-dialog-container') as HTMLElement;

		if (modalElement) {
			modalElement.style.transform = 'scale(1.1)';
			return of(true).pipe(
				delay(300),
				take(1),
				tap(() => {
					modalElement.style.transform = 'scale(1)';
				})
			);
		} else {
			return of(undefined);
		}
	}

	private setDialogRefMethods(dialogRef: MatDialogRef<any>): void {
		dialogRef
			.afterOpened()
			.pipe(
				take(1),
				tap(() => {
					this.isDialogOpen = true;
				})
			)
			.subscribe();

		dialogRef
			.afterClosed()
			.pipe(
				take(1),
				tap(() => {
					this.isDialogOpen = false;
				})
			)
			.subscribe();

		dialogRef
			.backdropClick()
			.pipe(concatMap(() => this.animateMatDialog()))
			.subscribe();
	}

	private attachResizeObserverToContent(): ResizeObserver {
		const content = this._document.getElementsByClassName('mdc-dialog__content')[0];

		if (!content) {
			return;
		}

		const observer = new ResizeObserver(() => {
			return this.addClassToContentIfScrollPresent(content);
		});
		observer.observe(content);

		return observer;
	}

	private addClassToContentIfScrollPresent(contentElement: Element): void {
		const hasVerticalScrollbar = contentElement.scrollHeight > contentElement.clientHeight;

		if (hasVerticalScrollbar && !contentElement.classList.contains('has-vertical-scrollbar')) {
			this._renderer.addClass(contentElement, 'has-vertical-scrollbar');
		}

		if (!hasVerticalScrollbar && contentElement.classList.contains('has-vertical-scrollbar')) {
			this._renderer.removeClass(contentElement, 'has-vertical-scrollbar');
		}
	}
}
