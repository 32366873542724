<h1 mat-dialog-title>{{ headerLabel }}</h1>
<div mat-dialog-content>
	<p class="mat-body-2" *ngFor="let message of messages">{{ message.label | translate }}: {{ message.value | translate }}</p>
</div>
<div mat-dialog-actions align="end">
	<button type="button" class="btn btn--tertiary btn--tertiary-wider" (click)="onCancel()">
		{{ 'Cancel' | translate }}
	</button>
	<button type="button" class="btn btn--primary btn--primary-wider" (click)="onConfirm()" cdkFocusInitial [disabled]="isSubmitting">
		<i *ngIf="isSubmitting" class="fas fa-sync fa-spin refresh-spinner icon icon--mr-4"></i>
		{{ 'Confirm' | translate }}
	</button>
</div>
