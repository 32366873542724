<h1 mat-dialog-title>{{ headerLabel | titlecase | translate }}</h1>
<div mat-dialog-content id="dialog-content" [ngStyle]="{ 'max-height': contentMaxHeight }">
	<fitech-workspace-dynamic-form
		[questions]="questions"
		[isSubmitting]="isSubmitting"
		[isSubmitted]="isSubmitted"
		[showUndoButton]="showUndoButton"
		(saved)="onConfirm($event)"
		(cancelled)="onCancel()">
	</fitech-workspace-dynamic-form>
</div>
