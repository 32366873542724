<div class="mat-dialog-header">
	<div class="mat-dialog-header__group">
		<h5 class="mat-dialog-header__title" mat-dialog-title>
			{{ title }}
		</h5>
		<span class="mat-dialog-header__subtitle">{{ subtitle }}</span>
	</div>

	<button mat-icon-button (click)="onCancel()">
		<mat-icon>close</mat-icon>
	</button>
</div>
<div id="dialog-content" mat-dialog-content>
	<fitech-workspace-line-chart #lineChart></fitech-workspace-line-chart>
</div>
<mat-dialog-actions>
	<button mat-button class="btn btn--tertiary" (click)="onCancel()">Back</button>
</mat-dialog-actions>
