import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '@fitech-workspace/core-lib';
import { Subscription } from 'rxjs';
import { VersionsApiService } from '../../api/versions-api.service';
import { VersionsDto } from '../../models/versions-dto.model';
@Component({
	selector: 'fitech-workspace-versions-dialog',
	templateUrl: './versions-dialog.component.html',
	styleUrls: ['./versions-dialog.component.scss'],
})
export class VersionsDialogComponent implements OnInit, OnDestroy {
	versions: VersionsDto;
	refreshing = false;

	private _apiSubscription: Subscription;

	constructor(
		public dialogRef: MatDialogRef<VersionsDialogComponent>,
		private _versionsApiService: VersionsApiService,
		private _notificationsService: NotificationService
	) {}

	ngOnInit(): void {
		this.getVersionsFromApi();
	}

	ngOnDestroy(): void {
		this._apiSubscription?.unsubscribe();
	}

	refresh(): void {
		this.getVersionsFromApi(true);
	}

	closeModal(): void {
		this.dialogRef.close();
	}

	private getVersionsFromApi(force: boolean = false): void {
		this._apiSubscription?.unsubscribe();
		this._apiSubscription = null;
		this.refreshing = true;

		this._apiSubscription = this._versionsApiService.getVersions(force).subscribe(
			(result: VersionsDto) => {
				this.versions = result;
			},
			(error: HttpErrorResponse) => {
				switch (error.status) {
					case HttpStatusCode.Forbidden:
						this._notificationsService.showError(`The user doesn't have permission to read versions.`);
						break;
					default:
						this._notificationsService.showError(`Error while loading versions from API.`);
				}
			},
			() => (this.refreshing = false)
		);
	}
}
