import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorHandlingService } from '@fitech-workspace/core-lib';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HistoryLog } from '../../models/history-log.model';

@Component({
	selector: 'fitech-workspace-history-dialog-ql',
	templateUrl: './history-dialog-ql.component.html',
	styleUrls: ['./history-dialog-ql.component.scss'],
})
export class HistoryDialogQlComponent implements OnInit {
	logs$: Observable<HistoryLog[]>;
	title: string;
	isNoHistory: boolean;
	isLogsLoading = true;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { logs$: Observable<HistoryLog[]>; title?: string },
		public dialogRef: MatDialogRef<HistoryDialogQlComponent>,
		private _errorHandlingService: ErrorHandlingService
	) {}

	ngOnInit(): void {
		this.logs$ = this.data.logs$.pipe(
			map((logs: HistoryLog[]) => {
				this.isLogsLoading = false;
				this.isNoHistory = !logs.length;
				return logs.map((log: HistoryLog) => {
					log.date = moment(log.date).toString();
					return log;
				});
			}),
			catchError((error: HttpErrorResponse) => {
				this.isLogsLoading = false;
				this._errorHandlingService.handleError(error);
				return of(null);
			})
		);

		this.title = this.data.title;
	}

	onCancel(): void {
		this.dialogRef.close({ result: false, shouldReloadTargets: false });
	}
}
