import { NgModule } from '@angular/core';
import { CoreLibModule } from '@fitech-workspace/core-lib';
import { SharedUiIconsModule } from '@fitech-workspace/shared/ui/controls-lib';
import { ButtonCsvExportComponent } from './components/button-csv-export/button-csv-export.component';
import { ButtonRefreshComponent } from './components/button-refresh/button-refresh.component';
import { ButtonWithMenuDialogComponent } from './components/button-with-menu-dialog/button-with-menu-dialog.component';

@NgModule({
	imports: [CoreLibModule, SharedUiIconsModule],
	declarations: [ButtonWithMenuDialogComponent, ButtonRefreshComponent, ButtonCsvExportComponent],
	exports: [ButtonWithMenuDialogComponent, ButtonRefreshComponent, ButtonCsvExportComponent],
})
export class SharedUiButtonLibModule {}
