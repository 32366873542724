<div class="sensors-container" #mainContainer>
	<div class="group">
		<div class="filter-container">
			<label class="label-filter" for="filter">Filter:</label>
			<input id="quick-search-input" class="form-control" name="filter" type="text" [formControl]="searchFilterControl" />
		</div>
	</div>
	<div class="group" *ngFor="let groupDec of filteredGroupDeclarationToDisplay">
		<div class="group__title">{{ groupDec.label }}</div>
		<div class="group__container">
			<fitech-workspace-hmi-indicator
				*ngFor="let state of groupDec.data; trackBy: trackByStateId"
				[ngClass]="{ 'for-wide-containers-1000': isWideContainer1000, 'for-wide-containers-1500': isWideContainer1500 }"
				[state]="state"
				[isAddedToReport]="trendSensorIds.includes(state?.sourceId)"
				(chartToggled)="toggleChart($event)">
			</fitech-workspace-hmi-indicator>
		</div>
	</div>
</div>
