/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Injector, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NgControl, NgModel } from '@angular/forms';

@Component({
	template: '',
})
export abstract class FormControlValueAccessorConnectorComponent implements OnInit, ControlValueAccessor {
	isControl = false;
	formControl: FormControl<any>;

	onChange: (obj: any) => void;
	onTouched: () => void;
	ngControl: NgControl | undefined;

	constructor(protected injector: Injector) {}

	ngOnInit(): void {
		const ngControl: NgControl = this.injector.get(NgControl, null, { optional: true });
		if (ngControl instanceof FormControlDirective || ngControl instanceof FormControlName || ngControl instanceof NgModel) {
			this.ngControl = ngControl;
			this.isControl = !!ngControl;
			if (this.isControl) {
				switch (ngControl.constructor) {
					case NgModel: {
						const { control } = ngControl as NgModel;
						this.formControl = control;
						break;
					}
					case FormControlName: {
						this.formControl = this.injector.get(FormGroupDirective).getControl(ngControl as FormControlName);
						break;
					}
					default: {
						this.formControl = (ngControl as FormControlDirective).form as FormControl;
						break;
					}
				}
			}
		}
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	writeValue(obj: any): void {
		if (this.onChange) {
			this.onChange(obj);
		}
	}
}
