import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HistoryLog } from '@fitech-workspace/shared/ui/dialog-lib';
import { Observable } from 'rxjs';

export const AUDIT_API_URL = new InjectionToken<string>('AUDIT_API_URL');

@Injectable({
	providedIn: 'root',
})
export class AuditQlRepository {
	protected apiUrl: string;

	constructor(
		protected httpClient: HttpClient,
		@Inject(AUDIT_API_URL)
		apiUrl: string
	) {
		this.apiUrl = apiUrl;
	}

	getChanges(tableName: string, keyValue: number): Observable<HistoryLog[]> {
		const url = `/api/audit/${tableName}/${keyValue}`;
		return this.httpClient.get<HistoryLog[]>(this.apiUrl + url);
	}
}
