import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { NotificationService } from '@fitech-workspace/core-lib';
import { MasterUsersService, SetPasswordCmd } from '@fitech-workspace/master-data-lib';
import { SetPasswordForm } from '../../models';
import { AUTH_ENV_CONFIG, UserService } from '../../services/user.service';

@Component({
	selector: 'fitech-workspace-password-set',
	templateUrl: './password-set.component.html',
	styleUrls: ['./password-set.component.scss'],
})
export class PasswordSetComponent implements OnInit {
	@Input() logoSrc: string;

	isInProgress: boolean;
	setPasswordForm: FormGroup<SetPasswordForm>;
	isSubmitting = false;
	isSubmitted = false;
	emailParam = '';
	codeParam = '';

	private _environmentConfig;

	get emailControl(): AbstractControl<string> {
		return this.setPasswordForm.get('email');
	}

	get newPasswordControl(): AbstractControl<string> {
		return this.setPasswordForm.get('newPassword');
	}

	get confirmPasswordControl(): AbstractControl<string> {
		return this.setPasswordForm.get('confirmPassword');
	}

	constructor(
		@Inject(AUTH_ENV_CONFIG) environmentConfig: any,
		private _notificationService: NotificationService,
		private _formBuilder: FormBuilder,
		private _userService: UserService,
		private _masterUsersService: MasterUsersService,
		private _route: ActivatedRoute
	) {
		this._environmentConfig = environmentConfig;
		if (this._environmentConfig) {
			this.logoSrc = this._environmentConfig.logoSrc;
		}

		this._route.queryParams.subscribe((params: Params) => {
			this.emailParam = params['email'];
			this.codeParam = params['code'];
		});
	}

	ngOnInit(): void {
		this.createFormGroups(this._formBuilder);
	}

	createFormGroups(fb: FormBuilder): void {
		this.setPasswordForm = fb.group(
			{
				email: new FormControl<string>(''),
				newPassword: new FormControl<string>(''),
				confirmPassword: new FormControl<string>(''),
			},
			{ validator: this.checkPasswords }
		);

		this.emailControl.setValidators([Validators.required, Validators.email]);
		this.newPasswordControl.setValidators([Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,100}')]);

		if (this.emailParam) {
			this.emailControl.setValue(this.emailParam);
		}
	}

	checkPasswords(form: FormGroup): any {
		const pass = form.controls.newPassword.value;
		const confirmPass = form.controls.confirmPassword.value;

		let result = null;
		if (pass !== confirmPass) {
			form.controls.confirmPassword.setErrors({ notSamePassword: true });
			result = { notSamePassword: true };
		}
		return result;
	}

	shouldDisableAction(): boolean {
		return this.isSubmitting || this.isSubmitted || this.emailControl.invalid || this.newPasswordControl.invalid || this.confirmPasswordControl.invalid;
	}

	setPassword(): void {
		this.isSubmitting = true;
		const setPasswordReq = this.createSetPasswordReq();
		this._masterUsersService.setPassword(setPasswordReq).subscribe(
			() => {
				this.isSubmitting = false;
				this.isSubmitted = true;
				this._notificationService.showSuccess('Password was set.');
				this.login(setPasswordReq.email, setPasswordReq.password);
			},
			(err: any) => {
				this.isSubmitting = false;

				if (err.response != null && err.response.toString().toLowerCase().includes('invalidtoken')) {
					this._notificationService.showInfo('Password was not set. Confirmation e-mail expired. Please ask an administrator for a new one.');
				} else {
					this._notificationService.showInfo('Password was not set.');
				}
			}
		);
	}

	login(userName: string, password: string): void {
		this._userService.loginWith(userName, password).subscribe();
	}

	createSetPasswordReq(): SetPasswordCmd {
		const setPasswordReq: SetPasswordCmd = {
			email: this.emailControl.value,
			password: this.newPasswordControl.value,
			confirmPassword: this.confirmPasswordControl.value,
			code: this.codeParam,
		};
		return setPasswordReq;
	}

	keyDownFunction(event: any): void {
		if (event.keyCode === 13) {
			this.setPassword();
		}
	}

	isValid(key: any): boolean {
		return this.setPasswordForm.controls[key].valid;
	}

	isTouched(key: any): boolean {
		return this.setPasswordForm.controls[key].touched;
	}

	getErrorMsg(key: any): string {
		let errorMsg = '';
		if (this.isTouched(key)) {
			if (this.setPasswordForm.controls[key].hasError('required')) {
				errorMsg += 'Field is required. ';
			}
			if (this.setPasswordForm.controls[key].hasError('email')) {
				errorMsg += 'Field does not meet e-mail pattern requirement. ';
			}
			if (this.setPasswordForm.controls[key].hasError('pattern')) {
				errorMsg += 'The password must be at least 6 characters long. Must have lower and upper case and digit. ';
			}
			if (key === 'confirmPassword' && this.setPasswordForm.controls[key].parent.hasError('notSamePassword')) {
				errorMsg += 'The password and confirmation password do not match. ';
			}
		}
		return errorMsg;
	}
}
