import { Component, OnInit, Input } from '@angular/core';
import { startWith, map } from 'rxjs/operators';
import { AsEnumerable } from 'linq-es2015';
import { Observable } from 'rxjs';
import { IconsService } from '../../services/icons.service';
import { Icon } from '../../models/icon.model';

@Component({
  selector: 'fitech-workspace-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconsComponent implements OnInit {
  constructor(private icons: IconsService) { }
  @Input() iconKey: string;
  icon: Icon;

  ngOnInit() {
    this.icon = this.icons.getIcon(this.iconKey);
  }
}
