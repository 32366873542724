import { UserDto } from '@fitech-workspace/auth-lib';
import { EntityBase, IDataInfo, IDataInfoProvider } from '@fitech-workspace/core-lib';
import { ChipsDataProvider, IAvatarData, IAvatarDataProvider, IChipsData } from '@fitech-workspace/shared/ui/grid-lib';

export class MasterUserData implements IAvatarDataProvider, ChipsDataProvider, IDataInfoProvider, EntityBase {
	id: number;
	user: UserDto;

	constructor(data: UserDto) {
		this.user = data;
		this.id = data.id;
	}

	getAvatarData(): IAvatarData {
		const avatarData: IAvatarData = { value: null, letter: null, color: null };
		if (this.user) {
			if (this.user.clientName) {
				avatarData.value = this.user.clientName;
				avatarData.letter = this.user.clientName.toString().charAt(0);
				avatarData.color = MasterUserData.getClientClassName(this.user.clientName);
			}
		}
		return avatarData;
	}

	getChips(field: string, cssClass: string): IChipsData[] {
		const chips: IChipsData[] = [];
		if (!Array.isArray(this.user[field])) {
			const val = this.user[field];
			chips.push(this.getChipsData(val, field, cssClass));
		} else {
			this.user[field].forEach((val: any) => {
				chips.push(this.getChipsData(val, field, cssClass));
			});
		}
		chips.sort((a: IChipsData, b: IChipsData) => (a.value > b.value ? 1 : a.value < b.value ? -1 : 0));
		return chips;
	}

	getChipsData(value: any, field: string, cssClass: string): IChipsData {
		if (field === 'roles') {
			const chips: IChipsData = {
				cssClass: cssClass ? cssClass : MasterUserData.getClassName(value.name),
				value: value.name,
			};
			return chips;
		}

		if (field === 'userAllowedClients') {
			const chips: IChipsData = {
				cssClass: cssClass ? cssClass : MasterUserData.getClientClassName(value.clientName),
				value: value.clientName,
			};
			return chips;
		}
	}

	getDataInfo(): IDataInfo {
		const menuOptions: IDataInfo = {
			id: this.user.id,
			label: this.user.email,
			description: this.user.fullName + ': ' + this.user.email,
			data: this.user,
		};
		return menuOptions;
	}

	static getClientClassName(name: string): string {
		if (name) {
			const letter = name.charAt(0);
			return 'color-' + letter;
		}
	}

	static getClassName(value: any): string {
		if (!value) {
			return '';
		}
		const className = 'color-' + this.removeSpecialChars(value.toString().toLowerCase());
		return className;
	}

	static removeSpecialChars(value: string): string {
		if (value) {
			return value.replace(/[\. ,&:-]+/g, '');
		}
		return value;
	}
}
