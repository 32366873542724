import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CmUser } from '../../shared/cm-user.model';
import { NavLinkParams } from '../../shared/models/nav-link-params.model';
import { CmUserService } from '../../shared/services';
import { AppNavLinkTitleEnum } from '../enums/app-navlink-title.enum';

@Injectable({
	providedIn: 'root',
})
export class AppNavLinksService {
	private _navLinks: NavLinkParams[] = [
		{
			title: AppNavLinkTitleEnum.Reports,
			isVisible: false,
			routerLink: '/reports',
		},
		{
			title: AppNavLinkTitleEnum.Monitoring,
			isVisible: false,
			routerLink: '/monitoring',
		},
		{
			title: AppNavLinkTitleEnum.Alarms,
			isVisible: false,
			routerLink: '/alarms',
		},
		{
			title: AppNavLinkTitleEnum.Management,
			isVisible: false,
			routerLink: '/management',
		},
		{
			title: AppNavLinkTitleEnum.Recipes,
			isVisible: false,
			routerLink: '/recipes',
		},
		{
			title: AppNavLinkTitleEnum.UpdatesManagement,
			isVisible: false,
			routerLink: '/software-packages',
		},
		{
			title: AppNavLinkTitleEnum.Components,
			isVisible: false,
			routerLink: '/components',
		},
		{
			title: AppNavLinkTitleEnum.Dashboard,
			isVisible: false,
			routerLink: '/dashboard',
		},
	];

	get navLinks$(): Observable<NavLinkParams[]> {
		return this._cmUserService.currentUser$.pipe(
			map((user: CmUser) =>
				this._navLinks.map((link: NavLinkParams) => {
					switch (link.title) {
						case AppNavLinkTitleEnum.Reports:
							return { ...link, isVisible: user.isReports };
						case AppNavLinkTitleEnum.Monitoring:
							return { ...link, isVisible: user.isMonitoring };
						case AppNavLinkTitleEnum.Alarms:
							return { ...link, isVisible: user.isMachinesAlarms };
						case AppNavLinkTitleEnum.Management:
							return { ...link, isVisible: user.isManagement };
						case AppNavLinkTitleEnum.Recipes:
							return { ...link, isVisible: user.isRecipes };
						case AppNavLinkTitleEnum.Components:
							return { ...link, isVisible: user.isComponentsManagement };
						case AppNavLinkTitleEnum.UpdatesManagement:
							return { ...link, isVisible: user.isUpdatesManagement };
						case AppNavLinkTitleEnum.Dashboard:
							return { ...link, isVisible: user.isDashboard };
					}
				})
			)
		);
	}

	constructor(private _cmUserService: CmUserService) {}
}
