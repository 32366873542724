import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@fitech-workspace/core-lib';
import { GlobalService } from '@fitech-workspace/master-data-lib';

export const getClientsResolver: ResolveFn<boolean> = () => {
	const globalService: GlobalService = inject(GlobalService);

	return globalService.getClients().pipe(
		catchError(() => {
			const notificationService: NotificationService = inject(NotificationService);
			notificationService.showError('Loading clients failed. Please contact administrator');
			return of(false);
		}),
		map(() => globalService.isClientsLoaded)
	);
};
