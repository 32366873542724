import { EChartsOption, SeriesOption } from 'echarts';
import { ObjectUtils } from '../utils/object.utils';

export interface IChartConfigBuilder {
	setGrid(left?: number, top?: number, right?: number, bottom?: number);
	setXAxisTime(endOnCurrentTime?, rest?);
	setYAxis(position?: string, type?: string, rest?);
	setTooltip(formatter: (params: any) => any, trigger?: string, rest?);
	addToolbox(zoom: boolean, saveAsImage: boolean);
	setDefault();
	setCustom(options: EChartsOption);
	build();
}

export class ChartConfigBuilder implements IChartConfigBuilder {
	private _chartOptions: any;

	constructor() {
		this._chartOptions = {};
	}

	setDefault() {}

	get chartOptions() {
		return this._chartOptions;
	}

	set chartOptions(options) {
		this._chartOptions = options;
	}

	static createDataSeries(name: string, type: string, color?: string, rest?: SeriesOption, isStepLine?: boolean, id?: any) {
		return {
			id: `[id:${id}]${name}`,
			name: id ? id : name,
			type,
			symbol: 'none',
			sampling: 'none',
			smooth: false,
			itemStyle: {
				color: color || 'rgb(44,115,210)',
			},
			step: isStepLine ? 'end' : null,
			data: [],
			large: true,
			...rest,
		};
	}

	static extractNameFromId(id: string): string {
		return id?.replace(/\[id:(.+?)\]/, '') ?? '';
	}

	fromOptions = (chartOptions: any) => {
		this._chartOptions = chartOptions;
		return this;
	};

	setGrid = (left: number = null, top: number = null, right: number = null, bottom: number = null) => {
		this._chartOptions.grid = {
			left: left || 40,
			top: top || 30,
			right: right || 60,
			bottom: bottom || 30,
		};
		return this;
	};

	setXAxisTime = (endOnCurrentTime = true, rest?) => {
		this._chartOptions.xAxis = {
			type: 'time',
			endOnCurrentTime,
			data: [],
			...rest,
		};
		return this;
	};

	setYAxis = (position = 'left', type = 'value', rest?) => {
		this._chartOptions.yAxis = {
			type,
			position,
			...rest,
		};
		return this;
	};

	setTooltip(formatter: (params: any) => any, trigger = 'axis', rest?) {
		this._chartOptions.tooltip = {
			trigger,
			axisPointer: {
				type: 'cross',
				animation: false,
				label: {
					backgroundColor: '#FFF',
					borderColor: '#1A73E8',
					borderWidth: 1,
					shadowBlur: 0,
					shadowOffsetX: 0,
					shadowOffsetY: 0,
					color: '#222',
				},
			},
			formatter,
			...rest,
		};
		return this;
	}

	addToolbox(zoom: boolean, saveAsImage: boolean) {
		this._chartOptions.toolbox = {
			right: 20,
			feature: {},
		};

		//zoom
		if (zoom) {
			this._chartOptions.toolbox.feature.dataZoom = {
				title: {
					zoom: 'Zoom',
					back: 'Zoom out',
				},
			};
			this._chartOptions.toolbox.feature.restore = {
				title: 'Restore',
			};
		}

		//saveAsImage
		if (saveAsImage) {
			this._chartOptions.toolbox.feature.saveAsImage = {
				title: 'Save as Image',
				name: 'chart-data',
			};
		}

		return this;
	}

	addExportCsvToolbox(exportFunc: () => void) {
		if (!this._chartOptions.toolbox?.feature) {
			this._chartOptions.toolbox = {
				right: 20,
				feature: {},
			};
		}

		this._chartOptions.toolbox.feature.myExportCsv = {
			show: true,
			title: 'Export CSV',
			name: 'export-csv',
			pixelRatio: 2,
			icon: 'path://M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm-96 144c0 4.42-3.58 8-8 8h-8c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h8c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-8c-26.51 0-48-21.49-48-48v-32c0-26.51 21.49-48 48-48h8c4.42 0 8 3.58 8 8v16zm44.27 104H160c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h12.27c5.95 0 10.41-3.5 10.41-6.62 0-1.3-.75-2.66-2.12-3.84l-21.89-18.77c-8.47-7.22-13.33-17.48-13.33-28.14 0-21.3 19.02-38.62 42.41-38.62H200c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-12.27c-5.95 0-10.41 3.5-10.41 6.62 0 1.3.75 2.66 2.12 3.84l21.89 18.77c8.47 7.22 13.33 17.48 13.33 28.14.01 21.29-19 38.62-42.39 38.62zM256 264v20.8c0 20.27 5.7 40.17 16 56.88 10.3-16.7 16-36.61 16-56.88V264c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v20.8c0 35.48-12.88 68.89-36.28 94.09-3.02 3.25-7.27 5.11-11.72 5.11s-8.7-1.86-11.72-5.11c-23.4-25.2-36.28-58.61-36.28-94.09V264c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8zm121-159L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z',
			onclick: (): void => {
				if (exportFunc) {
					exportFunc();
				}
			},
		};
		return this;
	}

	setCustom = (options: EChartsOption) => {
		this._chartOptions = ObjectUtils.deepSpread(this._chartOptions, options);
		return this;
	};

	build = () => {
		return this._chartOptions;
	};
}
