import { Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'fitech-workspace-icon-tooltip',
  templateUrl: './icon-tooltip.component.html',
  styleUrls: ['./icon-tooltip.component.scss']
})
export class IconTooltipComponent {

  @Input() icon = 'help';
  @Input() message: string;
  @Input() tooltipClass: string;
  @Input() position: TooltipPosition = 'below';
  @Input() showDelay: number;
  @Input() hideDelay: number;
  @Input() disabled: boolean;

}
