import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AsEnumerable } from 'linq-es2015';
import { IChipsWithIconData } from '../../..';
import { ChipsDef } from '../../models/chips-def.model';

@Component({
	selector: 'fitech-workspace-simple-chips-with-icon-renderer',
	templateUrl: './simple-chips-with-icon-renderer.component.html',
	styleUrls: ['./simple-chips-with-icon-renderer.component.scss'],
})
export class SimpleChipsWithIconRendererComponent implements ICellRendererAngularComp {
	params: {
		sortBy?: any;
		sortThanBy?: any;
		chipsDef: ChipsDef;
	};

	data: IChipsWithIconData[] = [];

	constructor(private _router: Router) {}

	refresh(params: any): boolean {
		return true;
	}

	agInit(params: any): void {
		const fieldName: string = params.colDef.field || params.chipsDef.typeName;
		let source = params.data[fieldName];

		if (!params.data[fieldName]) {
			return;
		}

		if (!Array.isArray(params.data[fieldName])) {
			source = [params.data[fieldName]];
		}

		let data = source.map((element: any) => {
			return this.getChipsWithIconData(params.chipsDef, element);
		});

		if (params.sortBy) {
			data = AsEnumerable(data)
				.OrderBy((x: any) => x[params.sortBy])
				.ToArray();
		}
		if (params.sortBy && params.sortThanBy) {
			data = AsEnumerable(data)
				.OrderBy((x: any) => x[params.sortBy])
				.ThenBy((x: any) => x[params.sortThanBy])
				.ToArray();
		}

		this.data = data;
	}

	afterGuiAttached?(params?: any): void {
		this.params = params;
	}

	onClick(item: IChipsWithIconData): void {
		if (item.onClickUrl) {
			this.goToPageWithFilter(item.onClickUrl.url, item.onClickUrl.gridFilter);
		}
	}

	goToPageWithFilter(routeName: string, gridFilter?: string): void {
		let params: NavigationExtras;

		if (gridFilter) {
			params = { queryParams: { gridFilter: gridFilter } };
		}

		this._router.navigate([routeName], params);
	}

	getChipsWithIconData(chipsDef: ChipsDef, data: any): IChipsWithIconData {
		if (!chipsDef.cssClass) {
			chipsDef.cssClass = this.getClassName(chipsDef.typeName);
		}
		if (!chipsDef.icon) {
			chipsDef.icon = this.removeSpecialChars(chipsDef.typeName);
		}

		if (!data) {
			return null;
		}

		data = JSON.parse(JSON.stringify(data));
		data.typeName = chipsDef.typeName || data.typeName;
		data.urlLastSegment = chipsDef.urlLastSegment;

		if (!data.name) {
			data.name = data[chipsDef.typeName]?.name;
		}

		return {
			value: (chipsDef.valueColumn ?? 'name').split('.').reduce((p: any, c: string) => (p && p[c]) || null, data),
			id: (chipsDef.idColumn ?? 'id').split('.').reduce((p: any, c: string) => (p && p[c]) || null, data),
			tooltip: chipsDef.typeName,
			cssClass: chipsDef.cssClass,
			icon: chipsDef.icon,
			onClickUrl: chipsDef.getOnClickUrl(data),
		};
	}

	getClassName(value: string): string {
		if (!value) {
			return '';
		}
		const className = 'color-' + this.removeSpecialChars(value.toString().toLowerCase());
		return className;
	}

	removeSpecialChars(value: string): string {
		if (value) {
			return value.replace(/[\\.,&:-]+/g, '').toLowerCase();
		}
		return value;
	}
}
