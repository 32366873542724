import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { of } from 'rxjs';
import { delay, take } from 'rxjs/operators';

@Directive({
	selector: '[focusInitial]',
})
export class FocusInitialDirective implements AfterViewInit {
	@Input() selectAll: boolean;
	@Input() setCaretAtEnd: boolean;
	@Input() withDelay: boolean;

	private get _input(): HTMLInputElement {
		return this._elementRef.nativeElement;
	}

	constructor(private _elementRef: ElementRef<HTMLInputElement>) {}

	ngAfterViewInit(): void {
		if (this.withDelay) {
			of(true)
				.pipe(take(1), delay(1))
				.subscribe(() => {
					this.setInputState();
				});
			return;
		}
		this.setInputState();
	}

	private setInputState(): void {
		this._input.focus();
		if (this.selectAll && this._input.select) {
			this._input.select();
		}

		if (!this.selectAll && this.setCaretAtEnd) {
			const valueLength = this._input.value?.length ?? 0;
			if (valueLength && this._input.setSelectionRange) {
				this._input.setSelectionRange(valueLength, valueLength);
			}
		}
	}
}
