import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	forwardRef,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Observable, Subject, Subscription, fromEvent, of } from 'rxjs';
import { delay, filter, map, startWith, take, tap } from 'rxjs/operators';
import { SelectOption, SelectOptionExtended, SelectOptionExtra, SelectOptionKey } from '../../models';
import { FormControlValueAccessorConnectorComponent } from '../form-control-value-accessor-connector/form-control-value-accessor-connector.component';
import { MatSelectSearchComponent } from '../mat-select-search/mat-select-search.component';

@Component({
	selector: 'fitech-workspace-mat-select',
	templateUrl: './mat-select-extended.component.html',
	styleUrls: ['./mat-select-extended.component.scss'],
	providers: [
		{
			provide: MatFormFieldControl,
			useExisting: MatSelectExtendedComponent,
		},
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MatSelectExtendedComponent),
			multi: true,
		},
	],
})
export class MatSelectExtendedComponent
	extends FormControlValueAccessorConnectorComponent
	implements OnInit, AfterViewInit, OnChanges, OnDestroy, MatFormFieldControl<SelectOptionKey | SelectOptionKey[]>
{
	@Input() id: string;
	@Input() name: string;
	@Input() initialPlaceholder = 'Select option...';
	@Input() noPermissionPlaceholder: string;
	@Input() multiple = false;
	@Input() isRefreshButton = false;
	@Input() refreshButtonTitle: string;
	@Input() isSelectButtons = true;
	@Input() refreshing = false;
	@Input() disabled: boolean;
	@Input() required = false;
	@Input() isPermissionBackendRead = true;
	@Input() showAddNewItem = false;
	@Input() forceClosingOnClickOutside = false;
	@Input('aria-describedby') userAriaDescribedBy: string;
	@Input() minItemsToSearch: number | undefined;
	@Input() value: SelectOptionKey | SelectOptionKey[];
	@Input() items: string[] | number[] | SelectOption[] | SelectOptionExtra<any>[];

	@Output() addedNewItem = new EventEmitter<string>();
	@Output() refreshedItems = new EventEmitter<void>();
	@Output() selectedAll = new EventEmitter<void>();
	@Output() clearedAll = new EventEmitter<void>();
	@Output() openedChange = new EventEmitter<boolean>();
	@Output() selectionChange = new EventEmitter<MatSelectChange | MatSelectChange>();

	@ViewChild(MatSelectSearchComponent, { static: false }) matSelectSearchComponent: MatSelectSearchComponent;
	@ViewChild('matSelect', { static: false }) matSelect: MatSelect;

	matSelectSearchControl: FormControl<string> = new FormControl('');

	filteredItems$: Observable<SelectOptionExtended[]>;
	isNoResults$: Observable<boolean>;

	noItems: boolean;
	isSearch = true;

	// Remark: documentation to implementing custom MatFormFieldControl
	// https://material.angular.io/guide/creating-a-custom-form-field-control
	stateChanges = new Subject<void>();
	controlType = 'mat-select';
	autofilled?: boolean;
	placeholder: string;

	touched = false;
	focused = false;

	private _items: SelectOption[];
	private _subscriptions = new Subscription();

	// Implementations of MatFormFieldControl
	get shouldLabelFloat(): boolean {
		const val = this.isControl ? this.formControl?.value : this.value;
		const isValueEmpty = Array.isArray(val) ? val.length === 0 : !val;

		return this.matSelect?.shouldLabelFloat || this.focused || !isValueEmpty;
	}

	get empty(): boolean {
		return this.matSelect?.empty ?? true;
	}

	get errorState(): boolean {
		return this.matSelect?.errorState ?? false;
	}

	constructor(protected injector: Injector, private _elRef: ElementRef<Element>, private _cdr: ChangeDetectorRef) {
		super(injector);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.setPlaceholder();
		this.setFilterItems();
	}

	ngAfterViewInit(): void {
		this.listenMouseEvents();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.disabled) {
			this.stateChanges.next();
		}
		if (changes.items) {
			this.setItems();
			this.setPlaceholder();
		}
		if (changes.value) {
			of(true)
				.pipe(
					delay(0),
					take(1),
					tap(() => {
						this.stateChanges.next();
					})
				)
				.subscribe();
		}
		if (changes.refreshing || changes.isPermissionBackendRead || changes.placeholder) {
			this.setPlaceholder();
		}
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
		this.stateChanges.complete();
	}

	trackByItemKey(index: number, item: SelectOptionExtended): SelectOptionKey {
		return item.key;
	}

	refreshItems(event: MouseEvent): void {
		this.refreshedItems.emit();
	}

	selectAll(): void {
		this.selectedAll.emit();
	}

	clearAll(): void {
		this.clearedAll.emit();
	}

	addNewItem(): void {
		this.addedNewItem.emit(this.matSelectSearchControl.value);
	}

	onSelectionChange(data: MatSelectChange | MatSelectChange): void {
		this.selectionChange.emit(data);
		this.stateChanges.next();
	}

	resetSearch(): void {
		this.matSelectSearchControl?.setValue('');
	}

	setItems(): void {
		if (!this.items?.length) {
			this._items = [];
		} else if (typeof this.items[0] === 'string') {
			this._items = (this.items as string[]).map((item: string): SelectOption => ({ key: item, value: item }));
		} else if (typeof this.items[0] === 'number') {
			this._items = (this.items as number[]).map((item: number): SelectOption => ({ key: item, value: `${item}` }));
		} else if ((this.items[0] as SelectOption) !== null) {
			this._items = [...(this.items as SelectOption[])];
		} else if ((this.items[0] as SelectOptionExtra<any>) !== null) {
			this._items = [...(this.items as SelectOptionExtra<any>[])];
		}
		const itemsCount = this._items?.length;
		this.noItems = !itemsCount;
		this.isSearch = typeof this.minItemsToSearch !== 'undefined' ? itemsCount >= this.minItemsToSearch : true;
		this.matSelectSearchControl?.setValue(this.matSelectSearchControl.value);
		this.stateChanges.next();
	}

	setDescribedByIds(ids: string[]): void {
		this.matSelect?.setDescribedByIds(ids);
	}

	onContainerClick(): void {
		this.matSelect?.onContainerClick();
	}

	changeOpened(isOpened: boolean): void {
		this.matSelectSearchComponent?.toggleSelect(isOpened);
		this.openedChange.emit(isOpened);
	}

	onFocusIn(event: FocusEvent): void {
		if (!this.focused && !this.disabled) {
			this.focused = true;
			this.stateChanges.next();
		}
	}

	onFocusOut(event: FocusEvent): void {
		if (!this._elRef.nativeElement.contains(event.relatedTarget as Element) && !this.disabled) {
			this.touched = true;
			this.focused = false;
			this.stateChanges.next();
		}
	}

	private setFilterItems(): void {
		this.filteredItems$ = this.matSelectSearchControl.valueChanges.pipe(
			startWith(''),
			map((value: string) => this.filterItems(value))
		);

		this.isNoResults$ = this.filteredItems$.pipe(
			map((filtered: SelectOptionExtended[]): boolean => filtered.every((option: SelectOptionExtended) => option.isHidden))
		);
	}

	private filterItems(searchValue: string): SelectOptionExtended[] {
		const filterValue = searchValue.toLowerCase();
		return this._items.map((option: SelectOption): SelectOptionExtended => ({ ...option, isHidden: !option.value.toLowerCase().includes(filterValue) }));
	}

	private setPlaceholder(): void {
		this.placeholder = !this.isPermissionBackendRead
			? this.noPermissionPlaceholder ?? `No permission to read ${this.name ?? ''}`
			: this.refreshing
			? ''
			: this.initialPlaceholder;

		if (this.items?.length === 0 && !this.refreshing) {
			this.placeholder = 'No items to choose';
		}

		this.stateChanges.next();
	}

	private listenMouseEvents(): void {
		if (this.forceClosingOnClickOutside) {
			this._subscriptions.add(
				fromEvent(document, 'click')
					.pipe(
						map((event: MouseEvent): Element => event.target as Element),
						filter((target: Element) => !!target)
					)
					.subscribe((target: Element) => {
						if (!this._elRef.nativeElement.contains(target)) {
							this.matSelect?.close();
							this.stateChanges.next();
						}
					})
			);
		}
	}
}
