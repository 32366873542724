import { Component, Input, OnInit } from '@angular/core';
import { ScreenSizeService } from '@fitech-workspace/core-lib';
import { NavLinkParams } from '../models/nav-link-params.model';

@Component({
	selector: 'fitech-workspace-common-header',
	templateUrl: './common-header.component.html',
	styleUrls: ['./common-header.component.scss'],
})
export class CommonHeaderComponent implements OnInit {
	@Input() navLinks: NavLinkParams[];
	@Input() isMainHeader = false;

	navbarId: string;
	collapseWidth = 992;

	constructor(private _screenSizeService: ScreenSizeService) {}

	ngOnInit(): void {
		this.navbarId = this.isMainHeader ? 'main-navbar' : 'sub-navbar';
	}

	blockNavbarCollapsingWhenInBackground(event: PointerEvent): void {
		if (this._screenSizeService.innerWidth >= this.collapseWidth) {
			event.stopPropagation();
		}
	}

	trackByRoute(index: number, navLink: NavLinkParams): string {
		return navLink.title;
	}
}
