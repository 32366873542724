<div class="mat-dialog-header">
	<div class="mat-dialog-header__group">
		<h5 class="mat-dialog-header__title" mat-dialog-title>
			{{ title }}
		</h5>
		<span class="mat-dialog-header__subtitle">{{ subTitle }}</span>
	</div>

	<button mat-icon-button (click)="onCancel()">
		<mat-icon>close</mat-icon>
	</button>
</div>
<div mat-dialog-content>
	<fitech-workspace-collapsible-layout (expand)="onSidePanelToggled()" (collapse)="onSidePanelToggled()" [sidePanelWidthMode]="'wide'">
		<div side-panel>
			<fitech-workspace-machine-sensors-list
				#machineSensorsList
				[currentMachineSensorStatesGridData]="currentMachineSensorStates"
				[trendSensorIds]="trendSensorIds"
				[machineAlarmsGridData]="machineAlarmsGridData"
				[loading]="loadingAlarms"
				(dateRangeChange)="onDateRangeChanged($event)"
				(sensorAdded)="addSensor($event)"
				(sensorRemoved)="removeSensor($event)"
				(sensorAddedWithAlarm)="onSensorAddedWithAlarm($event)"
				(currentStateChange)="onCurrentStateChange($event)">
			</fitech-workspace-machine-sensors-list>
		</div>
		<div content class="trend-viewer">
			<div class="trend-viewer__content">
				<fitech-workspace-machine-sensors-charts #sensorsCharts [isCurrentStateActive]="isCurrentStateActive"></fitech-workspace-machine-sensors-charts>

				<div class="trend-viewer__chart-sensors">
					<ng-container *ngFor="let sensor of trendSensors; trackBy: trackBySensorId; last as isLast">
						<fitech-workspace-trend-sensor
							[trendSensor]="sensor"
							[isLast]="isLast"
							(removed)="removeSensor(sensor)"
							(visibilityToggled)="toggleSensorVisibility(sensor)"
							(chartTypeToggled)="toggleSensorChartType(sensor)">
						</fitech-workspace-trend-sensor>
					</ng-container>
				</div>
			</div>
		</div>
	</fitech-workspace-collapsible-layout>
</div>
<mat-dialog-actions>
	<button mat-button class="btn btn--tertiary" (click)="onCancel()">Back</button>
</mat-dialog-actions>
