import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
	selector: 'fitech-workspace-avatar-renderer',
	templateUrl: './avatar-renderer.component.html',
	styleUrls: ['./avatar-renderer.component.scss'],
})
export class AvatarRendererComponent implements ICellRendererAngularComp {
	params: any;
	data: IAvatarData;

	refresh(params: any): boolean {
		return true;
	}

	agInit(params: any): void {
		if (params.data.getAvatarData()) {
			this.data = params.data.getAvatarData();
		}
	}

	afterGuiAttached?(params?: any): void {
		this.params = params;
	}
}
export interface IAvatarData {
	value: string;
	letter: string;
	color: string;
}
export interface IAvatarDataProvider {
	getAvatarData(): IAvatarData;
}
